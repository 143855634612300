import React from "react";
import { Link } from "react-router-dom";
import Header2 from "./Header2";
import secureLocalStorage from "react-secure-storage";
import apiurl from "./config";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const Halfmapsearch = () => {  

  const [category, setcategory] = useState([]);



  

  let token = secureLocalStorage.getItem("token");
  let userid = secureLocalStorage.getItem("coquiid");

  useEffect(() => {
    subcategoryget();
  }, []);

  const subcategoryget = () => {
    let tokenn = secureLocalStorage.getItem("token")
    let idd = secureLocalStorage.getItem("coquiid")

    const options = {
      headers: {
        Authorization: `${tokenn}`,
      },
    };
    let categoryidd = secureLocalStorage.getItem("catergoryidd");

    const data = JSON.stringify({
      user_id: idd,
      category_id: categoryidd,
    });

    axios
      .post(`${apiurl[0].apiUrl}React/sub_category_list_react`, data, options)
      // .post(`${apiurl[0].apiUrl}React/sub_category`, data, options)
      .then((res) => {

        setcategory(res.data.data);
      })
      .catch((err) => {
        //console.error(err);
      });
  };


  let lati = secureLocalStorage.getItem("latitude");
  let longi = secureLocalStorage.getItem("longitude");
   const lat = lati ? lati : '22.719568';
   const lon = longi ? longi : '75.857727';
   const zoom = 16;


  // const lat = "22.719568";
  // const lon = "75.857727";
  // const zoom = 16;
  return (
    <div>
      {/* <div className="preloader" />  */}

      {/* Main wrapper - style you can find in pages.scss */}

      <div id="main-wrapper">
        <Header2 />

        {/* ============================ Search Tag & Filter End ================================== */}
        {/* ============================ Map & Listing  Start================================== */}
        <div id="map-opt" className="home-map-banner half-map">
          <div className="fs-left-map-box">
            <div className="home-map fl-wrap">
              <div className="hm-map-container fw-map">
                {/* <div id="map" />   */}
                <div id="map" >
                <iframe
                                src={`https://maps.google.com/maps?q=${lat},${lon}&z=${zoom}&output=embed`}
                                width="100%"
                                height={'100%'}
                                style={{ border: 0 }}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="google map"
                              ></iframe> 
                              </div>  
              </div>
            </div>
          </div>
          <div className="fs-inner-container">
            <div className="fs-content gray">
              {/* row */}
              <div className="row justify-content-left gx-3">
                {/* Single */}


                {category?.map((list) => {
              return (
                <div  onClick={() => {
                  secureLocalStorage.setItem("suncategoryidd", list.id);
                }} className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                  <div className="Goodup-grid-wrap">
                    <div className="Goodup-grid-upper">
                     
                      <div className="Goodup-grid-thum">
                        <a
                          href="/listingsearch"
                          className="d-block text-center m-auto"
                        >
                          <img  style={{height:'70%' , width:'70%'}}
                          
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              list?.sub_category_image
                            }
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div className="Goodup-grid-fl-wrap">
                      <div className="Goodup-caption px-3 py-2">

                        <div className="Goodup-cates">
                          <a href="/listingsearch">{list?.category_name}</a>
                        </div>
                        <h4 className="mb-0 ft-medium medium">
                          <a href="/listingsearch" className="text-dark fs-md">
                          {list?.sub_category_name.slice(0, 18)}
                          </a>
                        </h4>
                        <div className="Goodup-middle-caption mt-3">
                          <div className="Goodup-location">
                            <i className="fas fa-map-marker-alt" />
                            Liverpool, London UK
                          </div>
                          {/* <div className="Goodup-call">
                            <a href>
                              {" "}
                              <i className="fas fa-phone-alt" />
                              +91 365 795 4526
                            </a>
                          </div>
                          <div className="vrt-list-sts">
                            <p className="vrt-qgunke">
                              <span className="ft-bold d14ixhs">Open</span> til
                              8:00 PM
                            </p>
                          </div> */}
                        </div>
                      </div>
                      <div className="Goodup-grid-footer py-3 px-3">
                        <div className="Goodup-ft-first">
                          <div className="Goodup-rating">
                            <div className="Goodup-pr-average high">4.3</div>
                            <div className="Goodup-rates">
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                            </div>
                          </div>
                          {/* 	<div class="Goodup-price-range">
                      <span class="active"><i class="fas fa-dollar-sign"></i></span>
                      <span class="active"><i class="fas fa-dollar-sign"></i></span>
                      <span class="active"><i class="fas fa-dollar-sign"></i></span>
                      <span class="active"><i class="fas fa-dollar-sign"></i></span>
                    </div> */}
                        </div>
                        {/* <div class="Goodup-ft-last">
                    <span class="small">2 min ago</span>
                  </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                 );
                })}
                {/* Single */}
         
              </div>
              {/* row */}
              {/* <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Previous">
                        <span className="fas fa-arrow-circle-right" />
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        ...
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        18
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Next">
                        <span className="fas fa-arrow-circle-right" />
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="clearfix" />
        {/* ============================ Map & Listing End ================================== */}
      
        
        <div
          className="w3-ch-sideBar w3-bar-block w3-card-2 w3-animate-right"
          style={{ display: "none", right: 0 }}
          id="Search"
        >
          <div className="rightMenu-scroll">
            <div className="d-flex align-items-center justify-content-between slide-head py-3 px-3">
              <h4 className="cart_heading fs-md ft-medium mb-0">
                Filter Option
              </h4>
              <button onclick="closeSearch()" className="close_slide">
                <i className="ti-close" />
              </button>
            </div>
            <div className="search-inner">
              <div className="side-filter-box">
                <div className="side-filter-box-body">
                  {/* Price Range */}
                  <div className="inner_widget_link">
                    <div className="btn-group d-flex justify-content-around price-btn-457">
                      <button type="button" className="btn">
                        $
                      </button>
                      <button type="button" className="btn">
                        $$
                      </button>
                      <button type="button" className="btn active d14ixh">
                        $$$
                      </button>
                      <button type="button" className="btn">
                        $$$$
                      </button>
                    </div>
                  </div>
                  {/* Suggested */}
                  <div className="inner_widget_link">
                    <h6 className="ft-medium">Suggested</h6>
                    <ul className="no-ul-list filter-list">
                      <li>
                        <input
                          id="a1"
                          className="checkbox-custom"
                          name="open"
                          type="checkbox"
                        />
                        <label htmlFor="a1" className="checkbox-custom-label">
                          Open Now
                        </label>
                      </li>
                      <li>
                        <input
                          id="a2"
                          className="checkbox-custom"
                          name="reservations"
                          type="checkbox"
                        />
                        <label htmlFor="a2" className="checkbox-custom-label">
                          Reservations
                        </label>
                      </li>
                      <li>
                        <input
                          id="a3"
                          className="checkbox-custom"
                          name="Mexican"
                          type="checkbox"
                        />
                        <label htmlFor="a3" className="checkbox-custom-label">
                          Mexican
                        </label>
                      </li>
                      <li>
                        <input
                          id="a4"
                          className="checkbox-custom"
                          name="Seafood"
                          type="checkbox"
                        />
                        <label htmlFor="a4" className="checkbox-custom-label">
                          Seafood
                        </label>
                      </li>
                      <li>
                        <input
                          id="a5"
                          className="checkbox-custom"
                          name="Takeout"
                          type="checkbox"
                        />
                        <label htmlFor="a5" className="checkbox-custom-label">
                          Takeout
                        </label>
                      </li>
                    </ul>
                  </div>
                  {/* Features */}
                  <div className="inner_widget_link">
                    <h6 className="ft-medium">Features</h6>
                    <ul className="no-ul-list filter-list">
                      <li>
                        <input
                          id="a6"
                          className="checkbox-custom"
                          name="Kids"
                          type="checkbox"
                          defaultChecked
                        />
                        <label htmlFor="a6" className="checkbox-custom-label">
                          Good for Kids
                        </label>
                      </li>
                      <li>
                        <input
                          id="a7"
                          className="checkbox-custom"
                          name="Service"
                          type="checkbox"
                        />
                        <label htmlFor="a7" className="checkbox-custom-label">
                          Waiter Service
                        </label>
                      </li>
                      <li>
                        <input
                          id="a8"
                          className="checkbox-custom"
                          name="Open"
                          type="checkbox"
                        />
                        <label htmlFor="a8" className="checkbox-custom-label">
                          Open to All
                        </label>
                      </li>
                      <li>
                        <input
                          id="a9"
                          className="checkbox-custom"
                          name="Dogs"
                          type="checkbox"
                        />
                        <label htmlFor="a9" className="checkbox-custom-label">
                          Dogs Allowed
                        </label>
                      </li>
                      <li>
                        <input
                          id="a10"
                          className="checkbox-custom"
                          name="Outdoor"
                          type="checkbox"
                        />
                        <label htmlFor="a10" className="checkbox-custom-label">
                          Outdoor Seating
                        </label>
                      </li>
                      <li>
                        <input
                          id="a11"
                          className="checkbox-custom"
                          name="Hot"
                          type="checkbox"
                        />
                        <label htmlFor="a11" className="checkbox-custom-label">
                          Hot and New
                        </label>
                      </li>
                      <li>
                        <input
                          id="a12"
                          className="checkbox-custom"
                          name="Breakfast"
                          type="checkbox"
                        />
                        <label htmlFor="a12" className="checkbox-custom-label">
                          Breakfast
                        </label>
                      </li>
                      <li>
                        <a
                          className="ft-bold d14ixh"
                          href="javascript:void(0);"
                        >
                          See More
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Neighborhoods */}
                  <div className="inner_widget_link">
                    <h6 className="ft-medium">Neighborhoods</h6>
                    <ul className="no-ul-list filter-list">
                      <li>
                        <input
                          id="b1"
                          className="checkbox-custom"
                          name="Alta"
                          type="checkbox"
                          defaultChecked
                        />
                        <label htmlFor="b1" className="checkbox-custom-label">
                          Alta Vista
                        </label>
                      </li>
                      <li>
                        <input
                          id="b2"
                          className="checkbox-custom"
                          name="Monticello"
                          type="checkbox"
                        />
                        <label htmlFor="b2" className="checkbox-custom-label">
                          Monticello Park
                        </label>
                      </li>
                      <li>
                        <input
                          id="b3"
                          className="checkbox-custom"
                          name="Beacon"
                          type="checkbox"
                        />
                        <label htmlFor="b3" className="checkbox-custom-label">
                          Beacon Hill
                        </label>
                      </li>
                      <li>
                        <input
                          id="b4"
                          className="checkbox-custom"
                          name="Near"
                          type="checkbox"
                        />
                        <label htmlFor="b4" className="checkbox-custom-label">
                          Near Northwest
                        </label>
                      </li>
                      <li>
                        <input
                          id="b5"
                          className="checkbox-custom"
                          name="North"
                          type="checkbox"
                        />
                        <label htmlFor="b5" className="checkbox-custom-label">
                          North Central
                        </label>
                      </li>
                      <li>
                        <input
                          id="b6"
                          className="checkbox-custom"
                          name="Northwest1"
                          type="checkbox"
                        />
                        <label htmlFor="b6" className="checkbox-custom-label">
                          Northwest
                        </label>
                      </li>
                      <li>
                        <input
                          id="b7"
                          className="checkbox-custom"
                          name="Pecan"
                          type="checkbox"
                        />
                        <label htmlFor="b7" className="checkbox-custom-label">
                          Pecan Valley
                        </label>
                      </li>
                      <li>
                        <input
                          id="b8"
                          className="checkbox-custom"
                          name="Prospect"
                          type="checkbox"
                        />
                        <label htmlFor="b8" className="checkbox-custom-label">
                          Prospect Hill
                        </label>
                      </li>
                      <li>
                        <input
                          id="b9"
                          className="checkbox-custom"
                          name="South"
                          type="checkbox"
                        />
                        <label htmlFor="b9" className="checkbox-custom-label">
                          South Central
                        </label>
                      </li>
                      <li>
                        <a
                          className="ft-bold d14ixh"
                          href="javascript:void(0);"
                        >
                          See More
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Bird's-eye View */}
                  <div className="inner_widget_link">
                    <h6 className="ft-medium">Bird's-eye View</h6>
                    <ul className="no-ul-list filter-list">
                      <li>
                        <input
                          id="c1"
                          className="checkbox-custom"
                          name="blc"
                          type="checkbox"
                          defaultChecked
                        />
                        <label htmlFor="c1" className="checkbox-custom-label">
                          Within 4 blocks
                        </label>
                      </li>
                      <li>
                        <input
                          id="c2"
                          className="checkbox-custom"
                          name="1km"
                          type="checkbox"
                        />
                        <label htmlFor="c2" className="checkbox-custom-label">
                          Walking (1 mi.)
                        </label>
                      </li>
                      <li>
                        <input
                          id="c3"
                          className="checkbox-custom"
                          name="2km"
                          type="checkbox"
                        />
                        <label htmlFor="c3" className="checkbox-custom-label">
                          Biking (2 mi.)
                        </label>
                      </li>
                      <li>
                        <input
                          id="c4"
                          className="checkbox-custom"
                          name="5km"
                          type="checkbox"
                        />
                        <label htmlFor="c4" className="checkbox-custom-label">
                          Driving (5 mi.)
                        </label>
                      </li>
                      <li>
                        <input
                          id="c5"
                          className="checkbox-custom"
                          name="10km"
                          type="checkbox"
                        />
                        <label htmlFor="c5" className="checkbox-custom-label">
                          Driving (10 mi.)
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="form-group filter_button">
                    <button
                      type="submit"
                      className="btn theme-bg text-light rounded full-width"
                    >
                      22 Results Show
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a id="tops-button" className="top-scroll" title="Back to top" href="#">
          <i className="ti-arrow-up" />
        </a>
      </div>
      {/* ============================================================== */}
      {/* End Wrapper */}
      {/* ============================================================== */}
      {/* ============================================================== */}
      {/* All Jquery */}
      {/* ============================================================== */}
      {/* Map */}
      {/* ============================================================== */}
      {/* This page plugins */}
      {/* ============================================================== */}
    </div>
  );
};

export default Halfmapsearch;
