import { Link } from "react-router-dom";
import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import ReactPlayer from "react-player";
import axios from "axios";

import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import apiurl from "./config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Placetoeatdetails = () => {
  const CustomPrevArrow = (props) => (
    <button
      className="custom-prev-arrow"
      onClick={props.onClick}
      type="button"
      data-role="none"
      class="slick-prev slick-arrow"
      aria-label="Previous"
      role="button"
      style={{ padding: "25px" }}
    >
      Previous
    </button>
  );

  const CustomNextArrow = (props) => (
    <button
      className="custom-next-arrow"
      onClick={props.onClick}
      type="button"
      data-role="none"
      class="slick-next slick-arrow"
      aria-label="Next"
      role="button"
      style={{ padding: "55px"}}
    >
      Next <i className="ti-calendar" />
    </button>
  );

  const settings = {
    autoplay: true,
    infinite: true,
    // dots:true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [singlelisting, setsinglelisting] = useState([]);

  let eventidd = secureLocalStorage.getItem("eventeat");


  useEffect(() => {
    singlelistingg();
  }, []);

  const singlelistingg = () => {
    const tokenn = secureLocalStorage.getItem("token");
    const idddd = secureLocalStorage.getItem("coquiid");
    const options = {
      headers: {
        Authorization: `${tokenn}`,
      },
    };
    let eventidd = secureLocalStorage.getItem("eventeat");
    

    const data = JSON.stringify({
      eat_id: eventidd,
      user_id:idddd,
    });

    axios
      .post(`${apiurl[0].apiUrl}React/eat_pr_details`, data, options)
      // .post(`${apiurl[0].apiUrl}React/event_details_react`, data, options)

      
      .then((res) => {

        setsinglelisting(res.data.data);
      })
      .catch((err) => {
       
      });
  };

  
  const lat = singlelisting?.eat_lat;
  const lon = singlelisting?.eat_long;
  const zoom = 16; 

  const eventImages = singlelisting?.eat_image;

  return (
    <div>
      
      <div className="preloader" />
      
      <div id="main-wrapper">
        
        <Header />
       
        <div className="clearfix" />
       
       

        <section className="featured-wraps gray">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="Goodup-ops-bhriol">
                  <div className="Goodup-lkp-flex d-flex align-items-start justify-content-start">
                    <div className="Goodup-lkp-thumb">
                      
                    </div>
                    <div className="Goodup-lkp-caption ps-3">
                      <div className="Goodup-lkp-title">
                        <h1 className="mb-0 ft-bold">
                          {singlelisting?.eat_name}
                        </h1>
                      </div>

                      <div className="d-block mt-3">
                        <div className="list-lioe">
                          <div className="list-lioe-single">
                            <span className="ft-medium text-info">
                              <i class="fas fa-map-marker-alt"></i> Location
                            </span>
                          </div>
                          <div className="list-lioe-single ms-2 ps-3 seperate">
                            <a
                              href="javascript:void(0);"
                              className="text-dark ft-medium"
                            >
                              {singlelisting?.eat_location}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="d-block mt-1">
                        <div className="list-lioe">
                          <div className="list-lioe-single">
                            <span className="ft-medium text-danger">
                              Eat Date & Time
                            </span>
                            <span className="ft-medium ms-2">
                              {singlelisting?.eat_date} -{" "}
                              {singlelisting?.eat_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


       
        <section className="py-5 position-relative">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                <div className=" mb-4">
                  <div className=" slick-initialized slick-slider">
                    <Slider {...settings}>
                      {eventImages?.map((items, index) => {
                        return (
                          <div className="" key={index}>
                            <a href="#" className="">
                              <img
                                width={"100%"}
                                height={"210px"}
                                src={`${apiurl[0].apiUrl}assets/bori_images/event/${items?.eat_image}`}
                                className=""
                                alt=""
                              />
                            </a>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
                {/* About The Business */}
                <div className="d-block">
                  <div className="jbd-01">
                    <div className="jbd-details">
                      <h5 className="ft-bold fs-lg">About the Eat</h5>
                      <div className="d-block mt-3">
                        <p style={{ textAlign: "justify" }}>
                          {singlelisting?.eat_description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sep-devider" />
                {/* Business Menu */}
                <div
                  className=" rounded mb-0"
                  style={{ background: "#f4f4f7" }}
                >
                
                </div>

                
               
              
              </div>
              {/* Sidebar */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
             
                <div className="jb-apply-form bg-white rounded py-4 px-4 border mb-4">
                  <div className="uli-list-info">
                    <ul>
                     
                      <a
                        href={singlelisting?.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <li>
                          <div className="list-uiyt">
                            <div className="list-iobk">
                              <i className="fas fa-globe" />
                            </div>
                            <div className="list-uiyt-capt">
                              <h5>Live Site</h5>
                              <p>{singlelisting?.website}</p>
                            </div>
                          </div>
                        </li>
                      </a>
                     
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk">
                            <i className="fas fa-phone" />
                          </div>
                          <div className="list-uiyt-capt">
                            <h5>Call Us</h5>
                            <p>{singlelisting?.contact}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk">
                            <i className="fas fa-map-marker-alt" />
                          </div>
                          <div className="list-uiyt-capt">
                            <h5>Get Directions</h5>
                            <a href={`https://www.google.com/maps/dir//${lat},${lon}/@${lat},${lon},16z?entry=ttu`}>
                            <p>{singlelisting?.eat_location}</p></a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </section>


















        <section className="py-0 position-relative">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
               
               
                <div
                  className=" rounded mb-0"
                  style={{ background: "#f4f4f7" }}
                >
                  <div className="jbd-01 px-3 py-2">
                    <div className="jbd-details mb-0">
                    
                      <div className="d-block mt-2">
                        <div className="row g-3">
                          <ReactPlayer
                            className="rounded"
                            url={
                              `${apiurl[0].apiUrl}assets/bori_images/event/` +
                              singlelisting?.eat_video
                            }
                            controls={true}
                            playing={false}
                            width={"100%"}
                            height={"500px"}
                            borderRadius={"10px"}
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              

                <div className="sep-devider" />
                {/* Location & Hours */}
                <div className="d-block">
                  <div className="jbd-01">
                    <div className="jbd-details">
                      <h5 className="ft-bold fs-lg mb-3">Location </h5>
                      <div className="Goodup-lot-wrap d-block">
                        <div className="row g-4">
                          <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="list-map-lot">
                             

<iframe
                src={`https://maps.google.com/maps?q=${lat},${lon}&z=${zoom}&output=embed`}
                width="100%"
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="google map"
            ></iframe>




                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sep-devider" />
         
              </div>
              
             
            </div>
          </div>
        </section>
       
        <Footer />
      

     
        <a id="tops-button" className="top-scroll" title="Back to top" href="#">
          <i className="ti-arrow-up" />
        </a>
      </div>
   
    </div>
  );
};

export default Placetoeatdetails;





