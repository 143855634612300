const apiurl=[
    {


//"apiUrl": "https://coquipages.com/CoquiPages/",
        "apiUrl": "https://logicaltest.website/Anju/Boricua/",
        //    "apiUrl": "https://logicaltest.website/Anju/Boricua/Api/",
    }
  ]

  export default apiurl ;



//   https://coquipages.com/CoquiPages/react/