import React, { useEffect, useState } from "react";

import Headerbusiness from "./Headerbusiness";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import "./loader.css";
import Dashboardimage from "../Component/Dashboardimage";
import apiurl from "../Component/config";
import Mainnavigation from "./Mainnavigation";
import { ToastContainer, toast } from "react-toastify";

const Baddlisting = () => {
  let id = secureLocalStorage.getItem("coquiid");
  let token = secureLocalStorage.getItem("token");
  let [coupon, setcoupon] = useState();
  let [latina, setlatina] = useState();
  let [parking, setparking] = useState();
  let [instagram, setinstagram] = useState();
  let [facebook, setfacebook] = useState();
  let [loaderstatus, setloaderstatus] = useState(true);
  let [simbolstatus, setsimbolstatusstatus] = useState(true);
  let [errorstatus, seterrorstatus] = useState(true);
  let [Spanish, setSpanish] = useState();
  let [English, setEnglish] = useState();
  let [Qualifications, setQualifications] = useState();
  // let [license, setlicense] = useState();

  let [all_imgl, setall_imgl] = useState();

  let [all_img, setall_img] = useState({
    vendor_id: id,
    owner_image: "",
    business_logo: "",
    listing_images: "",

    menu: "",
    vedio: "",
    vedio_price: 10,
  });

  let ownerimg = (e) => {
    setall_img({ ...all_img, [e.target.name]: e.target.files[0] });
  };
  let businesslogoimg = (e) => {
    setall_img({ ...all_img, [e.target.name]: e.target.files[0] });
  };
  let businessimglisting = (e) => {
    setall_img({ ...all_img, [e.target.name]: e.target.files });
  };
  let menuimg = (e) => {
    setall_img({ ...all_img, [e.target.name]: e.target.files });
  };

  // let license = (e) => {
  //   setall_img({ ...all_img, [e.target.name]: e.target.files[0] });
  // };
  // let videouploads = (e) => {
  //   setall_img({ ...all_img, [e.target.name]: e.target.files[0] });
  // };

  const videouploads = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(selectedFile);

      video.onloadedmetadata = () => {
        const videoDuration = Math.round(video.duration);
        if (videoDuration > 120) {
          alert(
            "Video duration should be less then 2 minutes. Please select a shorter video."
          );

          e.target.value = "";
        } else {
          setall_img({
            ...all_img,
            [e.target.name]: selectedFile,
            vedio_duration: videoDuration,
          });
        }
      };
    }
  };
  let Formsubmmit = (e) => {
    e.preventDefault();

    let formdata = new FormData();

    for (let i = 0; i <= all_img.listing_images.length - 1; i++) {
      formdata.append("listing_images[]", all_img.listing_images[i]);
    }
    for (let i = 0; i <= all_img.menu.length - 1; i++) {
      formdata.append("menu[]", all_img.menu[i]);
    }

    formdata.append("vendor_id", all_img.vendor_id);
    formdata.append("owner_image", all_img.owner_image);
    formdata.append("business_logo", all_img.business_logo);
    formdata.append("vedio", all_img.vedio);
    formdata.append("vedio_price", all_img.vedio_price);
    formdata.append("license", all_imgl);

    formdata.append("latina", latina);
    formdata.append("coupon", coupon);
    formdata.append("parking", parking);
    formdata.append("instagram", instagram);
    formdata.append("facebook", facebook);
    // formdata.append("English", English);
    // formdata.append("Spanish", Spanish);

    formdata.append("languages", English);

    formdata.append("qualifications", Qualifications);

    axios
      .post(`${apiurl[0].apiUrl}React/add_vendor_listing`, formdata, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.result == "true") {
          setsimbolstatusstatus(false);

          setloaderstatus(true);
        } else if (res.data.result == "false") {
          seterrorstatus(false);
        }
      })
      .catch((error) => {});

    setloaderstatus(false);
  };

  return (
    <div>
      {/* Preloader - style you can find in spinners.css */}
      {/* ============================================================== */}
      <div className="preloader" />
      {/* Main wrapper - style you can find in pages.scss */}
      {/* ============================================================== */}
      <div id="main-wrapper">
        {/* Top header  */}
        {/* Start Navigation */}
        <Headerbusiness />

        <div className="clearfix" />
        {/* ============================================================== */}
        {/* =============================== Dashboard Header ========================== */}
        <Dashboardimage />
        {/* =============================== Dashboard Header ========================== */}
        {/* ======================= dashboard Detail ======================== */}
        <div className="goodup-dashboard-wrap gray px-4 py-5">
          <a
            className="mobNavigation"
            data-bs-toggle="collapse"
            href="#MobNav"
            role="button"
            aria-expanded="false"
            aria-controls="MobNav"
          >
            <i className="fas fa-bars me-2" />
            Dashboard Navigation
          </a>
          <Mainnavigation />
          <div className="goodup-dashboard-content">
            <div className="dashboard-tlbar d-block">
              <div className="row">
                <div className="colxl-12 col-lg-12 col-md-12">
                  <h1 className="ft-medium">Add Listing</h1>
                </div>
              </div>
            </div>
            <div className="dashboard-widg-bar d-block">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="submit-form">
                    {/* Listing Info */}

                    {/* Image & Gallery Option */}
                    <div className="dashboard-list-wraps bg-white rounded mb-4">
                      <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                        <div className="dashboard-list-wraps-flx">
                          <h4 className="mb-0 ft-medium fs-md">
                            <i className="fa fa-camera me-2 theme-cl fs-sm" />
                            Image &amp; Gallery Option
                          </h4>
                        </div>
                      </div>
                      <div className="dashboard-list-wraps-body py-3 px-3">
                        <form action="" method="post" onSubmit={Formsubmmit}>
                          <div className="row">
                            {/* Logo */}

                            <div className="dashboard-list-wraps bg-white rounded mb-0">
                              <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                                <div className="dashboard-list-wraps-flx">
                                  <h4 className="mb-0 ft-medium fs-md">
                                    <i className="fa fa-user-friends me-2 theme-cl fs-sm" />
                                    Social Links
                                  </h4>
                                </div>
                              </div>
                              <div className="dashboard-list-wraps-body py-3 px-3">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                      <label className="mb-1">
                                        <i className="ti-facebook theme-cl me-1" />
                                        Facebook
                                      </label>
                                      <input
                                        value={facebook}
                                        onChange={(e) => {
                                          setfacebook(e.target.value);
                                        }}
                                        type="text"
                                        className="form-control rounded"
                                        placeholder="https://facebook.com/"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                      <label className="mb-1">
                                        <i className="ti-instagram theme-cl me-1" />
                                        Instagram
                                      </label>
                                      <input
                                        value={instagram}
                                        onChange={(e) => {
                                          setinstagram(e.target.value);
                                        }}
                                        type="text"
                                        className="form-control rounded"
                                        placeholder="https://instagram.com/"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                              <div class="form-group">
                                <label class="mb-1">Parking Information</label>
                                <select
                                  value={parking}
                                  onChange={(e) => {
                                    setparking(e.target.value);
                                  }}
                                  class="form-control"
                                >
                                  <option value="" selected disabled>
                                    Select Parking information
                                  </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                              <div class="form-group">
                                <label class="mb-1">Coupan</label>
                                <input
                                  value={coupon}
                                  onChange={(e) => {
                                    setcoupon(e.target.value);
                                  }}
                                  type="text"
                                  class="form-control rounded"
                                  placeholder="Type Coupan"
                                />
                              </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                              <div class="form-group">
                                <label class="mb-1">Latina</label>
                                <input
                                  value={latina}
                                  onChange={(e) => {
                                    setlatina(e.target.value);
                                  }}
                                  type="text"
                                  class="form-control rounded"
                                  placeholder="Type Latina"
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6 mt-2">
                              <div className="form-group">
                                <label className="mb-1">Qualifications</label>
                                <input
                                  className="form-control rounded"
                                  type="text"
                                  placeholder="Enter Qualifications"
                                  value={Qualifications}
                                  onChange={(e) =>
                                    setQualifications(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-7 col-md-6 mt-2">
                              <div className="form-group">
                                <label className="mb-1">Language</label>
                                <input
                                  className="form-control rounded"
                                  type="text"
                                  placeholder="Enter  Langauges"
                                  value={English}
                                  onChange={(e) => setEnglish(e.target.value)}
                                />
                              </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-6 mt-2">
                            <div className="form-group">
                    <label className="mb-1">License</label>


                    <input
                                  className="form-control rounded"
                                  
                                  name="owner_image"
                                  onChange={(e) => setlicense(e.target.files[0])}
                                  
                                  accept="image/*"
                                  type="file"
                                />

                    
                  </div></div> */}

                            {/* <div className="col-lg-3 col-md-6">
                              <label className="mb-1">License Image</label>
                              <form
                                
                                className="dropzone"
                                id="single-logo"
                              >
                                <label for="license">
                                  {" "}
                                  <i
                                    className="fas fa-upload"
                                    style={{ cursor: "pointer" }}
                                  />
                                </label>
                                <h6 className="text-success">
                                 
                                  image
                                </h6>
                                <input
                                  style={{ display: "none" }}
                                  
                                  name="license"
                                  onChange={((e)=>{setall_imgl(e.target.files[0])})}
                                  accept="image/*"
                                  type="file"
                                  id="license"
                                />
                              </form>
                              
                            </div> */}

                            <div className="col-lg-6 col-md-6 mb-3">
                              <label className="mb-1">License Image</label>
                              <form className="dropzone" id="single-logo">
                                {/* <img
                                                width={100}
                                                src={
                                                  `${apiurl[0].apiUrl}assets/bori_images/listing/` +
                                                  vendorelisting?.license
                                                }
                                              /> */}
                                <label for="license">
                                  {" "}
                                  <i
                                    className="fas fa-upload"
                                    style={{ cursor: "pointer" }}
                                  />
                                </label>

                                <input
                                  style={{ display: "none" }}
                                  name="license"
                                  onChange={(e) => {
                                    setall_imgl(e.target.files[0]);
                                  }}
                                  id="license"
                                  accept="image/*"
                                  type="file"
                                />
                              </form>
                            </div>

                            {/* <div className="col-lg-6 col-md-6 mt-2">
                            <div className="form-group">
                    <label className="mb-1">Language</label>
                    <input 
                      type="text"
                      
placeholder="Enter Spanish langauge"
                      value={Spanish}
                      onChange={(e) => setSpanish(e.target.value)}
                      className="form-control rounded"
                    />
                  </div></div> */}

                            <div className="col-lg-6 col-md-6 mb-3">
                              <label className="mb-1">Owner Image</label>
                              <form
                                action="https://themezhub.net/file-upload"
                                className="dropzone"
                                id="single-logo"
                              >
                                <label for="ownerimg">
                                  {" "}
                                  <i
                                    className="fas fa-upload"
                                    style={{ cursor: "pointer" }}
                                  />
                                </label>
                                <h6 className="text-success">
                                  {all_img.owner_image.length == null
                                    ? "1"
                                    : "0"}{" "}
                                  image
                                </h6>
                                <input
                                  style={{ display: "none" }}
                                  required
                                  name="owner_image"
                                  onChange={ownerimg}
                                  id="ownerimg"
                                  accept="image/*"
                                  type="file"
                                />
                              </form>
                              {/* <label className="smart-text">Maximum file size: 2 MB.</label> */}
                            </div>
                            {/* Featured Image */}
                            <div className="col-lg-6 col-md-6 mb-3">
                              <label className="mb-1">Business Logo</label>
                              <form
                                action="https://themezhub.net/file-upload"
                                className="dropzone"
                                id="featured-image"
                              >
                                <label for="business_logoimg">
                                  {" "}
                                  <i
                                    className="fas fa-upload"
                                    style={{ cursor: "pointer" }}
                                  />
                                </label>
                                <h6 className="text-success">
                                  {all_img.business_logo.length == null
                                    ? "1"
                                    : "0"}{" "}
                                  image
                                </h6>
                                <input
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  required
                                  name="business_logo"
                                  onChange={businesslogoimg}
                                  id="business_logoimg"
                                  type="file"
                                />
                              </form>
                              {/* <label className="smart-text">Maximum file size: 2 MB.</label> */}
                            </div>
                            {/* Gallery */}
                            <div className="col-lg-6 col-md-12 mb-3">
                              <label className="mb-1">
                                Listing Images Upload
                              </label>{" "}
                              <br />
                              <form
                                action="https://themezhub.net/file-upload"
                                className="dropzone"
                                id="gallery"
                              >
                                <label>Business photos (up to 20 photos)</label>
                                <label for="businessimg">
                                  {" "}
                                  <i
                                    className="fas fa-upload"
                                    style={{ cursor: "pointer" }}
                                  />
                                </label>
                                <h6 className="text-success">
                                  {all_img.listing_images.length} images
                                </h6>
                                <input
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  required
                                  name="listing_images"
                                  onChange={businessimglisting}
                                  multiple
                                  id="businessimg"
                                  type="file"
                                />
                              </form>
                              {/* <label className="smart-text">Maximum file size: 2 MB.</label> */}
                            </div>

                            {/* <div className="row"> */}
                            {/* Logo */}
                            <div className="col-lg-6 col-md-6 mt-2">
                              <label className="mb-1">Manu images upload</label>
                              <form
                                action="https://themezhub.net/file-upload"
                                className="dropzone"
                                id="single-logo"
                              >
                                <label className="">
                                  Upload business photos (up to 20 photos)
                                </label>
                                <label for="menuimg">
                                  {" "}
                                  <i
                                    className="fas fa-upload"
                                    style={{ cursor: "pointer" }}
                                  />
                                </label>
                                <h6 className="text-success">
                                  {all_img.menu.length} images
                                </h6>
                                <input
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  required
                                  name="menu"
                                  onChange={menuimg}
                                  id="menuimg"
                                  multiple
                                  type="file"
                                />
                              </form>
                              {/* <label className="smart-text">Maximum file size: 2 MB.</label> */}
                            </div>
                            {/* Featured Image */}
                            <div className="col-lg-6 col-md-6 mt-2">
                              <label className="mb-1">Video file upload</label>
                              <form
                                action="https://themezhub.net/file-upload"
                                className="dropzone"
                                id="featured-image"
                              >
                                <label className="text-danger">
                                  We will charge $50 extra to list if you will
                                  upload video
                                </label>
                                <label for="img">
                                  {" "}
                                  <i
                                    className="fas fa-upload"
                                    style={{ cursor: "pointer" }}
                                  />
                                </label>
                                <h6 className="text-success">
                                  {all_img.vedio.length == null ? "1" : "0"}{" "}
                                  video
                                </h6>
                                <input
                                  accept="video/*"
                                  style={{ display: "none" }}
                                  required
                                  name="vedio"
                                  onChange={videouploads}
                                  id="img"
                                  type="file"
                                />
                              </form>
                              {/* <label className="smart-text">Maximum file size: 2 MB.</label> */}
                            </div>
                          </div>
                          {/* Gallery */}
                          {/* </div> */}
                          <div className="text-center">
                            {loaderstatus == true ? (
                              simbolstatus == true ? (
                                <button
                                  type="submit"
                                  className="btn btn-danger m-2"
                                >
                                  Uploads
                                </button>
                              ) : errorstatus == true ? (
                                <>
                                  <h6 className="text-success m-2">
                                    Successfully added details....
                                  </h6>
                                </>
                              ) : (
                                <>
                                  <h6 className="text-danger m-2">Try-Agian</h6>
                                </>
                              )
                            ) : errorstatus == true ? (
                              <button className="loader m-2"></button>
                            ) : (
                              <>
                                <h6 className="text-danger m-2">Try-Again</h6>
                              </>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="row">
              <div className="col-md-12">
                <div className="py-3">
                  © 2024 CoquiPage. Designd By Logical Soft Tech Private
                  Limited.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ======================= dashboard Detail End ======================== */}
        <a id="tops-button" className="top-scroll" title="Back to top" href="#">
          <i className="ti-arrow-up" />
        </a>
      </div>
      {/* ============================================================== */}
      {/* End Wrapper */}
      {/* ============================================================== */}
      {/* ============================================================== */}
      {/* All Jquery */}
      {/* ============================================================== */}
      {/* Date Booking Script */}
      {/* ============================================================== */}
      {/* This page plugins */}
      {/* ============================================================== */}
    </div>
  );
};

export default Baddlisting;
