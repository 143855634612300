import apiurl from "./config";
import React, { useState, useEffect } from "react";
import { GoogleLoginButton } from "react-social-login-buttons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LoginSocialGoogle } from "reactjs-social-login";
import secureLocalStorage from "react-secure-storage";



function Google() {
  const navigate = useNavigate();
  const [output, setOutput] = useState("");
  const [getComment, setGetComment] = useState([]);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (profile) {
      sendToAPI();
    }
  }, [profile]);

  const sendToAPI = () => {
    let userDetails = {
      "google_id": profile.sub,
      "first_name": profile.given_name,
      "last_name": profile.family_name,
      "user_email": profile.email,
      
      "fcm_id":"1234567896525"
    };

    axios
      .post(`${apiurl[0].apiUrl}React/google_Login_react`, userDetails)
      .then((response) => {

        setGetComment([response.data.data]);
         
        if (response.data.token !== "error") {
          secureLocalStorage.setItem("google_id", response.data.data.id);
          secureLocalStorage.setItem("name", response.data.data.name);
          secureLocalStorage.setItem("email", response.data.data.email);
          secureLocalStorage.setItem("coquiid", response.data.data.id);
          secureLocalStorage.setItem("imageurl", response.data.data.image_url);
          secureLocalStorage.setItem("token", response.data.data.token);

        //   response.data.data.role === "user" ? navigate("/dashboard") : navigate("/dashboard");
        window.location.reload(navigate("/dashboard"));
        } else {
          setOutput("Invalid user or verify your account....");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



    return (
        <div>
            {!profile ? (
                <LoginSocialGoogle
                    client_id="1004517911619-4bnuicmpd8p1g1c0kflgem4kuqofi8qo.apps.googleusercontent.com"
                    
                    redirect_uri={'/'}
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="online"
                    onResolve={(response) => {
                        // console.log(response);
                        setProfile(response.data);
                    }}
                    onReject={(error) => {
                        console.log(error);
                    }}
                >
                    <GoogleLoginButton />
                </LoginSocialGoogle>
            ) : null}

            {profile ? (
                <div>
                    <h1>{profile.name}</h1>
                    <img src={profile.picture} alt="Profile" />
                </div>
            ) : null}

            <p>{output}</p>
        </div>
    );
}

export default Google;









