import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import apiurl from "./config";
import Header from "./Header";
import Footer from "./Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Google from "./Google";
import { useNavigate } from "react-router-dom";
import { getToken } from "firebase/messaging";
import { messaging } from "./Firebase";
const Subcategory = () => {
  const [category, setcategory] = useState([]);
  const [email, setEemail] = useState("");
  const [password, setPaassword] = useState("");
  const [output, setOutput] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [first_name, setFirstname] = useState("");


  const [last_name, setLastname] = useState("");
  const [user_email, setEmail] = useState("");
  const [zip, setZipcode] = useState("");
  const [user_password, setPassword] = useState("");
  const navigate = useNavigate();
  let token = secureLocalStorage.getItem("token");
  let userid = secureLocalStorage.getItem("coquiid");

  useEffect(() => {
    subcategoryget();
  }, []);

  const subcategoryget = () => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
    };
    let categoryidd = secureLocalStorage.getItem("catergoryid");

    const data = JSON.stringify({
      category_id: categoryidd,
    });

    axios
      .post(`${apiurl[0].apiUrl}React/sub_category`, data, options)
      .then((res) => {
        setcategory(res.data.data);
      })
      .catch((err) => {
        //console.error(err);
      });
  };

  


  const handleSubmitt =async (evt) => {
    evt.preventDefault();


    if (!('Notification' in window)) {
      //console.log('This browser does not support notifications.');
      return;
  }
  if (Notification.permission === 'granted') {
      //  console.log("hello token")
      let fcmtoken = await getToken(messaging, { vapidKey: 'BK0anho2f3mYHQxFitww4g7zgkwMo_8P53ptrbci04eh_TjnbLR99iw11SE5Bg3ao6iHoPdzeuK9lmllx0EBoSE' })
      // console.log(fcmtoken)
      secureLocalStorage.setItem("astro_fcm", fcmtoken)
      
  } else if (Notification.permission === 'denied') {
        //  console.log('Notification permission denied!');
      const confirmed = window.confirm(
          'You have denied notification permission. Do you want to enable it manually in your browser settings?'
      );
      if (confirmed) {
          
      }
  } else {
      try {
          const permissionResult = await Notification.requestPermission();
          if (permissionResult === 'granted') {
          } else if (permissionResult === 'denied') {
              //   console.log('Notification permission denied!');
          } else {
              //   console.log('Notification permission default (not responded yet).');
          }
      } catch (error) {
          console.error('Error requesting notification permission:', error);
      }
  }
    if (!email) {
      setOutput("*Email is required");
      return;
    } else if (!password) {
      setOutput("*Password is required");
      return;
    }
let fcm_id= secureLocalStorage.getItem("astro_fcm")
    const userDetails = {
      user_email: email,
      user_password: password,
      fcm_id: fcm_id,
      lat: latitude ? latitude : "22.75555",
      long: longitude ? longitude : "75.44444",
    };

    axios
      .post(`${apiurl[0].apiUrl}React/login_react_new`, userDetails)
      .then((response) => {
        if (response.data.result) {
          const responseData = response.data.userDetails;
          secureLocalStorage.setItem("coquiid", response.data.data.id);

          secureLocalStorage.setItem("token", response.data.data.token);

          toast.success(response.data.msg);

          setTimeout(() => {
            window.location.reload(navigate("/dashboard"));
          }, 2000);
        } else {
          if (response.data.message === "User not found") {
            setOutput("Invalid user. Please register first.");
          } else {
            setOutput("Invalid user or verify your account.");
          }
          setEemail("");
          setPaassword("");
        }
      })
      .catch((err) => {
        //console.log(err);
        setOutput("Incorrect email & password");
      });
  };



  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (!first_name) {
      setOutput("Please enter your First name.");
      return;
    }

    if (!last_name) {
      setOutput("Please enter your Last name.");
      return;
    }

    if (!zip) {
      setOutput("Please enter your zipcode.");
      return;
    }

    if (!user_email) {
      setOutput("Please enter your email address.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(user_email)) {
      setOutput("Please enter a valid email address.");
      return;
    }

    if (!user_password) {
      setOutput("Please enter a password.");
      return;
    }

    let userDetails = {
      first_name,
      last_name,
      user_email,
      user_password,
      zip,
      fcm_id: "12345",
    };

    try {
      const response = await axios.post(
        `${apiurl[0].apiUrl}React/user_signup_react`,
        userDetails
      );

      if (response.data.token !== "error") {
        // setOutput("User registered successfully.");
        toast.success(response.data.msg);

        setTimeout(() => {
          navigate("/");
        }, 3000);

        setFirstname("");
        setEmail("");
        setPassword("");
        setZipcode("");
        setLastname("");
      } else {
        setOutput(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.result === "false" &&
        error.response.data.message ===
          "user allready registered, please go to login page.."
      ) {
        setOutput(
          "The email is already registered. Please go to the login page."
        );
      } else {
        //console.log(error);
      }
    }
  };

  let lng = secureLocalStorage.getItem("language")
  return (
    <div>
      <Header />
      <section className="space min gray">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              {lng === "English" ? <div className="sec_title position-relative text-center mb-5">
                <h6 className="mb-0 theme-cl">Popular Categories</h6>
                <h2 className="ft-bold">Browse Top Subcategories</h2>
              </div> : <div className="sec_title position-relative text-center mb-5">
                <h6 className="mb-0 theme-cl">Categorías Populares</h6>
                <h2 className="ft-bold">Explorar las principales subcategorías</h2>
              </div>}
            </div>
          </div>
          {/* row */}
          {lng === "English" ? <div className="row align-items-left">
{category?.length > 0 ? (
category?.map((list) => {
  return (
    <div
      onClick={() => {
        secureLocalStorage.setItem("suncategoryidd", list.id);
      }}
      className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6"
    >
      <div className="cats-wrap text-center">
        <a href="/listingsearch" className="Goodup-catg-wrap">
          {/* <div class="Goodup-catg-city">26 Cities</div> */}
          <div className="catg-icon">
            <img
              height={"50px"}
              width={"50"}
              style={{ marginBottom: "30px" }}
              src={
                `${apiurl[0].apiUrl}assets/bori_images/category/` +
                list?.sub_category_image
              }
            />

            {/* <i className="fas fa-utensils" /> */}
          </div>
          <div className="Goodup-catg-caption">
            <h4 className="fs-md mb-0 ft-medium m-catrio">
              {list?.sub_category_name.slice(0, 18)}
            </h4>
            {/* <span className="text-muted">172 Listings</span> */}
          </div>
        </a>
      </div>
    </div>
  );
})
) : (
  <div className="col-md-12 text-center"><img src="assets/img/nodata.png"/></div>
                   )}

<div style={{ textAlign: "right" }} className="btn-container">
            <a href="/">
              <button className="btn btn-danger" id="">
                Back
              </button>
            </a>
          </div>
          
          </div> : <div className="row align-items-left">
{category?.length > 0 ? (
category?.map((list) => {
  return (
    <div
      onClick={() => {
        secureLocalStorage.setItem("suncategoryidd", list.id);
      }}
      className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6"
    >
      <div className="cats-wrap text-center">
        <a href="/listingsearch" className="Goodup-catg-wrap">
          {/* <div class="Goodup-catg-city">26 Cities</div> */}
          <div className="catg-icon">
            <img
              height={"50px"}
              width={"50"}
              style={{ marginBottom: "30px" }}
              src={
                `${apiurl[0].apiUrl}assets/bori_images/category/` +
                list?.sub_category_image
              }
            />

            {/* <i className="fas fa-utensils" /> */}
          </div>
          <div className="Goodup-catg-caption">
            <h4 className="fs-md mb-0 ft-medium m-catrio">
              {list?.sp_sub_category_name?.slice(0, 18)}
            </h4>
            {/* <span className="text-muted">172 Listings</span> */}
          </div>
        </a>
      </div>
    </div>
  );
})
) : (
  <div className="col-md-12 text-center"><img src="assets/img/nodata.png"/></div>
                   )}

<div style={{ textAlign: "right" }} className="btn-container">
            <a href="/">
              <button className="btn btn-danger" id="">
              Atrás
              </button>
            </a>
          </div>
          
          </div>}

         
        </div>
      </section>
      <Footer />

      <div
          className="modal fade"
          id="login"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="loginmodal"
          aria-hidden="true"
        >
          <div className="modal-dialog login-pop-form" role="document">
            <div className="modal-content" id="loginmodal">
              <div className="modal-headers">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="ti-close" />
                </button>
              </div>
              <div className="modal-body p-5">
                <div className="text-center mb-4">
                  <h4 className="m-0 ft-medium">Login Your Account</h4>
                </div>
                {output && <font style={{ color: "red" }}>{output}</font>}
                <form onClick={handleSubmitt} className="submit-form">
                  <div className="form-group">
                    <label className="mb-1">Phone</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Addres"
                      value={email}
                      onChange={(e) => setEemail(e.target.value)}
                      className="form-control rounded bg-light"
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1">Password</label>
                    <input
                      type="password"
                      id="form-login-password-2"
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPaassword(e.target.value)}
                      className="form-control rounded bg-light"
                    />
                  </div>
                  {/* <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="flex-1">
                        <input
                          id="dd"
                          className="checkbox-custom"
                          name="dd"
                          type="checkbox"
                          defaultChecked
                        />
                        <label
                          data-bs-toggle="modal"
                          data-bs-target="#forgot"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          htmlFor="dd"
                          className="checkbox-custom-label"
                        >
                          Forgot Password
                        </label>
                      </div>
                      <div className="eltio_k2">
                     

                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#signup"
                          className="ft-bold"
                        >
                          <i className="fas fa-user-plus me-1 theme-cl" />
                          Sign Up
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                    >
                      Log In
                    </button>
                  </div>
                  <div className="form-group text-center mb-0">
                    <p className="extra">Or login with</p>
                    <div className="option-log">
                      {/* <div className="single-log-opt">
                        <a href="javascript:void(0);" className="log-btn">
                          <img
                            src="assets/img/c-1.png"
                            className="img-fluid"
                            alt=""
                          />
                          Login with Google
                          
                          

                        </a>
                      </div>
                      <div className="single-log-opt">
                        <a href="javascript:void(0);" className="log-btn">
                          <img
                            src="assets/img/facebook.png"
                            className="img-fluid"
                            alt=""
                          />
                          Login with Facebook
                        </a>
                      </div> */}
                    </div>
                    <Google />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


        <div
          className="modal fade"
          id="signup"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="signupmodal"
          aria-hidden="true"
        >
          <div className="modal-dialog login-pop-form" role="document">
            <div className="modal-content" id="signupmodal">
              <div className="modal-headers">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="ti-close" />
                </button>
              </div>
              <div className="modal-body p-5">
                <div className="text-center mb-4">
                  <h4 className="m-0 ft-medium">Sign Up</h4>
                </div>
                {output && <font style={{ color: "red" }}>{output}</font>}
                <form onSubmit={handleSubmit} className="submit-form">
                  <div className="form-group">
                    <label className="mb-1">First Name</label>
                    <input
                      value={first_name}
                      onChange={(e) => setFirstname(e.target.value)}
                      type="text"
                      className="form-control rounded bg-light"
                      placeholder="Firstname*"
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1">Last Name</label>
                    <input
                      value={last_name}
                      onChange={(e) => setLastname(e.target.value)}
                      type="text"
                      className="form-control rounded bg-light"
                      placeholder="Lastname*"
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1">Zip Code</label>
                    <input
                      value={zip}
                      onChange={(e) => setZipcode(e.target.value)}
                      type="number"
                      className="form-control rounded bg-light"
                      placeholder="Zip Code*"
                      maxLength={6}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1">Email</label>
                    <input
                      value={user_email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="form-control rounded bg-light"
                      placeholder="Email*"
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1">Password</label>
                    <input
                      maxLength={"8"}
                      value={user_password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className="form-control rounded bg-light"
                      placeholder="Password*"
                    />
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                    >
                      Sign Up
                    </button>
                  </div>
                  {/* <div className="form-group text-center mb-0">
                    <p className="extra">Or login with</p>
                    <div className="option-log">
                      <div className="single-log-opt">
                        <a href="javascript:void(0);" className="log-btn">
                          <img
                            src="assets/img/c-1.png"
                            className="img-fluid"
                            alt=""
                          />
                          Login with Google
                        </a>
                      </div>
                      <div className="single-log-opt">
                        <a href="javascript:void(0);" className="log-btn">
                          <img
                            src="assets/img/facebook.png"
                            className="img-fluid"
                            alt=""
                          />
                          Login with Facebook
                        </a>
                      </div>
                    </div>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Subcategory;
