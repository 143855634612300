import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import apiurl from "./config";
import secureLocalStorage from "react-secure-storage";

const Footer = () => {
  const [helpdata, sethelpdata] = useState([]);

  useEffect(() => {
    gethelp();
  }, []);
  const gethelp = () => {
    axios
      .get(`${apiurl[0].apiUrl}React/get_help_center`)
      .then((res) => sethelpdata(res.data.data));
  };
  
  let lng = secureLocalStorage.getItem("language")
  return (
    <div>
      <footer className="light-footer skin-light-footer style-2">
        <div className="footer-middle">
          <div className="container">
           {lng === "English" ?  <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="footer_widget">
                  <img
                    src="assets/img/logoss.png"
                    className="img-footer small mb-2"
                    alt=""
                  />
                  {/* <div className="address mt-2">
                        7742 Sadar Street Range Road, USA<br />United Kingdom GHQ11	
                       </div> */}
                  <div className="address mt-3">
                    {helpdata?.contact}
                    <br />
                    {helpdata?.email}
                  </div>
                  <div className="address mt-2">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://www.facebook.com/groups/859160575966776/?ref=share_group_link&exp=7ffb"
                          className="theme-cl"
                        >
                          <i className="lni lni-facebook-filled" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://www.instagram.com/p/C4gp_MnxjsR/?igsh=MTFvMWwwcGxpbXBkdw=="
                          className="theme-cl"
                        >
                          <i className="lni lni-instagram-filled" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://youtube.com/@Coquipages?si=QHq7tNsk1vbKtuaH"
                          className="theme-cl"
                        >
                          <i className="lni lni-youtube" />
                        </a>
                      </li>

                      <li className="list-inline-item">
                        <a
                          target="_black"
                          href="https://www.tiktok.com/@coquipages?_t=8kgQwFlFZ6J&_r=1"
                          className="theme-cl"
                        >
                          <i class="fab fa-tiktok"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="footer_widget">
                  <h4 className="widget_title">About</h4>
                  <ul className="footer-menu">
                    <li>
                      <a href="/about">About CoquiPages</a>
                    </li>
                    {/* <li>
                      <a href="#">Careers</a>
                    </li>
                    <li>
                      <a href="#">Press</a>
                    </li>
                    <li>
                      <a href="#">Investor Relations</a>
                    </li>
                    <li>
                      <a href="#">Trust &amp; Safety</a>
                    </li> */}
                    {/* <li>
                      <a href="#">Content Guidelines</a>
                     </li>
                    <li>
                      <a href="#">Accessibility Statement</a>
                    </li> */}
                    <li>
                      <a href="/term">Terms of Service</a>
                    </li>
                    <li>
                      <a href="/Privacy">Privacy Policy</a>
                    </li>
                    {/* <li>
                      <a href="#">Ad Choices</a>
                    </li> */}
                    {/* <li>
                      <a href="#">Your Privacy Choices</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="footer_widget">
                  <h4 className="widget_title">Discover</h4>
                  <ul className="footer-menu">
                    {/* <li>
                      <a href="#">CoquiPages Project Cost Guides</a>
                    </li>
                    <li>
                      <a href="#">Collections</a>
                    </li>
                    <li>
                      <a href="#">Talk</a>
                    </li>
                    <li>
                      <a href="#">Events</a>
                    </li>
                    <li>
                      <a href="/bloghome">CoquiPages Blog</a>
                    </li> */}
                    <li>
                      <a href="/contact">Support</a>
                    </li>
                    {/* <li>
                      <a href="#">CoquiPages Mobile</a>
                    </li> */}
                    <li>
                      <a href="/faq">Faq</a>
                    </li>
                    {/* <li>
                      <a href="#">RSS</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <div className="footer_widget">
                  <h4 className="widget_title">CoquiPages for Business</h4>
                  <ul className="footer-menu">
                    <li>
                      <a href="#">CoquiPages for Business</a>
                    </li>
                    <li>
                      <a href="#">Business Owner Login</a>
                    </li>
                    <li>
                      <a href="#">Claim your Business Page</a>
                    </li>
                    <li>
                      <a href="#">Advertise on CoquiPages</a>
                    </li>
                    <li>
                      <a href="#">CoquiPages for Restaurant Owners</a>
                    </li>
                    <li>
                      <a href="#">Table Management</a>
                    </li>
                    <li>
                      <a href="#">Business Success Stories</a>
                    </li>
                    <li>
                      <a href="#">Business Support</a>
                    </li>
                    <li>
                      <a href="/bloghome">CoquiPages Blog for Business</a>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <div className="footer_widget">
                  <h4 className="widget_title">Languages</h4>
                  <select className="footer-menu">
                    <option>English</option>
                    <option>Spanish</option>
                  </select> */}
                  {/* <h4 className="widget_title">Countries</h4>
                      <select className="footer-menu">
                        <option>USA</option>
                        <option>PUERTO RICO</option>
                      </select>  */}
                {/* </div>
              </div> */}



            </div> :  <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="footer_widget">
                  <img
                    src="assets/img/logoss.png"
                    className="img-footer small mb-2"
                    alt=""
                  />
                  
                  <div className="address mt-3">
                    {helpdata?.contact}
                    <br />
                    {helpdata?.email}
                  </div>
                  <div className="address mt-2">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://www.facebook.com/groups/859160575966776/?ref=share_group_link&exp=7ffb"
                          className="theme-cl"
                        >
                          <i className="lni lni-facebook-filled" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://www.instagram.com/p/C4gp_MnxjsR/?igsh=MTFvMWwwcGxpbXBkdw=="
                          className="theme-cl"
                        >
                          <i className="lni lni-instagram-filled" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://youtube.com/@Coquipages?si=QHq7tNsk1vbKtuaH"
                          className="theme-cl"
                        >
                          <i className="lni lni-youtube" />
                        </a>
                      </li>

                      <li className="list-inline-item">
                        <a
                          target="_black"
                          href="https://www.tiktok.com/@coquipages?_t=8kgQwFlFZ6J&_r=1"
                          className="theme-cl"
                        >
                          <i class="fab fa-tiktok"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="footer_widget">
                  <h4 className="widget_title">Acerca de</h4>
                  <ul className="footer-menu">
                    <li>
                      <a href="/about">Acerca de CoquiPages</a>
                    </li>
                  
                    <li>
                      <a href="/term">Términos de servicio</a>
                    </li>
                    <li>
                      <a href="/Privacy">política de privacidad</a>
                    </li>
                    
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="footer_widget">
                  <h4 className="widget_title">Descubrir</h4>
                  <ul className="footer-menu">
                    
                    <li>
                      <a href="/contact">Apoyo</a>
                    </li>
                   
                    <li>
                      <a href="/faq">Preguntas más frecuentes</a>
                    </li>
                   
                  </ul>
                </div>
              </div>
              



            </div>}
          </div>
        </div>
        <div className="footer-bottom br-top">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 text-center">
                <p className="mb-0">© 2024 CoquiPages</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;




// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import apiurl from "./config";

// const Footer = () => {
//   const [helpdata, setHelpData] = useState([]);
//   const [selectedLanguage, setSelectedLanguage] = useState("English");

//   useEffect(() => {
//     getHelp();
//   }, []);

//   const getHelp = () => {
//     axios
//       .get(`${apiurl[0].apiUrl}React/get_help_center`)
//       .then((res) => setHelpData(res.data.data));
//   };

//   const handleLanguageChange = (e) => {
//     setSelectedLanguage(e.target.value);
//   };

//   const content = {
//     English: {
//       about: "About",
//       careers: "Careers",
//       press: "Press",
//       investorRelations: "Investor Relations",
//       trustSafety: "Trust & Safety",
//       contentGuidelines: "Content Guidelines",
//       accessibilityStatement: "Accessibility Statement",
//       termsOfService: "Terms of Service",
//       privacyPolicy: "Privacy Policy",
//       adChoices: "Ad Choices",
//       yourPrivacyChoices: "Your Privacy Choices",
//     },
//     Spanish: {
//       about: "Acerca de",
//       careers: "Carreras",
//       press: "Prensa",
//       investorRelations: "Relaciones con Inversores",
//       trustSafety: "Confianza y Seguridad",
//       contentGuidelines: "Directrices de Contenido",
//       accessibilityStatement: "Declaración de Accesibilidad",
//       termsOfService: "Términos de Servicio",
//       privacyPolicy: "Política de Privacidad",
//       adChoices: "Opciones de Anuncios",
//       yourPrivacyChoices: "Tus Opciones de Privacidad",
//     },
//   };

//   const selectedContent = content[selectedLanguage];

//   return (
//     <div>
//       <footer className="light-footer skin-light-footer style-2">
//         <div className="footer-middle">
//           <div className="container">
//             <div className="row">
//               <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
//                 <div className="footer_widget">
//                   <img
//                     src="assets/img/logoss.png"
//                     className="img-footer small mb-2"
//                     alt=""
//                   />
//                   <div className="address mt-3">
//                     {helpdata?.contact}
//                     <br />
//                     {helpdata?.email}
//                   </div>
//                   <div className="address mt-2">
//                     <ul className="list-inline">
//                       <li className="list-inline-item">
//                         <a
//                           target="_blank"
//                           href="https://www.facebook.com/groups/859160575966776/?ref=share_group_link&exp=7ffb"
//                           className="theme-cl"
//                         >
//                           <i className="lni lni-facebook-filled" />
//                         </a>
//                       </li>
//                       <li className="list-inline-item">
//                         <a
//                           target="_blank"
//                           href="https://www.instagram.com/p/C4gp_MnxjsR/?igsh=MTFvMWwwcGxpbXBkdw=="
//                           className="theme-cl"
//                         >
//                           <i className="lni lni-instagram-filled" />
//                         </a>
//                       </li>
//                       <li className="list-inline-item">
//                         <a
//                           target="_blank"
//                           href="https://youtube.com/@Coquipages?si=QHq7tNsk1vbKtuaH"
//                           className="theme-cl"
//                         >
//                           <i className="lni lni-youtube" />
//                         </a>
//                       </li>
//                       <li className="list-inline-item">
//                         <a
//                           target="_black"
//                           href="https://www.tiktok.com/@coquipages?_t=8kgQwFlFZ6J&_r=1"
//                           className="theme-cl"
//                         >
//                           <i class="fab fa-tiktok"></i>
//                         </a>
//                       </li>
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
//                 <div className="footer_widget">
//                   <h4 className="widget_title">{selectedContent.about}</h4>
//                   <ul className="footer-menu">
//                     <li>
//                       <a href="/about">{selectedContent.about}</a>
//                     </li>
//                     <li>
//                       <a href="#">{selectedContent.careers}</a>
//                     </li>
//                     <li>
//                       <a href="#">{selectedContent.press}</a>
//                     </li>
//                     <li>
//                       <a href="#">{selectedContent.investorRelations}</a>
//                     </li>
//                     <li>
//                       <a href="#">{selectedContent.trustSafety}</a>
//                     </li>
//                     <li>
//                       <a href="#">{selectedContent.contentGuidelines}</a>
//                     </li>
//                     <li>
//                       <a href="#">{selectedContent.accessibilityStatement}</a>
//                     </li>
//                     <li>
//                       <a href="/term">{selectedContent.termsOfService}</a>
//                     </li>
//                     <li>
//                       <a href="/Privacy">{selectedContent.privacyPolicy}</a>
//                     </li>
//                     <li>
//                       <a href="#">{selectedContent.adChoices}</a>
//                     </li>
//                     <li>
//                       <a href="#">{selectedContent.yourPrivacyChoices}</a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
//                 <div className="footer_widget">
//                   <h4 className="widget_title">Discover</h4>
//                   <ul className="footer-menu">
//                     <li>
//                       <a href="#">CoquiPages Project Cost Guides</a>
//                     </li>
//                     <li>
//                       <a href="#">Collections</a>
//                     </li>
//                     <li>
//                       <a href="#">Talk</a>
//                     </li>
//                     <li>
//                       <a href="#">Events</a>
//                     </li>
//                     <li>
//                       <a href="/bloghome">CoquiPages Blog</a>
//                     </li>
//                     <li>
//                       <a href="#">Support</a>
//                     </li>
//                     <li>
//                       <a href="#">CoquiPages Mobile</a>
//                     </li>
//                     <li>
//                       <a href="/faq">Faq</a>
//                     </li>
//                     <li>
//                       <a href="#">RSS</a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
//                 <div className="footer_widget">
//                   <h4 className="widget_title">CoquiPages for Business</h4>
//                   <ul className="footer-menu">
//                     <li>
//                       <a href="#">CoquiPages for Business</a>
//                     </li>
//                     <li>
//                       <a href="#">Business Owner Login</a>
//                     </li>
//                     <li>
//                       <a href="#">Claim your Business Page</a>
//                     </li>
//                     <li>
//                       <a href="#">Advertise on CoquiPages</a>
//                     </li>
//                     <li>
//                       <a href="#">CoquiPages for Restaurant Owners</a>
//                     </li>
//                     <li>
//                       <a href="#">Table Management</a>
//                     </li>
//                     <li>
//                       <a href="#">Business Success Stories</a>
//                     </li>
//                     <li>
//                       <a href="#">Business Support</a>
//                     </li>
//                     <li>
//                       <a href="/bloghome">CoquiPages Blog for Business</a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
//                 <div className="footer_widget">
//                   <h4 className="widget_title">Languages</h4>
//                   <select
//                   className="footer-menu"
//                   value={selectedLanguage}
//                   onChange={handleLanguageChange}
//                 >
//                   <option value="English">English</option>
//                   <option value="Spanish">Spanish</option>
//                 </select>
                 
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="footer-bottom br-top">
//           <div className="container">
//             <div className="row align-items-center">
//               <div className="col-lg-12 col-md-12 text-center">
//                 <p className="mb-0">© 2024 CoquiPages</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default Footer;











