import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import axios from 'axios';
import apiurl from './config';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";
function Contact() {
  const [helpdataa, sethelpdata] = useState();
const [name, setname] = useState();
const [email, setemail] = useState();
const [subject, setsubject] = useState();
const [message, setmessage] = useState();
const [phone, setphone] = useState();

  const handlecontact=(e)=>{
    e.preventDefault();
const formData = new FormData()
formData.append("name",name)
formData.append("email",email)
formData.append("phone",phone)
formData.append("subject",subject)
formData.append("message",message)


   
    axios.post(`${apiurl[0].apiUrl}React/contact_us`,formData).then((res)=>{
      console.log("res",res)
      toast.success(res.data.msg)
    }).catch((error)=>{

    })
    setname("");
    setemail("");
    setsubject("");
    setmessage("");
    setphone("");
  }

  useEffect(()=>{
    helpdata();
  },[0])
  let helpdata =()=>{
    axios.get(`${apiurl[0].apiUrl}React/get_help_center`).then((res)=>{
      sethelpdata(res?.data?.data)
      
    }).catch((error)=>{

    })
  }

  let lng = secureLocalStorage.getItem("language")
  
  return (
    <div>
   
     <div className="preloader" /> 
   
    <div id="main-wrapper">
    
     <Header/>
     <ToastContainer/>
      
      <div className="clearfix" />
     
      {lng === "English" ? <section className="gray">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="sec_title position-relative text-center">
                <h2 className="off_title">Contact Us</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-start justify-content-center">
            <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12">
              <form onSubmit={handlecontact} className="row submit-form py-4 px-3 rounded bg-white mb-4">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Your Name *</label>
                    <input required onChange={((e)=>{setname(e.target.value)})} value={name} type="text" className="form-control" placeholder="Your Name" />
                  </div>
                </div>
                
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Your Email *</label>
                    <input required onChange={((e)=>{setemail(e.target.value)})} value={email} type="email" className="form-control"placeholder="Your Email" />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Your Phone*</label>
                    <input required onChange={((e)=>{setphone(e.target.value)})} value={phone} type="phone" className="form-control"placeholder="Your Phone" />
                  </div>
                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Mobile</label>
                    <input type="text" className="form-control" defaultValue="+91 256 548 7542" />
                  </div>
                </div> */}
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Subject</label>
                    <input required onChange={((e)=>{setsubject(e.target.value)})} value={subject} type="text" className="form-control" placeholder="Type Your Subject" />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-8 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Message</label>
                    <textarea minLength={300} required onChange={((e)=>{setmessage(e.target.value)})} value={message} className="form-control ht-80" placeholder={"Your Message..."} />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <button type="submit" className="btn theme-bg text-light">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12">
              <div className="row">
                {/* <div className="col-xl-4 col-lg-4 col-md-12">
                  <div className="bg-white rounded p-3 mb-2">
                    <h4 className="ft-medium mb-3 theme-cl">Address info:</h4>
                    <p>1354 Green Street Nashville Drive Dodge City,<br /> KS 67801 United States</p>
                  </div>
                </div> */}
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="bg-white rounded p-3 mb-2">
                    <h4 className="ft-medium mb-3 theme-cl">Call Us:</h4>
                    <h6 className="ft-medium mb-1">Customer Care:</h6>
                   <p className="mb-2"><a href={`tel:${helpdataa?.contact}`}>{helpdataa?.contact}</a></p>
                  </div>
                </div>
               

                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="bg-white rounded p-3 mb-2">
                    <h4 className="ft-medium mb-3 theme-cl">Drop A Mail:</h4>
                    <p>Drop mail we will contact with you.</p>
                    <p className="lh-1 text-dark"><a href={`mailto:${helpdataa?.email}`}>{helpdataa?.email}</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> : <section className="gray">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="sec_title position-relative text-center">
                <h2 className="off_title">Contacta con nosotras</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-start justify-content-center">
            <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12">
              <form onSubmit={handlecontact} className="row submit-form py-4 px-3 rounded bg-white mb-4">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Su nombre *</label>
                    <input required onChange={((e)=>{setname(e.target.value)})} value={name} type="text" className="form-control" placeholder="Su nombre" />
                  </div>
                </div>
                
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Tu correo electrónico *</label>
                    <input required onChange={((e)=>{setemail(e.target.value)})} value={email} type="email" className="form-control"placeholder="Tu correo electrónico" />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Su teléfono*</label>
                    <input required onChange={((e)=>{setphone(e.target.value)})} value={phone} type="phone" className="form-control"placeholder="Su teléfono" />
                  </div>
                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Mobile</label>
                    <input type="text" className="form-control" defaultValue="+91 256 548 7542" />
                  </div>
                </div> */}
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Sujeta</label>
                    <input required onChange={((e)=>{setsubject(e.target.value)})} value={subject} type="text" className="form-control" placeholder="Sujeta" />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-8 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="small text-dark ft-medium">Mensaje</label>
                    <textarea minLength={300} required onChange={((e)=>{setmessage(e.target.value)})} value={message} className="form-control ht-80" placeholder={"Tu mensaje..."} />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <button type="submit" className="btn theme-bg text-light">Enviar mensaje</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12">
              <div className="row">
                {/* <div className="col-xl-4 col-lg-4 col-md-12">
                  <div className="bg-white rounded p-3 mb-2">
                    <h4 className="ft-medium mb-3 theme-cl">Address info:</h4>
                    <p>1354 Green Street Nashville Drive Dodge City,<br /> KS 67801 United States</p>
                  </div>
                </div> */}
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="bg-white rounded p-3 mb-2">
                    <h4 className="ft-medium mb-3 theme-cl">Llámanos:</h4>
                    <h6 className="ft-medium mb-1">Atención al cliente:</h6>
                   <p className="mb-2"><a href={`tel:${helpdataa?.contact}`}>{helpdataa?.contact}</a></p>
                  </div>
                </div>
               

                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="bg-white rounded p-3 mb-2">
                    <h4 className="ft-medium mb-3 theme-cl">dejar un correo:</h4>
                    <p>Déjanos un correo y nos pondremos en contacto contigo.</p>
                    <p className="lh-1 text-dark"><a href={`mailto:${helpdataa?.email}`}>{helpdataa?.email}</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}

    <Footer/>
      {/* ============================ Footer End ================================== */}
      {/* Log In Modal */}
      <div className="modal fade" id="login" tabIndex={-1} role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="loginmodal">
            <div className="modal-headers">
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Login Your Account</h4>
              </div>
              <form className="submit-form">				
                <div className="form-group">
                  <label className="mb-1">Phone</label>
                  <input type="text" className="form-control rounded bg-light" placeholder="Phone*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Password</label>
                  <input type="password" className="form-control rounded bg-light" placeholder="Password*" />
                </div>
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-1">
                      <input id="dd" className="checkbox-custom" name="dd" type="checkbox" defaultChecked />
                      <label htmlFor="dd" className="checkbox-custom-label">Remember Me</label>
                    </div>	
                    <div className="eltio_k2">
                      <a href="#" className="theme-cl">Lost Your Password?</a>
                    </div>	
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-md full-width theme-bg text-light rounded ft-medium">Log In</button>
                </div>
                <div className="form-group text-center mb-0">
                  <p className="extra">Or login with</p>
                  <div className="option-log">
                    <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/c-1.png" className="img-fluid" alt="" />Login with Google</a></div>
                    <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/facebook.png" className="img-fluid" alt="" />Login with Facebook</a></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
      {/* Sign up Modal */}
     
      {/* End Modal */}
      <a id="tops-button" className="top-scroll" title="Back to top" href="#"><i className="ti-arrow-up" /></a>
    </div>
    {/* ============================================================== */}
    {/* End Wrapper */}
    {/* ============================================================== */}
    {/* ============================================================== */}
    {/* All Jquery */}
    {/* ============================================================== */}
    {/* ============================================================== */}
    {/* This page plugins */}
    {/* ============================================================== */}		
  </div>
  )
}

export default Contact
