import React from "react";
import Header from "./Header";
import secureLocalStorage from "react-secure-storage";
import Navbardashboard from "./Navbardashboard";
import Dashboardimage from "./Dashboardimage";
import { useState } from "react";
import axios from "axios";
import apiurl from "./config";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Savedlisting = () => {
  let logout = () => {
    secureLocalStorage.removeItem("coquiid");
    secureLocalStorage.removeItem("token");
  };

  const [getfavouritelist, setgetfavouritelist] = useState([]);

  useEffect(() => {
    getfavouritelistreact();
  }, [0]);

  const getfavouritelistreact = () => {
    const tokenn = secureLocalStorage.getItem("token");
    
    let idddd = secureLocalStorage.getItem("coquiid");

    const data = {
      user_id: idddd,
    };

    axios
      .post(`${apiurl[0].apiUrl}React/get_favourite_list_react`, data, {
        headers: {
          Authorization: `${tokenn}`,
        },
      })
      .then((response) => {
        setgetfavouritelist(response.data.data);
      })
      .catch((error) => {});
  };

  const favorite = (item) => {
    const tokenn = secureLocalStorage.getItem("token");

    let listingidd = secureLocalStorage.getItem("listingserachid");
    let idddd = secureLocalStorage.getItem("coquiid");

    const data = {
      user_id: idddd,
      listing_id: item,
    };

    axios
      .post(
        `${apiurl[0].apiUrl}React/favourite_unfavourite_listing_react`,
        data,
        {
          headers: {
            Authorization: `${tokenn}`,
          },
        }
      )
      .then((response) => {
        getfavouritelistreact();
        toast.success(response.data.msg);
      })
      .catch((error) => {});
  };
let lng = secureLocalStorage.getItem("language")
  return (
    <div>
      <ToastContainer />
      {/* ============================================================== */}
      {/* Preloader - style you can find in spinners.css */}
      {/* ============================================================== */}
      <div className="preloader" />
      {/* ============================================================== */}
      {/* Main wrapper - style you can find in pages.scss */}
      {/* ============================================================== */}
      <div id="main-wrapper">
        {/* ============================================================== */}
        {/* Top header  */}
        {/* ============================================================== */}
        {/* Start Navigation */}
        <Header />
        {/* End Navigation */}
        <div className="clearfix" />
        {/* ============================================================== */}
        {/* Top header  */}
        {/* ============================================================== */}
        {/* =============================== Dashboard Header ========================== */}
        <Dashboardimage />
        {/* =============================== Dashboard Header ========================== */}
        {/* ======================= dashboard Detail ======================== */}
        <div className="goodup-dashboard-wrap gray px-4 py-5">
          <a
            className="mobNavigation"
            data-bs-toggle="collapse"
            href="#MobNav"
            role="button"
            aria-expanded="false"
            aria-controls="MobNav"
          >
            <i className="fas fa-bars me-2" />
            Dashboard Navigation
          </a>

          <Navbardashboard />
         {lng === "English" ?  <div className="goodup-dashboard-content">
            <div className="dashboard-tlbar d-block mb-5">
              <div className="row">
                <div className="colxl-12 col-lg-12 col-md-12">
                  <h1 className="ft-medium">My favorite Listings</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li>
                        <a href="/">Home /</a>
                      </li>
                      <li>
                        <a href="/dashboard">Dashboard /</a>
                      </li>
                      <li>
                        <a href="#" className="theme-cl">
                          Bookmark Listings
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="dashboard-widg-bar d-block">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="dashboard-list-wraps bg-white rounded mb-4">
                    <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                      <div className="dashboard-list-wraps-flx">
                        <h4 className="mb-0 ft-medium fs-md">
                          <i className="fa fa-file-alt me-2 theme-cl fs-sm" />
                          My favorite Listings
                        </h4>
                      </div>
                    </div>
                    <div className="dashboard-list-wraps-body py-3 px-3">
                      <div className="dashboard-listing-wraps">
                        {/* Single Listing Item */}
                        {getfavouritelist?.length > 0 ? (
                          getfavouritelist?.map((getfavorite) => {
                            return (
                              <div
                                className="dsd-single-listing-wraps"
                                onClick={() => {
                                  secureLocalStorage.setItem(
                                    "listingserachid",
                                    getfavorite.id
                                  );
                                }}
                              >
                                <div className="dsd-single-lst-thumb">
                                  <a href="/singlelisting">
                                    <img
                                      src={`${apiurl[0].apiUrl}assets/bori_images/listing/${getfavorite?.business_logo}`}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </a>
                                </div>
                                <div className="dsd-single-lst-caption">
                                  <div className="dsd-single-lst-title">
                                    <h5>{getfavorite?.business_name}</h5>
                                  </div>
                                  <div className="dsd-single-lst-title">
                                    <h5>
                                      Category - {getfavorite?.category_name}
                                    </h5>
                                  </div>
                                  {/* <span className="agd-location"><i className="lni lni-map-marker me-1" />San Francisco, USA</span> */}

                                  <div className="Goodup-call">
                                    <a href>
                                      {" "}
                                      <i className="fas fa-phone-alt" />{" "}
                                      {getfavorite?.vendor_contact}
                                    </a>
                                  </div>
                                  <div className="ico-content">
                                    <div className="Goodup-ft-first">
                                      <div className="Goodup-rating">
                                        <div className="Goodup-rates">
                                          <i className="fas fa-star" />
                                          <i className="fas fa-star" />
                                          <i className="fas fa-star" />
                                          <i className="fas fa-star" />
                                          <i className="fas fa-star" />
                                        </div>
                                      </div>
                                      <div className="Goodup-price-range">
                                        <span className="ft-medium">
                                          {getfavorite?.rating} Rating
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="dsd-single-lst-footer">
                                    {/* <a href="javascript:void(0);" className="btn btn-edit mr-1"><i className="fas fa-edit me-1" />Edit</a>
      <a href="javascript:void(0);" className="btn btn-view mr-1"><i className="fas fa-eye me-1" />View</a> */}
                                    <a
                                      onClick={() => {
                                        favorite(getfavorite?.id);
                                      }}
                                      href="javascript:void(0);"
                                      className="btn btn-delete"
                                    >
                                      <i class="lni lni-heart-filled "></i>
                                      &nbsp;Unfavorite
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <h4 className="mb-0 ft-medium fs-md">
                            No Favorite Data{" "}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* footer */}
            {/* <div class="row">
                    <div class="col-md-12">
                        <div class="py-3">© 2022 Goodup. Designd By ThemezHub.</div>
                    </div>
                </div> */}
          </div> :  <div className="goodup-dashboard-content">
            <div className="dashboard-tlbar d-block mb-5">
              <div className="row">
                <div className="colxl-12 col-lg-12 col-md-12">
                  <h1 className="ft-medium">Mis listados favoritos</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li>
                        <a href="/">Hogar /</a>
                      </li>
                      <li>
                        <a href="/dashboard">Panel /</a>
                      </li>
                      <li>
                        <a href="#" className="theme-cl">
                        Listados de marcadores
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="dashboard-widg-bar d-block">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="dashboard-list-wraps bg-white rounded mb-4">
                    <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                      <div className="dashboard-list-wraps-flx">
                        <h4 className="mb-0 ft-medium fs-md">
                          <i className="fa fa-file-alt me-2 theme-cl fs-sm" />
                          Mis listados favoritos
                        </h4>
                      </div>
                    </div>
                    <div className="dashboard-list-wraps-body py-3 px-3">
                      <div className="dashboard-listing-wraps">
                        {/* Single Listing Item */}
                        {getfavouritelist?.length > 0 ? (
                          getfavouritelist?.map((getfavorite) => {
                            return (
                              <div
                                className="dsd-single-listing-wraps"
                                onClick={() => {
                                  secureLocalStorage.setItem(
                                    "listingserachid",
                                    getfavorite.id
                                  );
                                }}
                              >
                                <div className="dsd-single-lst-thumb">
                                  <a href="/singlelisting">
                                    <img
                                      src={`${apiurl[0].apiUrl}assets/bori_images/listing/${getfavorite?.business_logo}`}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </a>
                                </div>
                                <div className="dsd-single-lst-caption">
                                  <div className="dsd-single-lst-title">
                                    <h5>{getfavorite?.sp_business_name}</h5>
                                  </div>
                                  <div className="dsd-single-lst-title">
                                    <h5>
                                      Category - {getfavorite?.sp_name}
                                    </h5>
                                  </div>
                                  {/* <span className="agd-location"><i className="lni lni-map-marker me-1" />San Francisco, USA</span> */}

                                  <div className="Goodup-call">
                                    <a href>
                                      {" "}
                                      <i className="fas fa-phone-alt" />{" "}
                                      {getfavorite?.vendor_contact}
                                    </a>
                                  </div>
                                  <div className="ico-content">
                                    <div className="Goodup-ft-first">
                                      <div className="Goodup-rating">
                                        <div className="Goodup-rates">
                                          <i className="fas fa-star" />
                                          <i className="fas fa-star" />
                                          <i className="fas fa-star" />
                                          <i className="fas fa-star" />
                                          <i className="fas fa-star" />
                                        </div>
                                      </div>
                                      <div className="Goodup-price-range">
                                        <span className="ft-medium">
                                          {getfavorite?.rating} Rating
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="dsd-single-lst-footer">
                                    {/* <a href="javascript:void(0);" className="btn btn-edit mr-1"><i className="fas fa-edit me-1" />Edit</a>
      <a href="javascript:void(0);" className="btn btn-view mr-1"><i className="fas fa-eye me-1" />View</a> */}
                                    <a
                                      onClick={() => {
                                        favorite(getfavorite?.id);
                                      }}
                                      href="javascript:void(0);"
                                      className="btn btn-delete"
                                    >
                                      <i class="lni lni-heart-filled "></i>
                                      &nbsp;No favorito
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <h4 className="mb-0 ft-medium fs-md">
                            Sin datos favoritos{" "}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* footer */}
            {/* <div class="row">
                    <div class="col-md-12">
                        <div class="py-3">© 2022 Goodup. Designd By ThemezHub.</div>
                    </div>
                </div> */}
          </div>}
        </div>
        {/* ======================= dashboard Detail End ======================== */}
        <a id="tops-button" className="top-scroll" title="Back to top" href="#">
          <i className="ti-arrow-up" />
        </a>
      </div>
      {/* ============================================================== */}
      {/* End Wrapper */}
      {/* ============================================================== */}
      {/* ============================================================== */}
      {/* All Jquery */}
      {/* ============================================================== */}
      {/* ============================================================== */}
      {/* This page plugins */}
      {/* ============================================================== */}
    </div>
  );
};

export default Savedlisting;
