import React from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Topcategory from "./Topcategory";
import Dealsoftheday from "./Dealsoftheday";
import Eventmain from "./Eventmain";
import Download from "./Download";
import Job from "./Job";
import Lesprasanto from "./Lespresento";
import Educational from "./Educational";
import Volunteering from "./Volunteering";
import apiurl from "./config";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import Google from "./Google";
import Lugares from "./Lugares";
import Sabiasque from "./Sabiasque";
import { getToken } from "firebase/messaging";
import { messaging } from "./Firebase";
import Player from "./Player";
import Puerto from "./Puerto";
import Eventsinpr from "./Eventsinpr";
import Placestoeat from "./Placestoeat";
import Socialmedia from "./Socialmedia";

function Home() {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState("");
  const [first_name, setFirstname] = useState("");

  const [output, setOutput] = useState("");
  const [last_name, setLastname] = useState("");
  const [user_email, setEmail] = useState("");
  const [zip, setZipcode] = useState("");
  const [user_password, setPassword] = useState("");

  const navigate = useNavigate();

  const [email, setEemail] = useState("");
  const [password, setPaassword] = useState("");
  const [currentToken, setCurrentToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailforgot, setEemailforgot] = useState("");

  const [category, setCategory] = useState([]);
  const [details, setDetails] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
 const [bannerres, setbannerres] = useState();
  // let idd = secureLocalStorage.getItem("coquiid");
  let idd = "1222"
  const categoryget = async () => {
    let tokenn = secureLocalStorage.getItem("token");
    let idd = secureLocalStorage.getItem("coquiid");
    const options = {
      headers: {
        Authorization: `${tokenn}`,
      },
    };

    const data = {
      user_id: idd,
      lat: details?.lat || "",
      long: details?.lng || "",
    };

    try {
      const res = await axios.post(
        `${apiurl[0].apiUrl}React/category_list_react`,
        data,
        options
      );
      // const res = await axios.post(`${apiurl[0].apiUrl}React/category`, data, options);

      setCategory(res.data.data);
    } catch (err) {}
  };

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);
      setDetails(latLng);
      categoryget();
    } catch (error) {}
  };

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);
    secureLocalStorage.setItem("catergoryidd", selectedValue);
  };

  const handleSubmitforgot = () => {
    if (!emailforgot) {
      setOutput("Please enter your email address.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailforgot)) {
      setOutput("Please enter a valid email address.");
      return;
    }

    let data = {
      user_email: emailforgot,
    };
    axios
      .post(`${apiurl[0].apiUrl}React/forget_password_react`, data)
      .then((response) => {
        if (response.data.token !== "error") {
          setOutput(response.data.msg);
          toast.error(response.data.msg);

          setTimeout(() => {
            window.location.reload(navigate("/"));
          }, 3000);

          setEemailforgot("");
        } else {
          setOutput(response.data.message);
        }
      })
      .catch((error) => {});
  };

  const handleSubmitt = (evt) => {
    evt.preventDefault();

    //   if (!('Notification' in window)) {

    //     return;
    // }
    // if (Notification.permission === 'granted') {

    //     let fcmtoken = await getToken(messaging, { vapidKey: 'BK0anho2f3mYHQxFitww4g7zgkwMo_8P53ptrbci04eh_TjnbLR99iw11SE5Bg3ao6iHoPdzeuK9lmllx0EBoSE' })

    //     secureLocalStorage.setItem("astro_fcm", fcmtoken)

    // } else if (Notification.permission === 'denied') {

    //     const confirmed = window.confirm(
    //         'You have denied notification permission. Do you want to enable it manually in your browser settings?'
    //     );
    //     if (confirmed) {

    //     }
    // } else {
    //     try {
    //         const permissionResult = await Notification.requestPermission();
    //         if (permissionResult === 'granted') {
    //         } else if (permissionResult === 'denied') {

    //         } else {

    //         }
    //     } catch (error) {
    //         console.error('Error requesting notification permission:', error);
    //     }
    // }

    if (!email) {
      setOutput("*Email is required");
      return;
    } else if (!password) {
      setOutput("*Password is required");
      return;
    }
    let fcm_id = secureLocalStorage.getItem("astro_fcm");

    const userDetails = {
      user_email: email,
      user_password: password,
      fcm_id: fcm_id ? fcm_id : "123456",
      lat: latitude ? latitude : "22.75555",
      long: longitude ? longitude : "75.44444",
    };

    axios
      .post(`${apiurl[0].apiUrl}React/login_react_new`, userDetails)
      .then((response) => {
        if (response.data.result) {
          const responseData = response.data.userDetails;
          secureLocalStorage.setItem("coquiid", response.data.data.id);

          secureLocalStorage.setItem("token", response.data.data.token);

          toast.success(response.data.msg);

          setTimeout(() => {
            window.location.reload(navigate("/dashboard"));
          }, 2000);
        } else {
          if (response.data.message === "User not found") {
            setOutput("Invalid user. Please register first.");
          } else {
            setOutput("Invalid user or verify your account.");
          }
          setEemail("");
          setPaassword("");
        }
      })
      .catch((err) => {
        //console.log(err);
        setOutput("Incorrect email & password");
      });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (!first_name) {
      setOutput("Please enter your First name.");
      return;
    }

    if (!last_name) {
      setOutput("Please enter your Last name.");
      return;
    }

    if (!zip) {
      setOutput("Please enter your zipcode.");
      return;
    }

    if (!user_email) {
      setOutput("Please enter your email address.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(user_email)) {
      setOutput("Please enter a valid email address.");
      return;
    }

    if (!user_password) {
      setOutput("Please enter a password.");
      return;
    }

    let userDetails = {
      first_name,
      last_name,
      user_email,
      user_password,
      zip,
      fcm_id: "12345",
    };

    try {
      const response = await axios.post(
        `${apiurl[0].apiUrl}React/user_signup_react`,
        userDetails
      );

      if (response.data.token !== "error") {
        // setOutput("User registered successfully.");
        toast.success(response.data.msg);

        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        setOutput(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.result === "false" &&
        error.response.data.message ===
          "user allready registered, please go to login page.."
      ) {
        setOutput(
          "The email is already registered. Please go to the login page."
        );
      } else {
        //console.log(error);
      }
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);

          secureLocalStorage.setItem("latitude", latitude);
          secureLocalStorage.setItem("longitude", longitude);
        },
        (error) => {
          //console.error("Error getting geolocation:", error.message);
        }
      );
    } else {
      //console.error("Geolocation is not supported by this browser.");
    }
  }, []);


  useEffect(()=>{
    bannerdata();
  },[0])
  let bannerdata = ()=>{
    axios.get(`${apiurl[0].apiUrl}React/get_banner`).then((res)=>{
      setbannerres(res.data)
    }).catch((error)=>{

    })
  }
 // Add an empty dependency array to ensure the effect runs only once on mount
  
 
    let lng = secureLocalStorage.getItem("language");
   
  
  return (
    <div>
      <ToastContainer />
      <Header />

      <div className="preloader" />

      <div id="main-wrapper" >
        <div className="clearfix" />

        <div 
          className=" home-banner margin-bottom-0"
          style={{
            background: `linear-gradient(to left, transparent 100%, #101219), url(${bannerres?.path + bannerres?.data[0]?.image})`,
            // backgroundSize: 'cover !important',
            // backgroundPosition: 'center !important'
          }}
          
          // style={{backgroundSize:'cover !important' ,backgroundPosition:'center !important' ,
          //   background: `linear-gradient(to left, transparent 100%, #101219), url(${bannerres?.path + bannerres?.data[0]?.image})`,
          // }}
          // style={{
          //   background: "#f41b3b url(assets/img/Logo_Official1.jpg) no-repeat",
          // }}
          //data-overlay={5}
        >
          <div className="container" >
            <div className="row justify-content-center">
              <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12 col-12">
                {/* <div className="banner_caption text-center mb-5"> */}
                {lng === "English" ? <div
                  className="banner_caption text-center "
                  style={{ marginTop: "" }}
                >
                  <h1 className="banner_title ft-bold-blac " style={{color:'',fontWeight:'bold'}}>
                    Explore Great Place in Your Town
                    
                  </h1>
                  <p className="fs-md ft-medium" style={{color:'',visibility:'hidden'}}>
                    Explore wonderful place to stay, salon, shoping, massage or
                    visit local areas.
                  </p>
                </div> : <div
                  className="banner_caption text-center "
                  style={{ marginTop: "" }}
                >
                  <h1 className="banner_title ft-bold-blac " style={{color:'',fontWeight:'bold'}}>
                  Explora un gran lugar en tu ciudad
                    
                  </h1>
                  <p className="fs-md ft-medium" style={{color:'',visibility:'hidden'}}>
                  Explore un maravilloso lugar para quedarse, salón, ir de compras, masajes o
                    visitar áreas locales.
                  </p>
                </div>}
                {lng === "English" ? (
                 
                    <form className="gray main-search-wrap fl-wrap ">
                    <div className="main-search-item gray" style={{backgroundColor:'#f4f4f7'}}>
                      <PlacesAutocomplete
                        value={address}
                        onChange={(newValue) => setAddress(newValue)}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              style={{ width: "100%" ,backgroundColor:'#f4f4f7' }}
                              className="form-control gray"
                              {...getInputProps({
                                placeholder: "search for a city.",
                              })}
                            />
                            <div>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>

                    <div className="main-search-item ">
                      <select
                        className="form-control gray"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        <option value>Choose Categories</option>
                        {category?.map((items) => {
                          return (
                            <option key={items.id} value={items.id}>
                              {items?.category_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="main-search-button">
                      <button
                        type="submit"
                        className="btn full-width theme-bg text-white"
                      >
                        <a href="/halfmapsearch">
                          Search
                          <i className="fas fa-search" />
                        </a>
                      </button>
                    </div>
                  </form>
                  
                ) : (
                  <form className="gray main-search-wrap fl-wrap ">
                    <div className="main-search-item gray" style={{backgroundColor:'#f4f4f7'}}>
                      <PlacesAutocomplete
                        value={address}
                        onChange={(newValue) => setAddress(newValue)}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              style={{ width: "100%" ,backgroundColor:'#f4f4f7' }}
                              className="form-control gray"
                              {...getInputProps({
                                placeholder: "buscar una ciudad",
                              })}
                            />
                            <div>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>

                    <div className="main-search-item ">
                      <select
                        className="form-control gray"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        <option value>Elija categorías</option>
                        {category?.map((items) => {
                          return (
                            <option key={items.id} value={items.id}>
                              {items?.category_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="main-search-button">
                      <button
                        type="submit"
                        className="btn full-width theme-bg text-white"
                      >
                        <a href="/halfmapsearch">
                        Buscar
                          <i className="fas fa-search" />
                        </a>
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>

        <Topcategory />
        <Player />

        <Eventmain />
        <Dealsoftheday />
        {/* <Lugares /> */}
        <Lesprasanto />
        <Sabiasque />
        {/* <Educational />
        <Puerto/>
        <Eventsinpr/>
        <Placestoeat/> */}
        {/* <Volunteering /> */}
        {/* <Job /> */}
<Socialmedia/>
        <Download />

        <Footer />
      </div>
      <div
        className="modal fade"
        id="forgot"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="forgotmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="forgotmodal">
            <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Forgot Your Password</h4>
              </div>
              {output && <font style={{ color: "red" }}>{output}</font>}
              {/* <form onClick={handleSubmitforgot} className="submit-form"> */}
              <div className="form-group">
                <label className="mb-1">Phone</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Addres"
                  value={emailforgot}
                  onChange={(e) => setEemailforgot(e.target.value)}
                  className="form-control rounded bg-light"
                />
              </div>

              <div className="form-group">
                <button
                  onClick={handleSubmitforgot}
                  type="submit"
                  className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                >
                  Submit
                </button>
              </div>

              {/* </form> */}
            </div>
          </div>
        </div>
      </div>

      {/* Log In Modal */}

      <div
        className="modal fade"
        id="login"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="loginmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="loginmodal">
            <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Login Your Account</h4>
              </div>
              {output && <font style={{ color: "red" }}>{output}</font>}
              <form onClick={handleSubmitt} className="submit-form">
                <div className="form-group">
                  <label className="mb-1">Phone</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Addres"
                    value={email}
                    onChange={(e) => setEemail(e.target.value)}
                    className="form-control rounded bg-light"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Password</label>
                  <input
                    type="password"
                    id="form-login-password-2"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPaassword(e.target.value)}
                    className="form-control rounded bg-light"
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-1">
                      <input
                        id="dd"
                        className="checkbox-custom"
                        name="dd"
                        type="checkbox"
                        defaultChecked
                      />
                      <label
                        data-bs-toggle="modal"
                        data-bs-target="#forgot"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        htmlFor="dd"
                        className="checkbox-custom-label"
                      >
                        Forgot Password
                      </label>
                    </div>
                    <div className="eltio_k2">
                      {/* <a href="#" className="theme-cl">
                          Lost Your Password?
                        </a> */}

                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#signup"
                        className="ft-bold"
                      >
                        <i className="fas fa-user-plus me-1 theme-cl" />
                        Sign Up
                      </a>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                  >
                    Log In
                  </button>
                </div>
                <div className="form-group text-center mb-0">
                  <p className="extra">Or login with</p>
                  <div className="option-log">
                    {/* <div className="single-log-opt">
                        <a href="javascript:void(0);" className="log-btn">
                          <img
                            src="assets/img/c-1.png"
                            className="img-fluid"
                            alt=""
                          />
                          Login with Google
                          
                          

                        </a>
                      </div>
                      <div className="single-log-opt">
                        <a href="javascript:void(0);" className="log-btn">
                          <img
                            src="assets/img/facebook.png"
                            className="img-fluid"
                            alt=""
                          />
                          Login with Facebook
                        </a>
                      </div> */}
                  </div>
                  <Google />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
      {/* Sign up Modal */}
      <div
        className="modal fade"
        id="signup"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="signupmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="signupmodal">
            <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Sign Up</h4>
              </div>
              {output && <font style={{ color: "red" }}>{output}</font>}
              <form onSubmit={handleSubmit} className="submit-form">
                <div className="form-group">
                  <label className="mb-1">First Name</label>
                  <input
                    value={first_name}
                    onChange={(e) => setFirstname(e.target.value)}
                    type="text"
                    className="form-control rounded bg-light"
                    placeholder="Firstname*"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Last Name</label>
                  <input
                    value={last_name}
                    onChange={(e) => setLastname(e.target.value)}
                    type="text"
                    className="form-control rounded bg-light"
                    placeholder="Lastname*"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Zip Code</label>
                  <input
                    value={zip}
                    onChange={(e) => setZipcode(e.target.value)}
                    type="number"
                    className="form-control rounded bg-light"
                    placeholder="Zip Code*"
                    maxLength={6}
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Email</label>
                  <input
                    value={user_email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control rounded bg-light"
                    placeholder="Email*"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Password</label>
                  <input
                    maxLength={"8"}
                    value={user_password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control rounded bg-light"
                    placeholder="Password*"
                  />
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                  >
                    Sign Up
                  </button>
                </div>
                {/* <div className="form-group text-center mb-0">
                    <p className="extra">Or login with</p>
                    <div className="option-log">
                      <div className="single-log-opt">
                        <a href="javascript:void(0);" className="log-btn">
                          <img
                            src="assets/img/c-1.png"
                            className="img-fluid"
                            alt=""
                          />
                          Login with Google
                        </a>
                      </div>
                      <div className="single-log-opt">
                        <a href="javascript:void(0);" className="log-btn">
                          <img
                            src="assets/img/facebook.png"
                            className="img-fluid"
                            alt=""
                          />
                          Login with Facebook
                        </a>
                      </div>
                    </div>
                  </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
      <a id="tops-button" className="top-scroll" title="Back to top" href="#">
        <i className="ti-arrow-up" />
      </a>
    </div>
  );
}

export default Home;
