import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import apiurl from "./config";
import { toast } from "react-toastify";
import { Toaster } from "react-hot-toast";
import Google from "./Google";

const Header2 = () => {
  const [address, setAddress] = useState("");
  const [email, setEemail] = useState("");
  const [password, setPaassword] = useState("");
  const [output, setOutput] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [emailforgot, setEemailforgot] = useState("");
  const navigate = useNavigate();
  const [policyData, setPolicyData] = useState([]);

  useEffect(() => {
    getDataterm();
  }, [0]);
  const getDataterm = () => {
    axios
      .get(`${apiurl[0].apiUrl}React/get_terms_condtion`)
      .then((res) => setPolicyData(res.data.data));
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    setAddress(value);
    secureLocalStorage.setItem("valuee", value);

    secureLocalStorage.setItem("lat", JSON.stringify(latLng));
  };
  let latlongg = secureLocalStorage.getItem("lat");
  let parsevalue = JSON.parse(latlongg);
  const [details, setDetails] = useState(parsevalue);

  const idd = secureLocalStorage.getItem("coquiid");

  let logout = async () => {
    const id = secureLocalStorage.getItem("coquiid");

    const data = {
      user_id: id,
    };

    axios.post(`${apiurl[0].apiUrl}React/logout_react`, data);
    secureLocalStorage.removeItem("coquiid");
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("volunteringid");
    secureLocalStorage.removeItem("educaltioncalidd");
    secureLocalStorage.removeItem("eventid");
    secureLocalStorage.removeItem("lessid");
    secureLocalStorage.removeItem("catergoryid");
    secureLocalStorage.removeItem("listingserachid");
    secureLocalStorage.removeItem("listingid");
    secureLocalStorage.removeItem("latitude");
    secureLocalStorage.removeItem("suncategoryidd");
    secureLocalStorage.removeItem("catergoryid");
    secureLocalStorage.removeItem("_id");

    
  };

  const handleSubmitt = (evt) => {
    evt.preventDefault();

    if (!email) {
      setOutput("*Email is required");
      return;
    } else if (!password) {
      setOutput("*Password is required");
      return;
    }
    let fcm_id = secureLocalStorage.getItem("astro_fcm");

    const userDetails = {
      user_email: email,
      user_password: password,
      fcm_id: fcm_id ? fcm_id : "123456",
      lat: latitude ? latitude : "22.75555",
      long: longitude ? longitude : "75.44444",
    };

    axios
      .post(`${apiurl[0].apiUrl}React/login_react_new`, userDetails)
      .then((response) => {
        if (response.data.result) {
          const responseData = response.data.userDetails;
          secureLocalStorage.setItem("coquiid", response.data.data.id);

          secureLocalStorage.setItem("token", response.data.data.token);

          toast.success(response.data.msg);

          setTimeout(() => {
            window.location.reload(navigate("/dashboard"));
          }, 2000);
        } else {
          if (response.data.message === "User not found") {
            setOutput("Invalid user. Please register first.");
          } else {
            setOutput("Invalid user or verify your account.");
          }
          setEemail("");
          setPaassword("");
        }
      })
      .catch((err) => {
        //console.log(err);
        toast.error("Incorrect data provide by your side");
      });
  };

  const [output1 ,setOutput1] = useState();
  const handleSubmitforgot = () => {
    if (!emailforgot) {
      setOutput1("Please enter your email address.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailforgot)) {
      setOutput1("Please enter a valid email address.");
      return;
    }

    let data = {
      user_email: emailforgot,
    };
    axios
      .post(`${apiurl[0].apiUrl}React/forget_password_react`, data)
      .then((response) => {

        if (response.data.token !== "error") {
          setOutput1(response.data.msg);
          toast.error(response.data.msg);

          // setTimeout(() => {
          //   window.location.reload(navigate("/"));
          // }, 3000);

          // setEemailforgot("");
        } else {
          setOutput1(response.data.message);
        }
      })
      .catch((error) => {});
  };




  const [category, setcategory] = useState([]);
  
  const [category1, setcategory1] = useState([]);
  
  const [category2, setcategory2] = useState([]);
 
  const [category3, setCategory3] = useState([]);
  useEffect(() => {
    categoryget();
  }, []);

  const categoryget = () => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
    };

 

    const data = JSON.stringify({
      category_id: "7",
    });

    axios
      .post(`${apiurl[0].apiUrl}React/sub_category`, data, options)
      .then((res) => {
        //console.log("res",res)
        setcategory(res.data.data);
      })
      .catch((err) => {
        //console.error(err);
      });
  };







  useEffect(() => {
    subcategoryget1();
  }, []);

  const subcategoryget1 = () => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
    };


    const data = JSON.stringify({
      category_id: "22",
    });

    axios
      .post(`${apiurl[0].apiUrl}React/sub_category`, data, options)
      .then((res) => {
       // console.log("res",res)
        setcategory1(res.data.data);
      })
      .catch((err) => {
        //console.error(err);
      });
  };






  useEffect(() => {
    subcategoryget2();
  }, []);

  const subcategoryget2 = () => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
    };
    

    const data = JSON.stringify({
      category_id: "15",
    });

    axios
      .post(`${apiurl[0].apiUrl}React/sub_category`, data, options)
      .then((res) => {
        //console.log("res",res)
        setcategory2(res.data.data);
      })
      .catch((err) => {
        
      });
  };

  useEffect(() => {
    categoryget3();
  }, []);
  const categoryget3 = async () => {
    let lati = secureLocalStorage.getItem("latitude");
    let longi = secureLocalStorage.getItem("longitude");
    const data = JSON.stringify({
      lat: lati ? lati : "",
      long: longi ? longi : "",
    });
    try {
      const res = await axios.post(`${apiurl[0].apiUrl}React/category`, data);
      //console.log("res",res)
      setCategory3(res.data.data);

    } catch (err) {

    }
  };

  let lng = secureLocalStorage.getItem("language")
  return (
    <div>
      <Toaster />
      {lng === "English" ? <div className="header header-light dark-text head-border">
        <div className="container-fluid px-4">
          <nav id="navigation" className="navigation navigation-landscape">
            <div className="nav-header">
              <a className="nav-brand" href="/">
                <img src="assets/img/logoss.png" className="logo" alt="" />
              </a>
              <div className="nav-toggle" />
              <div className="mobile_nav">
                {idd == null ? (
                  <ul>
                    <li>
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#login"
                        className="theme-cl fs-lg"
                      >
                        <i className="lni lni-user" />
                      </Link>
                    </li>
                    <li>
                      <a
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#login"
                        className="crs_yuo12 w-auto text-white theme-bg"
                      >
                        <span className="embos_45">
                          <i className="fas fa-plus me-2" />
                          Add Listing
                        </span>
                      </a>
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <a href="/dashboard" className="theme-cl fs-lg">
                        <i className="lni lni-user" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/dashboard"
                        className="crs_yuo12 w-auto text-white theme-bg"
                      >
                        <span className="embos_45">
                          <i className="fas fa-plus me-2" />
                          My account
                        </span>
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className="nav-menus-wrapper">
              <form className="main-search-wrap fl-wrap half-column">
                <div className="main-search-item">
                  <span className="search-tag">Find</span>
                  <input
                    type="text"
                    className="form-control radius"
                    placeholder="Search here..."
                  />
                </div>
                <div className="main-search-item">
                  <span className="search-tag">Where</span>
                  {/* <input type="text" className="form-control" placeholder="San Francisco, CA" /> */}

                  <PlacesAutocomplete
                    value={address}
                    onChange={(newValue) => setAddress(newValue)}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          style={{ width: "100%" }}
                          className="form-control"
                          {...getInputProps({
                            placeholder: "Enter Address",
                          })}
                        />
                        <div>
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                            };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="main-search-button">
                  <a href="/halfmapsearch"></a>
                  <button
                    type="submit"
                    className="btn full-width theme-bg text-white"
                  >
                    <a href="/halfmapsearch">
                      <i className="fas fa-search" />
                    </a>
                  </button>
                </div>
              </form>
              {idd == null ? (null
                // <ul className="nav-menu nav-menu-social align-to-right">
                //   <li>
                //     <Link
                //       to="#"
                //       data-bs-toggle="modal"
                //       data-bs-target="#login"
                //       className="ft-bold"
                //     >
                //       <i className="fas fa-sign-in-alt me-1 theme-cl" />
                //       Log In
                //     </Link>
                //   </li>
                  
                // </ul>
              ) : (
                <ul className="nav-menu nav-menu-social align-to-right">
                  <li onClick={logout}>
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#login"
                      className="ft-bold"
                    >
                      <i className="fas fa-sign-in-alt me-1 theme-cl" />
                      LogOut
                    </Link>
                  </li>
                  {/* <li class=""><Link to="#" data-bs-toggle="modal" data-bs-target="#login" class="crs_yuo12 w-auto text-black "><span class="embos_45"><i class="fas fa-user-plus me-1 theme-cl"></i>Sign Up</span></Link></li> */}

                  {/* <li class="">
										<Link to="#" data-bs-toggle="modal" data-bs-target="#signup" class="crs_yuo12 w-auto text-black ">
											<span class="embos_45"><i class="fas fa-user-plus me-1 theme-cl"></i>Sign Up</span>
										</Link>
									</li> */}
                </ul>
              )}
            </div>
          </nav>
        </div>
      </div> :<div className="header header-light dark-text head-border">
        <div className="container-fluid px-4">
          <nav id="navigation" className="navigation navigation-landscape">
            <div className="nav-header">
              <a className="nav-brand" href="/">
                <img src="assets/img/logoss.png" className="logo" alt="" />
              </a>
              <div className="nav-toggle" />
              <div className="mobile_nav">
                {idd == null ? (
                  <ul>
                    <li>
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#login"
                        className="theme-cl fs-lg"
                      >
                        <i className="lni lni-user" />
                      </Link>
                    </li>
                    <li>
                      <a
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#login"
                        className="crs_yuo12 w-auto text-white theme-bg"
                      >
                        <span className="embos_45">
                          <i className="fas fa-plus me-2" />
                          Agregar listado
                        </span>
                      </a>
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <a href="/dashboard" className="theme-cl fs-lg">
                        <i className="lni lni-user" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="/dashboard"
                        className="crs_yuo12 w-auto text-white theme-bg"
                      >
                        <span className="embos_45">
                          <i className="fas fa-plus me-2" />
                          Mi cuenta
                        </span>
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className="nav-menus-wrapper">
              <form className="main-search-wrap fl-wrap half-column">
                <div className="main-search-item">
                  <span className="search-tag">Encontrar</span>
                  <input
                    type="text"
                    className="form-control radius"
                    placeholder="Busca aquí...
                    "
                  />
                </div>
                <div className="main-search-item">
                  <span className="search-tag">Dónde
</span>
                  

                  <PlacesAutocomplete
                    value={address}
                    onChange={(newValue) => setAddress(newValue)}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          style={{ width: "100%" }}
                          className="form-control"
                          {...getInputProps({
                            placeholder: "Introduce la ciudad",
                          })}
                        />
                        <div>
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                            };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="main-search-button">
                  <a href="/halfmapsearch"></a>
                  <button
                    type="submit"
                    className="btn full-width theme-bg text-white"
                  >
                    <a href="/halfmapsearch">
                      <i className="fas fa-search" />
                    </a>
                  </button>
                </div>
              </form>
              {idd == null ? (null
               
              ) : (
                <ul className="nav-menu nav-menu-social align-to-right">
                  <li onClick={logout}>
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#login"
                      className="ft-bold"
                    >
                      <i className="fas fa-sign-in-alt me-1 theme-cl" />
                      Cerrar sesión
                    </Link>
                  </li>
                  
                </ul>
              )}
            </div>
          </nav>
        </div>
      </div>}





      
      {lng === "English" ? <section className="cats-filters py-3">
        <div className="container-fluid px-4">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-9 col-lg-9 col-md-9 col-12">
              <div className="Goodup-all-drp">
                <div className="Goodup-single-drp small">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Restaurants
                    </button>
                    <ul className="dropdown-menu" style={{minWidth:'550px' ,height:'250px' ,overflow:'scroll' , scrollbarWidth:'none'}}>
                    {category?.map((items) => {
                    return (
                      <li onClick={() => {
                        secureLocalStorage.setItem(
                          "suncategoryidd",
                          items.id
                        );

                        secureLocalStorage.setItem("catergoryid", "7");
                      }}
                      key={items.id}
                      value={items.id}>
                        <a href="/listingsearch"  className="dropdown-item">
                        {/* <Link className="dropdown-item" to="/halfmapsearch"> */}
                          <img
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                            className="img-fluid"
                            width={20}
                            alt=""
                          />
                          {items?.sub_category_name}
                          </a>
                      </li>
                       );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="Goodup-single-drp small">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Home Services
                    </button>
                    <ul className="dropdown-menu" style={{minWidth:'550px' ,height:'250px' ,overflow:'scroll' , scrollbarWidth:'none'}}>
                    {category1?.map((items) => {
                    return (
                      <li onClick={() => {
                        secureLocalStorage.setItem(
                          "suncategoryidd",
                          items.id
                        );
                        secureLocalStorage.setItem("catergoryid", "22");
                      }}  key={items.id}
                      value={items.id} >
                         <a href="/listingsearch" className="dropdown-item">
                       
                          <img
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                            className="img-fluid"
                            width={20}
                            alt=""
                          />
                          {items?.sub_category_name}
                        </a>
                      </li>
                      );
                    })}
                    </ul>
                  </div>
                </div>
                <div className="Goodup-single-drp small">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Auto Services
                    </button>
                    <ul className="dropdown-menu" style={{minWidth:'550px' ,height:'250px' ,overflow:'scroll' , scrollbarWidth:'none'}}>
                    {category2?.map((items) => {
                    return (
                      <li onClick={() => {
                        secureLocalStorage.setItem(
                          "suncategoryidd",
                          items.id
                        );
                        secureLocalStorage.setItem("catergoryid", "15");
                      }}
                      key={items.id}
                      value={items.id}>
                        <a href="/listingsearch" className="dropdown-item" >
                          <img
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                            className="img-fluid"
                            width={20}
                            alt=""
                          />
                          {items?.sub_category_name}
                        </a>
                      </li>
                    )})}
                    </ul>
                  </div>
                </div>
                {/* <div className="Goodup-single-drp small">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </button>
                    <ul className="dropdown-menu" style={{minWidth:'550px' ,height:'250px' ,overflow:'scroll' , scrollbarWidth:'none'}}> 
                    {category3?.map((items) => {
                    return (
                         <li  onClick={() => {
                          secureLocalStorage.setItem("catergoryid", items.id);
                          secureLocalStorage.setItem("suncategoryidd", items.id);

                          
                        }}
                        key={items.id}
                        value={items.id}
                        
                         >
                         <a className="dropdown-item" href="/listingsearch">
                           <img
                             src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.category_image
                            }
                             className="img-fluid"
                             width={20}
                             alt=""
                           />
                           {items?.category_name}
                         </a>
                       </li>
                     
                    )})}
                      
                     
                     
                    </ul>
                  </div>
                </div> */}
                {/* <div className="Goodup-single-drp small">
            <div className="btn-group">
              <button type="button" className="btn active" onclick="openSearch()">Filters<i className="filt-count">3</i></button>
            </div>
          </div> */}
                {/* <div className="Goodup-single-drp small">
            <div className="btn-group">
              <button type="button" className="btn bg-dark text-light">Update</button>
            </div>
          </div> */}
              </div>
            </div>
            {/* <div className="col-xl-3 col-lg-3 col-md-3 col-12">
        <div className="text-right"><button className="map-btn" onclick="removeClass()">Map<i className="fas fa-map ms-1" /></button></div>
      </div> */}
          </div>
        </div>
      </section> : <section className="cats-filters py-3">
        <div className="container-fluid px-4">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-9 col-lg-9 col-md-9 col-12">
              <div className="Goodup-all-drp">
                <div className="Goodup-single-drp small">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Restaurantes

                    </button>
                    <ul className="dropdown-menu" style={{minWidth:'550px' ,height:'250px' ,overflow:'scroll' , scrollbarWidth:'none'}}>
                    {category?.map((items) => {
                    return (
                      <li onClick={() => {
                        secureLocalStorage.setItem(
                          "suncategoryidd",
                          items.id
                        );

                        secureLocalStorage.setItem("catergoryid", "7");
                      }}
                      key={items.id}
                      value={items.id}>
                        <a href="/listingsearch"  className="dropdown-item">
                        {/* <Link className="dropdown-item" to="/halfmapsearch"> */}
                          <img
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                            className="img-fluid"
                            width={20}
                            alt=""
                          />
                          {items?.sp_sub_category_name}
                          </a>
                      </li>
                       );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="Goodup-single-drp small">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Servicios para el hogar
                    </button>
                    <ul className="dropdown-menu" style={{minWidth:'550px' ,height:'250px' ,overflow:'scroll' , scrollbarWidth:'none'}}>
                    {category1?.map((items) => {
                    return (
                      <li onClick={() => {
                        secureLocalStorage.setItem(
                          "suncategoryidd",
                          items.id
                        );
                        secureLocalStorage.setItem("catergoryid", "22");
                      }}  key={items.id}
                      value={items.id} >
                         <a href="/listingsearch" className="dropdown-item">
                       
                          <img
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                            className="img-fluid"
                            width={20}
                            alt=""
                          />
                          {items?.sp_sub_category_name}
                        </a>
                      </li>
                      );
                    })}
                    </ul>
                  </div>
                </div>
                <div className="Goodup-single-drp small">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Servicios para automóviles
                    </button>
                    <ul className="dropdown-menu" style={{minWidth:'550px' ,height:'250px' ,overflow:'scroll' , scrollbarWidth:'none'}}>
                    {category2?.map((items) => {
                    return (
                      <li onClick={() => {
                        secureLocalStorage.setItem(
                          "suncategoryidd",
                          items.id
                        );
                        secureLocalStorage.setItem("catergoryid", "15");
                      }}
                      key={items.id}
                      value={items.id}>
                        <a href="/listingsearch" className="dropdown-item" >
                          <img
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                            className="img-fluid"
                            width={20}
                            alt=""
                          />
                          {items?.sp_sub_category_name}
                        </a>
                      </li>
                    )})}
                    </ul>
                  </div>
                </div>
                {/* <div className="Goodup-single-drp small">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </button>
                    <ul className="dropdown-menu" style={{minWidth:'550px' ,height:'250px' ,overflow:'scroll' , scrollbarWidth:'none'}}> 
                    {category3?.map((items) => {
                    return (
                         <li  onClick={() => {
                          secureLocalStorage.setItem("catergoryid", items.id);
                          secureLocalStorage.setItem("suncategoryidd", items.id);

                          
                        }}
                        key={items.id}
                        value={items.id}
                        
                         >
                         <a className="dropdown-item" href="/listingsearch">
                           <img
                             src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.category_image
                            }
                             className="img-fluid"
                             width={20}
                             alt=""
                           />
                           {items?.category_name}
                         </a>
                       </li>
                     
                    )})}
                      
                     
                     
                    </ul>
                  </div>
                </div> */}
                {/* <div className="Goodup-single-drp small">
            <div className="btn-group">
              <button type="button" className="btn active" onclick="openSearch()">Filters<i className="filt-count">3</i></button>
            </div>
          </div> */}
                {/* <div className="Goodup-single-drp small">
            <div className="btn-group">
              <button type="button" className="btn bg-dark text-light">Update</button>
            </div>
          </div> */}
              </div>
            </div>
            {/* <div className="col-xl-3 col-lg-3 col-md-3 col-12">
        <div className="text-right"><button className="map-btn" onclick="removeClass()">Map<i className="fas fa-map ms-1" /></button></div>
      </div> */}
          </div>
        </div>
      </section>}

      <div
        className="modal fade"
        id="login"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="loginmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="loginmodal">
            <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Login Your Account</h4>
              </div>
              {output && <font style={{ color: "red" }}>{output}</font>}
              <form onSubmit={handleSubmitt} className="submit-form">
                <div className="form-group">
                  <label className="mb-1">Email & Phone </label>
                  <input required
                    type="text"
                    name="email"
                    placeholder="Email or Phone Number "
                    value={email}
                    onChange={(e) => setEemail(e.target.value)}
                    className="form-control rounded bg-light"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Password</label>
                  <input
                    type="password"
                    required
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPaassword(e.target.value)}
                    className="form-control rounded bg-light"
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                  <div class="flex-1">
            
            <input required id="a1" class="checkbox-custom" name="open" type="checkbox" />
            <label for="a1" class="checkbox-custom-label"><a href="#" data-bs-toggle="modal"
            data-bs-target="#term"
            data-bs-dismiss="modal" class="theme-cl">Terms and Conditions</a></label>
          </div>	
          <div className="flex-1">
                      <input
                        id="dd"
                        className="checkbox-custom"
                        name="dd"
                        type="checkbox"
                        defaultChecked
                      />
                      
                      <label
                        data-bs-toggle="modal"
                        data-bs-target="#forgot"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        htmlFor="dd"
                        className="checkbox-custom-label"
                      >
                        Forgot Password
                      </label>
                    </div>
                    
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                  >
                    Log In
                  </button>
                </div>
                <div className="form-group text-center mb-0">
                  <p className="extra">Or login with</p>
                  <div className="option-log"></div>
                  <Google />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="forgot"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="forgotmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="forgotmodal">
            <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Forgot Your Password</h4>
              </div>
              {output1 && <font style={{ color: "red" }}>{output1}</font>}
              {/* <form onClick={handleSubmitforgot} className="submit-form"> */}
              <div className="form-group">
                <label className="mb-1">Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Addresss"
                  value={emailforgot}
                  onChange={(e) => setEemailforgot(e.target.value)}
                  className="form-control rounded bg-light"
                />
              </div>

              <div className="form-group mt-3">
                <button
                  onClick={handleSubmitforgot}
                  type="submit"
                  className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                >
                  Submit
                </button>
              </div>

              {/* </form> */}
            </div>
          </div>
        </div>
      </div>

      <div
          className="modal fade"
          id="term"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="termmodal"
          aria-hidden="true"
        >
          <div className="modal-dialog login-pop-form" role="document" style={{maxWidth:'80%'}}>
            <div className="modal-content" id="termmodal">
              <div className="modal-headers">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="ti-close" />
                </button>
              </div>
              <div className="modal-body p-5">
               
               
                <section className="middle" style={{overflow:'scroll' , height:'600px' , scrollbarWidth:'none'}}>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-11 col-lg-12 col-md-6 col-sm-12">
                {/* {policyData.map((faq)=>{
                    return( */}
                <div className="abt_caption">
                  <h2
                    className="ft-medium mb-4"
                    dangerouslySetInnerHTML={{ __html: policyData?.title }}
                  />

                  <p
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                      __html: policyData?.description,
                    }}
                  />
                </div>
                {/* )
                  })} */}
              </div>
            </div>
          </div>
        </section>

                

               </div>
              
            </div>
          </div>
        </div>
    </div>
  );
};

export default Header2;
