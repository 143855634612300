import React, { useEffect, useState } from "react";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import apiurl from "./config";

const Player = () => {
  const [event, setEvent] = useState();
 
  let token = secureLocalStorage.getItem("token");

  useEffect(() => {
    eventt();
  }, [0]);

  const eventt = () => {
    const options = {
      headers: {
        Authorization: `${token}`,
      },
    };

    axios
      .get(`${apiurl[0].apiUrl}React/youtube_podcast`, options)
      .then((res) => {
        setEvent(res.data.data);
      })
      .catch((err) => {});
  };

  const getVideoId = (url) => {
    if (!url) {
      return null;
    }

    const videoId = url.split("v=")[1];
    return videoId;
  };

  const getEmbedUrl = (url) => {
    const videoId = getVideoId(url);

    if (videoId !== null) {
      return `https://www.youtube.com/embed/${videoId}`;
    }

    return null;
  };
let lng = secureLocalStorage.getItem("language")
  return (
    <div>
      {/* <section className="space">
        <div className="container"></div>
      </section> */}
      <section className="space min pt-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="sec_title position-relative text-center mb-2">
               {lng === "English" ?  <h2 className="ft-bold">Announcements</h2> :  <h2 className="ft-bold">Anuncio</h2>}
                <br />
              </div>
            </div>
          </div>
          <div className="row justify-content-left">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="gup_blg_grid_box">
                <div className="gup_blg_grid_thumb">
                  <iframe style={{objectFit:'contain'}}

                    width="100%"
                    height="360"
                    src={getEmbedUrl(event?.link)}
                    title="YouTube Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Player;
