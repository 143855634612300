import React from 'react'
import secureLocalStorage from 'react-secure-storage'

const Socialmedia = () => {
	let lng = secureLocalStorage.getItem("language")
  return (
    <section className="pt-0">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12 content-column">
                  <div className="content_block_2 pr-3 py-0">
                    <div className="content-box">
                    <div className="sec_title position-relative text-center mb-5">
             
              {lng === "English" ? <h2 className="ft-bold">Social Media</h2> : <h2 className="ft-bold">Medios de comunicación social</h2>}
              
            </div>
                     
                   <div className='row'>
					  
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
			<a target='_blank' href='https://www.instagram.com/p/C4gp_MnxjsR/?igsh=MTFvMWwwcGxpbXBkdw=='>
							<div class="_jb_list72">
								
								<div class="_jb_list72_flex">
									<div class="_jb_list72_first">
										<div class="_jb_list72_yhumb">
											<img src="assets/img/c-5.png" class="img-fluid" alt=""/>	
										</div>
									</div>
									<div class="_jb_list72_last">
										<h4 class="_jb_title"><a target='_blank' href='https://www.instagram.com/p/C4gp_MnxjsR/?igsh=MTFvMWwwcGxpbXBkdw=='>Instagram</a></h4>
										 <div class="_times_jb" style={{visibility:'hidden'}}>$60k - 80k</div>
										
									</div>
								</div>
								
							</div>
							</a>
						</div>
						

						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
						<a target='_blank' href='https://www.facebook.com/groups/859160575966776/?ref=share_group_link&exp=7ffb'>
							<div class="_jb_list72">
								
								<div class="_jb_list72_flex">
									<div class="_jb_list72_first">
										<div class="_jb_list72_yhumb">
											<img src="assets/img/facebook.png" class="img-fluid" alt=""/>	
										</div>
									</div>
									<div class="_jb_list72_last">
										<h4 class="_jb_title"><a target='_blank' href='https://www.facebook.com/groups/859160575966776/?ref=share_group_link&exp=7ffb'>Facebook</a></h4>
										 <div class="_times_jb" style={{visibility:'hidden'}}>$60k - 80k</div>
										
									</div>
								</div>
								
							</div>
							</a>
						</div>
						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
						<a target='_blank' href='https://www.tiktok.com/@coquipages?_t=8kgQwFlFZ6J&_r=1'>
							<div class="_jb_list72">
								
								<div class="_jb_list72_flex">
									<div class="_jb_list72_first">
										<div class="_jb_list72_yhumb">
											<img src="assets/img/tiktok.png" class="img-fluid" alt=""/>	
										</div>
									</div>
									<div class="_jb_list72_last">
										<h4 class="_jb_title"><a target='_blank' href='https://www.tiktok.com/@coquipages?_t=8kgQwFlFZ6J&_r=1'>Tiktok</a></h4>
										 <div class="_times_jb" style={{visibility:'hidden'}}>$60k - 80k</div>
										
									</div>
								</div>
								
							</div>
							</a>
						</div>
						<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
						<a target='_blank' href='https://youtube.com/@Coquipages?si=QHq7tNsk1vbKtuaH'>
							<div class="_jb_list72">
								
								<div class="_jb_list72_flex">
									<div class="_jb_list72_first">
										<div class="_jb_list72_yhumb">
											<img width={200} src="assets/img/youtube1.png" class="img-fluid" alt=""/>	
										</div>
									</div>
									<div class="_jb_list72_last">
										<h4 class="_jb_title"><a target='_blank' href='https://youtube.com/@Coquipages?si=QHq7tNsk1vbKtuaH'>Youtube</a></h4>
										 <div class="_times_jb" style={{visibility:'hidden'}}>$60k - 80k</div>
										
									</div>
								</div>
								
							</div>
							</a>
						</div>
                    </div>
					</div>
                  </div>
                </div>
               
              </div>
            </div>
          </section>
  )
}

export default Socialmedia
