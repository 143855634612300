import React, { useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import { Toaster } from 'react-hot-toast';
import apiurl from './config';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage"; 
const Signuptobussiness = () => {
    const [output, setOutput] = useState("");
    const [first_name, setFirstname] = useState("");

  const [last_name, setLastname] = useState("");
  const [user_email, setEmail] = useState("");
  const [user_mobile, setuser_mobile] = useState("");
  const [zip, setZipcode] = useState("");
  const [user_password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (!first_name) {
      setOutput("Please enter your First name.");
      return;
    }

    if (!last_name) {
      setOutput("Please enter your Last name.");
      return;
    }

    if (!zip) {
      setOutput("Please enter your zipcode.");
      return;
    }

    if (!user_email) {
      setOutput("Please enter your email address.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(user_email)) {
      setOutput("Please enter a valid email address.");
      return;
    }

    if (!user_password) {
      setOutput("Please enter a password.");
      return;
    }

    let userDetails = {
      first_name,
      last_name,
      user_email,
      user_mobile,
      user_password,

      zip,
      fcm_id: "12345",
    };

    try {
      const response = await axios.post(
        `${apiurl[0].apiUrl}React/user_signup_react`,
        userDetails
      );

      if (response.data.token !== "error") {
        setOutput("Successfully user signup.");
        toast.success(response.data.msg);

        // setTimeout(() => {
        //   navigate("/login");
        // }, 3000);
      } else {
        toast.error(response.data.msg);
        setOutput(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.result === "false" &&
        error.response.data.message ===
          "user allready registered, please go to login page.."
      ) {
        setOutput(
          "The email is already registered. Please go to the login page."
        );
      } else {
      }
    }
  };

  let lng = secureLocalStorage.getItem("language")
  return (
    <div>
    {/* ============================================================== */}
    {/* Preloader - style you can find in spinners.css */}
    {/* ============================================================== */}
    <div className="preloader" />
    {/* ============================================================== */}
    {/* Main wrapper - style you can find in pages.scss */}
    {/* ============================================================== */}
    <div id="main-wrapper">
      {/* ============================================================== */}
      {/* Top header  */}
      {/* ============================================================== */}
      {/* Start Navigation */}
      <Header/>
      <Toaster/>
      {/* End Navigation */}
      <div className="clearfix" />
      {/* ============================================================== */}
      {/* Top header  */}
      {/* ============================================================== */}
      {/* ======================= Login Detail ======================== */}
    {lng === "English" ?   <section className="gray">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-12">
              <div className="signup-screen-wrap">
                <div className="signup-screen-single light">
                  <div className="text-center mb-4">
                    <h4 className="m-0 ft-medium">Create An Business Account</h4>
                  </div>
                  {output && <font style={{ color: "red" }}>{output}</font>}
                  <form onSubmit={handleSubmit} >
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label className="mb-1">First Name</label>
                          <input required
                    value={first_name}
                    onChange={(e) => setFirstname(e.target.value)}
                    type="text"
                    className="form-control rounded"
                    placeholder="Firstname*"/>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="mb-1">Last Name</label>
                          <input required
                    value={last_name}
                    onChange={(e) => setLastname(e.target.value)}
                    type="text"
                    className="form-control rounded"
                    placeholder="Lastname*" />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="mb-1">Zip Code</label>
                          <input required
                    value={zip}
                    onChange={(e) => setZipcode(e.target.value)}
                    type="number"
                    className="form-control rounded "
                    placeholder="Zip Code*"
                    maxLength={6} />
                        </div>
                      </div>
                     
                      <div className="col-6">
                        <div className="form-group">
                          <label className="mb-1">Email</label>
                          <input required
                    value={user_email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    placeholder="Email*" />
                        </div>
                      </div>
                      <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">Phone</label>
                      <input  required
                    value={user_mobile}
                    onChange={(e) => setuser_mobile(e.target.value)}
                    type="mobile"
                    maxLength={10}
                    minLength={10}
                    className="form-control rounded bg-light"
                    placeholder="Mobile*"/>
                    </div>
                    </div>
                    <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">Password</label>
                      <input required
                    maxLength={"8"}
                    value={user_password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control rounded bg-light"
                    placeholder="Password*"/>
                    </div>
                    </div>
                    </div>

                    
                    <div className="form-group mt-3 mb-3">
                      <button type="submit" className="btn btn-md full-width theme-bg  text-light rounded ft-medium">Sign Up</button>
                    </div>
                    <div className="form-group text-center mb-0">
                      <p className="extra">Or Signup with</p>
                      <div className="option-log">
                        <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/c-1.png" className="img-fluid" alt="" />Login with Google</a></div>
                        {/* <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/facebook.png" className="img-fluid" alt="" />Login with Facebook</a></div> */}

                        <div className="single-log-opt"><p className="mb-0">Have You Already An account? <a href="/login" className="ft-medium text-success">Sign In</a></p></div>
                      
                      
                        
                      </div>
                    </div>
                    {/* <div className="form-group text-center mt-4 mb-0">
                      <p className="mb-0">Have You Already An account? <a href="/logintobusiness" className="ft-medium text-success">Sign In</a></p>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> :   <section className="gray">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-12">
              <div className="signup-screen-wrap">
                <div className="signup-screen-single light">
                  <div className="text-center mb-4">
                    <h4 className="m-0 ft-medium">Crear una cuenta comercial</h4>
                  </div>
                  {output && <font style={{ color: "red" }}>{output}</font>}
                  <form onSubmit={handleSubmit} >
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label className="mb-1">Nombre de pila</label>
                          <input required
                    value={first_name}
                    onChange={(e) => setFirstname(e.target.value)}
                    type="text"
                    className="form-control rounded"
                    placeholder="Nombre de pila*"/>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="mb-1">Apellido</label>
                          <input required
                    value={last_name}
                    onChange={(e) => setLastname(e.target.value)}
                    type="text"
                    className="form-control rounded"
                    placeholder="Apellido*" />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="mb-1">Código postal</label>
                          <input required
                    value={zip}
                    onChange={(e) => setZipcode(e.target.value)}
                    type="number"
                    className="form-control rounded "
                    placeholder="Código postal*"
                    maxLength={6} />
                        </div>
                      </div>
                     
                      <div className="col-6">
                        <div className="form-group">
                          <label className="mb-1">Correo electrónico</label>
                          <input required
                    value={user_email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    placeholder="Correo electrónico*" />
                        </div>
                      </div>
                      <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">Teléfono</label>
                      <input  required
                    value={user_mobile}
                    onChange={(e) => setuser_mobile(e.target.value)}
                    type="mobile"
                    maxLength={10}
                    minLength={10}
                    className="form-control rounded bg-light"
                    placeholder="Teléfono*"/>
                    </div>
                    </div>
                    <div className="col-6">
                    <div className="form-group">
                      <label className="mb-1">Contraseña</label>
                      <input required
                    maxLength={"8"}
                    value={user_password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control rounded bg-light"
                    placeholder="Contraseña*"/>
                    </div>
                    </div>
                    </div>

                    
                    <div className="form-group mt-3 mb-3">
                      <button type="submit" className="btn btn-md full-width theme-bg  text-light rounded ft-medium">Inscribirse</button>
                    </div>
                    <div className="form-group text-center mb-0">
                      <p className="extra">O regístrate con</p>
                      <div className="option-log">
                        <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/c-1.png" className="img-fluid" alt="" />Iniciar sesión con Google</a></div>
                        {/* <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/facebook.png" className="img-fluid" alt="" />Login with Facebook</a></div> */}

                        <div className="single-log-opt"><p className="mb-0">¿Ya tienes una cuenta? <a href="/login" className="ft-medium text-success">Iniciar sesión</a></p></div>
                      
                      
                        
                      </div>
                    </div>
                    {/* <div className="form-group text-center mt-4 mb-0">
                      <p className="mb-0">Have You Already An account? <a href="/logintobusiness" className="ft-medium text-success">Sign In</a></p>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}
      {/* ======================= Login End ======================== */}
      {/* ============================ Footer Start ================================== */}
      <Footer/>
      {/* ============================ Footer End ================================== */}
      {/* Log In Modal */}
      <div className="modal fade" id="login" tabIndex={-1} role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="loginmodal">
            <div className="modal-headers">
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Login Your Account</h4>
              </div>
              <form className="submit-form">				
                <div className="form-group">
                  <label className="mb-1">Phone</label>
                  <input type="text" className="form-control rounded bg-light" placeholder="Phone*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Password</label>
                  <input type="password" className="form-control rounded bg-light" placeholder="Password*" />
                </div>
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-1">
                      <input id="dd" className="checkbox-custom" name="dd" type="checkbox" defaultChecked />
                      <label htmlFor="dd" className="checkbox-custom-label">Remember Me</label>
                    </div>	
                    <div className="eltio_k2">
                      <a href="#" className="theme-cl">Lost Your Password?</a>
                    </div>	
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-md full-width theme-bg text-light rounded ft-medium">Log In</button>
                </div>
                <div className="form-group text-center mb-0">
                  <p className="extra">Or login with</p>
                  <div className="option-log">
                    <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/c-1.png" className="img-fluid" alt="" />Login with Google</a></div>
                    <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/facebook.png" className="img-fluid" alt="" />Login with Facebook</a></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
      {/* Sign up Modal */}
      <div className="modal fade" id="signup" tabIndex={-1} role="dialog" aria-labelledby="signupmodal" aria-hidden="true">
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="signupmodal">
            <div className="modal-headers">
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Sign Up</h4>
              </div>
              <form className="submit-form">				
                <div className="form-group">
                  <label className="mb-1">First Name</label>
                  <input type="text" className="form-control rounded bg-light" placeholder="Firstname*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Last Name</label>
                  <input type="text" className="form-control rounded bg-light" placeholder="Lastname*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Phone</label>
                  <input type="text" className="form-control rounded bg-light" placeholder="Phone*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Email</label>
                  <input type="email" className="form-control rounded bg-light" placeholder="Email*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Password</label>
                  <input type="password" className="form-control rounded bg-light" placeholder="Password*" />
                </div>
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-1">
                      <input id="dd" className="checkbox-custom" name="dd" type="checkbox" defaultChecked />
                      <label htmlFor="dd" className="checkbox-custom-label">Remember Me</label>
                    </div>	
                    <div className="eltio_k2">
                      <a href="#" className="theme-cl">Lost Your Password?</a>
                    </div>	
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-md full-width theme-bg text-light rounded ft-medium">Sign Up</button>
                </div>
                <div className="form-group text-center mb-0">
                  <p className="extra">Or login with</p>
                  <div className="option-log">
                    <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/c-1.png" className="img-fluid" alt="" />Login with Google</a></div>
                    <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/facebook.png" className="img-fluid" alt="" />Login with Facebook</a></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
      <a id="tops-button" className="top-scroll" title="Back to top" href="#"><i className="ti-arrow-up" /></a>
    </div>
    {/* ============================================================== */}
    {/* End Wrapper */}
    {/* ============================================================== */}
    {/* ============================================================== */}
    {/* All Jquery */}
    {/* ============================================================== */}
    {/* ============================================================== */}
    {/* This page plugins */}
    {/* ============================================================== */}		
  </div>
  )
}

export default Signuptobussiness
