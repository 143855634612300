import React from 'react'
import apiurl from '../Component/config';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

const Mainnavigation = () => {

    let logout = async () => {
        const id = secureLocalStorage.getItem("coquiid");
    
        const data = {
          user_id: id,
        };
    
        axios.post(`${apiurl[0].apiUrl}React/logout_react`, data);
        secureLocalStorage.removeItem("coquiid");
        secureLocalStorage.removeItem("token");
        secureLocalStorage.removeItem("volunteringid");
        secureLocalStorage.removeItem("educaltioncalidd");
        secureLocalStorage.removeItem("eventid");
        secureLocalStorage.removeItem("lessid");
        secureLocalStorage.removeItem("catergoryid");
        secureLocalStorage.removeItem("listingserachid");
        secureLocalStorage.removeItem("listingid");
        secureLocalStorage.removeItem("latitude");
        secureLocalStorage.removeItem("longitude");
        secureLocalStorage.removeItem("suncategoryidd");
        secureLocalStorage.removeItem("catergoryid");
      };
    
let id =secureLocalStorage.getItem("mylistingid")
let lng = secureLocalStorage.getItem("language")
  return (
    <div className="collapse" id="MobNav">
    <div className="goodup-dashboard-nav sticky-top">
      {lng === "English" ? <div className="goodup-dashboard-inner">
        <ul data-submenu-title="Main Navigation">
          <li className="active">
            <a href="/bdashboard">
              <i className="lni lni-dashboard me-2" />
              Dashboard
            </a>
          </li>
          <li>
            <a href="/bmylisting">
              <i className="lni lni-files me-2" />
              My Listings
            </a>
          </li>
{id ? ("") : (<li>
            <a href="/baddlisting">
              <i className="lni lni-add-files me-2" />
              Add Listing
            </a>
          </li>) }

          
          <li>
            <a href="/bsavedlisting">
              <i className="lni lni-bookmark me-2" />
              Saved Listing
            </a>
          </li>
          {/* <li>
            <a href="/bmybooking">
              <i className="lni lni-briefcase me-2" />
              My Bookings<span className="count-tag bg-warning">4</span>
            </a>
          </li> */}
          <li>
            <a href="/bwallet">
              <i className="lni lni-mastercard me-2" />
              Wallet
            </a>
          </li>
          <li>
            <a href="/bmessage">
              <i className="lni lni-envelope me-2" />
              Messages<span className="count-tag">0</span>
            </a>
          </li>
        </ul>
        <ul data-submenu-title="My Accounts">
          <li>
            <a href="/bmyprofile">
              <i className="lni lni-user me-2" />
              My Profile{" "}
            </a>
          </li>
          <li>
            <a href="/bchangepassword">
              <i className="lni lni-lock-alt me-2" />
              Change Password
            </a>
          </li>
          <li>
            <a href="javascript:void(0);">
              <i className="lni lni-trash-can me-2" />
              Delete Account
            </a>
          </li>
          <li>
            <a onClick={logout} href="/">
              <i className="lni lni-power-switch me-2" />
              Log Out
            </a>
          </li>
        </ul>
      </div> : <div className="goodup-dashboard-inner">
        <ul data-submenu-title="Navegación Principal">
          <li className="active">
            <a href="/bdashboard">
              <i className="lni lni-dashboard me-2" />
              Panel
            </a>
          </li>
          <li>
            <a href="/bmylisting">
              <i className="lni lni-files me-2" />
              Mis listados
            </a>
          </li>
{id ? ("") : (<li>
            <a href="/baddlisting">
              <i className="lni lni-add-files me-2" />
              Agregar listado
            </a>
          </li>) }

          
          <li>
            <a href="/bsavedlisting">
              <i className="lni lni-bookmark me-2" />
              Listado guardado
            </a>
          </li>
          {/* <li>
            <a href="/bmybooking">
              <i className="lni lni-briefcase me-2" />
              My Bookings<span className="count-tag bg-warning">4</span>
            </a>
          </li> */}
          <li>
            <a href="/bwallet">
              <i className="lni lni-mastercard me-2" />
              Billetera
            </a>
          </li>
          <li>
            <a href="/bmessage">
              <i className="lni lni-envelope me-2" />
              Mensajes<span className="count-tag">0</span>
            </a>
          </li>
        </ul>
        <ul data-submenu-title="My Accounts">
          <li>
            <a href="/bmyprofile">
              <i className="lni lni-user me-2" />
              Mi perfil{" "}
            </a>
          </li>
          <li>
            <a href="/bchangepassword">
              <i className="lni lni-lock-alt me-2" />
              Cambiar la contraseña
            </a>
          </li>
          <li>
            <a href="javascript:void(0);">
              <i className="lni lni-trash-can me-2" />
              Borrar cuenta
            </a>
          </li>
          <li>
            <a onClick={logout} href="/">
              <i className="lni lni-power-switch me-2" />
              Cerrar sesión
            </a>
          </li>
        </ul>
      </div>}
    </div>
  </div>
  )
}

export default Mainnavigation
