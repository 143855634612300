import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import apiurl from "./config";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Toaster } from "react-hot-toast";
import Google from "./Google";
function Header() {
  const navigate = useNavigate();

  const [category, setcategory] = useState([]);

  const [email, setEemail] = useState("");
  const [password, setPaassword] = useState("");
  const [output, setOutput] = useState("");
  const [output1, setOutput1] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [emailforgot, setEemailforgot] = useState("");
  const [first_name, setFirstname] = useState("");

  const [last_name, setLastname] = useState("");
  const [user_email, setEmail] = useState("");
  const [user_mobile, setuser_mobile] = useState("");

  const [policyData, setPolicyData] = useState([]);

  const [zip, setZipcode] = useState("");
  const [user_password, setPassword] = useState("");
  const idd = secureLocalStorage.getItem("coquiid");
  let token = secureLocalStorage.getItem("token");

  // category :61
  // 7

  useEffect(() => {
    getDataterm();
  }, [0]);
  const getDataterm = () => {
    axios
      .get(`${apiurl[0].apiUrl}React/get_terms_condtion`)
      .then((res) => setPolicyData(res.data.data));
  };

  useEffect(() => {
    categoryget();
  }, []);

  const categoryget = () => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
    };

    // const data = JSON.stringify({
    //   category_id: "7",
    //   sub_category_id: "61",
    // });

    const data = JSON.stringify({
      category_id: "7",
    });

    axios
      .post(`${apiurl[0].apiUrl}React/sub_category`, data, options)
      .then((res) => {
        setcategory(res.data.data);
      })
      .catch((err) => {
        //console.error(err);
      });
  };

  const [category1, setcategory1] = useState([]);

  let userid = secureLocalStorage.getItem("coquiid");

  useEffect(() => {
    subcategoryget1();
  }, []);

  const subcategoryget1 = () => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
    };
    let categoryidd = secureLocalStorage.getItem("catergoryid");

    const data = JSON.stringify({
      category_id: "22",
    });

    axios
      .post(`${apiurl[0].apiUrl}React/sub_category`, data, options)
      .then((res) => {
        setcategory1(res.data.data);
      })
      .catch((err) => {
        //console.error(err);
      });
  };

  const [category2, setcategory2] = useState([]);

  useEffect(() => {
    subcategoryget2();
  }, []);

  const subcategoryget2 = () => {
    const options = {
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
    };
    let categoryidd = secureLocalStorage.getItem("catergoryid");

    const data = JSON.stringify({
      category_id: "15",
    });

    axios
      .post(`${apiurl[0].apiUrl}React/sub_category`, data, options)
      .then((res) => {
        setcategory2(res.data.data);
      })
      .catch((err) => {
        //console.error(err);
      });
  };

  const [category3, setCategory3] = useState([]);

  useEffect(() => {
    categoryget3();
  }, []);
  const categoryget3 = async () => {
    let lati = secureLocalStorage.getItem("latitude");
    let longi = secureLocalStorage.getItem("longitude");
    const data = JSON.stringify({
      lat: lati ? lati : "",
      long: longi ? longi : "",
    });
    try {
      const res = await axios.post(`${apiurl[0].apiUrl}React/category`, data);

      setCategory3(res.data.data);
    } catch (err) {}
  };

  let logout = async () => {
    const id = secureLocalStorage.getItem("coquiid");

    const data = {
      user_id: id,
    };

    axios
      .post(`${apiurl[0].apiUrl}React/logout_react`, data)
      .then((response) => {
        window.location.reload(navigate("/"));
        if (response.status === 200) {
          secureLocalStorage.removeItem("coquiid");
          secureLocalStorage.removeItem("token");
          secureLocalStorage.removeItem("volunteringid");
          secureLocalStorage.removeItem("educaltioncalidd");
          secureLocalStorage.removeItem("eventid");
          secureLocalStorage.removeItem("lessid");
          secureLocalStorage.removeItem("catergoryid");
          secureLocalStorage.removeItem("listingserachid");
          secureLocalStorage.removeItem("listingid");
          secureLocalStorage.removeItem("latitude");
          secureLocalStorage.removeItem("suncategoryidd");
          secureLocalStorage.removeItem("catergoryid");
          secureLocalStorage.removeItem("mylistingid");
          secureLocalStorage.removeItem("lnglathome");
          secureLocalStorage.removeItem("google_id");
          secureLocalStorage.removeItem("eventid");

          secureLocalStorage.removeItem("email");
          secureLocalStorage.removeItem("video_value");
          secureLocalStorage.removeItem("name");
          secureLocalStorage.removeItem("latitude");

          secureLocalStorage.removeItem("email");
          secureLocalStorage.removeItem("video_value");
          secureLocalStorage.removeItem("name");
          secureLocalStorage.removeItem("latitude");

          secureLocalStorage.removeItem("sabias");
          secureLocalStorage.removeItem("educationalid");
          secureLocalStorage.removeItem("vacationid");
          secureLocalStorage.removeItem("longitude");
          secureLocalStorage.removeItem("latitude");
          secureLocalStorage.removeItem("astro_fcm");
          secureLocalStorage.removeItem("_id");
          secureLocalStorage.removeItem("English");

        
        } else {
        }
      })
      .catch((error) => {});
  };

  let [vendorstatus, setvendorstatus] = useState();
  let [vendor_type_status, setvendor_type_status] = useState();

  useEffect(() => {
    try {
      let data = {
        vendor_id: idd,
      };
      axios
        .post(`${apiurl[0].apiUrl}React/get_vendor_profile_react`, data, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          setvendorstatus(res?.data?.data?.approve);
          setvendor_type_status(res?.data?.data?.type);
        })
        .catch((error) => {});
    } catch (error) {}
  }, [vendorstatus, idd, vendor_type_status]);

  const userId = secureLocalStorage.getItem("coquiid");
  const location = useLocation();
  const isProtectedPage = [
    "/singlelisting",
    "/businesssignup",
    "/eventdetails",
    "/dayspecialdetails",
    "/lugaresdetails",
    "/sabiasdetails",
    "/lessdetails",
    "/educationaldetails",
    "/volunteringdetails",
    "/jobdetails",
    "/dashboard",
    "/savedlisting",
    "/myprofile",

    "/changepassword",
    "/businesshome",
    "/bdashboard",
    "/bmylisting",
    "/bsavedlisting",
    "/bmyprofile",
    "/bchangepassword",
    "/purertodetails",

    "/eventsinprdetails",
    "/placetoeatdetails",
  ].includes(location.pathname);

  if (!userId && isProtectedPage) {
    return <Navigate to="/login" />;
  }

  const handleSubmitt = (evt) => {
    evt.preventDefault();
    if (!email) {
      setOutput("*Email is required");
      return;
    } else if (!password) {
      setOutput("*Password is required");
      return;
    }
    let fcm_id = secureLocalStorage.getItem("astro_fcm");

    const userDetails = {
      user_email: email,
      user_password: password,
      fcm_id: fcm_id ? fcm_id : "123456",
      lat: latitude ? latitude : "22.75555",
      long: longitude ? longitude : "75.44444",
    };

    axios
      .post(`${apiurl[0].apiUrl}React/login_react_new`, userDetails)
      .then((response) => {
        if (response.data.result) {
          const responseData = response.data.userDetails;
          secureLocalStorage.setItem("coquiid", response.data.data.id);

          secureLocalStorage.setItem("token", response.data.data.token);

          toast.success(response.data.msg);

          setTimeout(() => {
            window.location.reload(navigate("/dashboard"));
          }, 2000);
        } else {
          if (response.data.message === "User not found") {
            setOutput("Invalid user. Please register first.");
          } else {
            setOutput("Invalid user or verify your account.");
          }
          setEemail("");
          setPaassword("");
        }
      })
      .catch((err) => {
        //console.log(err);
        toast.error("Incorrect data provide by your side");
      });
  };

  const handleSubmitforgot = () => {
    if (!emailforgot) {
      setOutput1("Please enter your email address.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailforgot)) {
      setOutput1("Please enter a valid email address.");
      return;
    }

    let data = {
      user_email: emailforgot,
    };
    axios
      .post(`${apiurl[0].apiUrl}React/forget_password_react`, data)
      .then((response) => {
        if (response.data.token !== "error") {
          setOutput1(response.data.msg);
          toast.error(response.data.msg);

          // setTimeout(() => {
          //   window.location.reload(navigate("/"));
          // }, 3000);

          // setEemailforgot("");
        } else {
          setOutput1(response.data.message);
        }
      })
      .catch((error) => {});
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (!first_name) {
      setOutput("Please enter your First name.");
      return;
    }

    if (!last_name) {
      setOutput("Please enter your Last name.");
      return;
    }

    if (!zip) {
      setOutput("Please enter your zipcode.");
      return;
    }

    if (!user_email) {
      setOutput("Please enter your email address.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(user_email)) {
      setOutput("Please enter a valid email address.");
      return;
    }

    if (!user_password) {
      setOutput("Please enter a password.");
      return;
    }

    let userDetails = {
      first_name,
      last_name,
      user_email,
      user_mobile,
      user_password,

      zip,
      fcm_id: "12345",
    };

    try {
      const response = await axios.post(
        `${apiurl[0].apiUrl}React/user_signup_react`,
        userDetails
      );

      if (response.data.token !== "error") {
        // setOutput("User registered successfully.");
        toast.success(response.data.msg);

        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error(response.data.msg);
        setOutput(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.result === "false" &&
        error.response.data.message ===
          "user allready registered, please go to login page.."
      ) {
        setOutput(
          "The email is already registered. Please go to the login page."
        );
      } else {
      }
    }
  };


  let lng = secureLocalStorage.getItem("language")
 
  return (
    <div>
      <div className="header header-light dark-text">
        <Toaster />
        <div className="container">
          <nav id="navigation" className="navigation navigation-landscape">
            <div className="nav-header">
              <a className="nav-brand" href="/">
                <img src="assets/img/logoss.png" className="logo" alt="" />
              </a>
              <div className="nav-toggle" />
              <div className="mobile_nav">
                {idd == null ? (
                  <ul>
                    <li>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#login"
                        className="theme-cl fs-lg"
                      >
                        <i className="lni lni-user" />
                      </a>
                    </li>
                    <li>
                      {lng === "English" ? <a
                        href="/login"
                        className="crs_yuo12 w-auto text-white theme-bg"
                      >
                        <span className="embos_45">
                          <i className="fas fa-plus me-2" />
                          Log-in
                        </span>
                      </a> : <a
                        href="/login"
                        className="crs_yuo12 w-auto text-white theme-bg"
                      >
                        <span className="embos_45">
                          <i className="fas fa-plus me-2" />
                          Acceso
                        </span>
                      </a> }
                      
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <a href="/dashboard" className="theme-cl fs-lg">
                        <i className="lni lni-user" />
                      </a>
                    </li>
                    <li onClick={logout}>
                      {/* aaaaa */}
                      <a
                        href="#"
                        className="crs_yuo12 w-auto text-white theme-bg"
                      >
                        <span className="embos_45">
                          <i className="fas fa-plus me-2" />
                          LogOut
                        </span>
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>


            {lng === "English" ? <div
              className="nav-menus-wrapper"
              style={{ transitionProperty: "none" }}
            >
              <ul className="nav-menu">
                {/* <li>
                <a href="javascript:void(0);"> Restaurant</a>

                <ul className="nav-dropdown nav-submenu " style={{overflow:'scroll',height:'450px' , scrollbarWidth:'none'}}>
                  {category?.map((items) => {
                    return (
                      <li
                        onClick={() => {
                          secureLocalStorage.setItem(
                            "suncategoryidd",
                            items.id
                          );

                          secureLocalStorage.setItem("catergoryid", "7");
                        }}
                        key={items.id}
                        value={items.id}
                      >
                        <a href="/listingsearch">
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                          />
                          &nbsp;&nbsp;
                          {items?.sub_category_name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li>
                <a href="javascript:void(0);">Home Service</a>
                <ul className="nav-dropdown nav-submenu">
                  {category1?.map((items) => {
                    return (
                      <li
                        onClick={() => {
                          secureLocalStorage.setItem(
                            "suncategoryidd",
                            items.id
                          );
                          secureLocalStorage.setItem("catergoryid", "22");
                        }}
                        key={items.id}
                        value={items.id}
                      >
                        <a href="/listingsearch">
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                          />
                          &nbsp;&nbsp; {items?.sub_category_name.slice(0, 18)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li> */}
                {/* <li>
                <a href="javascript:void(0);">Auto Service</a>
                <ul className="nav-dropdown nav-submenu">
                  {category2?.map((items) => {
                    return (
                      <li
                        onClick={() => {
                          secureLocalStorage.setItem(
                            "suncategoryidd",
                            items.id
                          );
                          secureLocalStorage.setItem("catergoryid", "15");
                        }}
                        key={items.id}
                        value={items.id}
                      >
                        <a href="/listingsearch">
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                          />
                          &nbsp;&nbsp; {items?.sub_category_name.slice(0, 18)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li> */}
                {/* <li>
                <a href="javascript:void(0);">More</a>
                <ul className="nav-dropdown nav-submenu" style={{overflow:'scroll',height:'450px' , scrollbarWidth:'none'}}>
                  {category3?.map((items) => {
                    return (
                      <li
                        onClick={() => {
                          secureLocalStorage.setItem("catergoryid", items.id);
                        }}
                        key={items.id}
                        value={items.id}
                      >
                        <a href="/subcategory">
                          {" "}
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.category_image
                            }
                          />
                          &nbsp;&nbsp; {items?.category_name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li> */}

                {/* <li>
                <a href="javascript:void(0);">List Your Business</a>
                <ul className="nav-dropdown nav-submenu" style={{overflow:'scroll',height:'450px' , scrollbarWidth:'none'}}>
                 
                      <li
                        
                      >
                        <a href="/contact">
                      <i class="lni lni-add-files me-2"></i> Add a Business
                    </a>
                      </li>
                      <li>
                    <a href="/contact">
                      <i class="lni lni-dashboard me-2"></i>Claim your business
                    </a>
                  </li>

                      <li>
                    
                    {vendor_type_status === "vendor" ? (
                      vendorstatus == 1 ? (
                        <a href="/businesshome">
                          <i class="lni lni-user me-2"></i>Business Account
                        </a>
                      ) : (
                        <a href="/">
                          <i class="fas fa-user me-1 "></i>Business not approve
                        </a>
                      )
                    ) : (
                      <a href="/businesssignup">
                        <i class="lni lni-user me-2"></i>List Your Business
                      </a>
                    )}
                  </li>
                   
                </ul>
              </li> */}
              </ul>
              <ul className="nav-menu">
                <li>
                  <a href="javascript:void(0);">List Your Business</a>
                  <ul className="nav-dropdown nav-submenu">
                    <li>
                      <a href="/contact">
                        <i class="lni lni-add-files me-2"></i> Add a Business
                      </a>
                    </li>
                    {/* <li>
                      <a href="/contact">
                        <i class="lni lni-dashboard me-2"></i>Claim your
                        business
                      </a>
                    </li> */}



{idd == null ? (<li>
                      <a href="/login">
                        <i class="lni lni-dashboard me-2"></i>Log in to Business Account
                      </a>
                    </li>) : (null)}
                    
                    <li>
                      {vendor_type_status === "vendor" ? (
                        vendorstatus == 1 ? (
                          <a href="/bdashboard">
                            <i class="lni lni-user me-2"></i>Business Account
                          </a>
                         ) : (
                          <a href="/">
                            <i class="fas fa-user me-1 "></i> Business not
                            approve
                          </a>
                        )
                      ) : (
                        <a href="/businesssignup">
                          <i class="lni lni-user me-2"></i>List Your Business
                        </a>
                      )}
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="nav-menu">
              <li>
  <a href="javascript:void(0);">Language</a>
  <ul className="nav-dropdown nav-submenu">
    <li>
      <a onClick={(e) => {
        window.location.reload();
        secureLocalStorage.setItem("language", "English");
      }} href="#">
        <i class="lni lni-add-files me-2"></i> English
      </a>
    </li>
    <li>
      <a onClick={(e) => {
        window.location.reload();
        secureLocalStorage.setItem("language", "Spanish");
      }} href="#">
        <i class="lni lni-dashboard me-2"></i> Spanish
      </a>
    </li>
  </ul>
</li>

                {/* <li>
                  <a href="javascript:void(0);">Language</a>
                  <ul className="nav-dropdown nav-submenu">
                    <li>
                      <a onClick={(()=>{secureLocalStorage.setItem("English","English")})} href="#">
                        <i class="lni lni-add-files me-2"></i> English
                      </a>
                    </li>
                    <li>
                      <a onClick={(()=>{secureLocalStorage.setItem("English","Spanish")})} href="#">
                        <i class="lni lni-dashboard me-2"></i>Spanish
                      </a>
                    </li>
                  </ul>
                </li> */}
              </ul>
              {idd == null ? (null
                // <ul className="nav-menu nav-menu-social align-to-right">
                //   <li>
                //     <a
                //       href="#"
                //       data-bs-toggle="modal"
                //       data-bs-target="#login"
                //       className="ft-bold"
                //     >
                //       <i className="fas fa-sign-in-alt me-1 theme-cl" />
                //       Log In
                //     </a>
                //   </li>
                //   <li>
                //     <a
                //       href="#"
                //       data-bs-toggle="modal"
                //       data-bs-target="#signup"
                //       className="ft-bold"
                //     >
                //       <i className="fas fa-user-plus me-1 theme-cl" />
                //       Sign Up
                //     </a>
                //   </li>
                // </ul>
              ) : (
                <ul className="nav-menu nav-menu-social align-to-right">
                  <li>
                    <a href="/dashboard" className="ft-bold">
                      <i class="fas fa-user-plus me-1 theme-cl"></i>
                    </a>
                  </li>

                  <li onClick={logout}>
                    <a href="#" className="ft-bold">
                      {/* <i class="fas fa-user-plus me-1 theme-cl"></i> */}
                      <i className="fas fa-sign-in-alt me-1 theme-cl" />
                      Logout
                    </a>
                  </li>
                </ul>
              )}
            </div> : <div
              className="nav-menus-wrapper"
              style={{ transitionProperty: "none" }}
            >
              <ul className="nav-menu">
                {/* <li>
                <a href="javascript:void(0);"> Restaurant</a>

                <ul className="nav-dropdown nav-submenu " style={{overflow:'scroll',height:'450px' , scrollbarWidth:'none'}}>
                  {category?.map((items) => {
                    return (
                      <li
                        onClick={() => {
                          secureLocalStorage.setItem(
                            "suncategoryidd",
                            items.id
                          );

                          secureLocalStorage.setItem("catergoryid", "7");
                        }}
                        key={items.id}
                        value={items.id}
                      >
                        <a href="/listingsearch">
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                          />
                          &nbsp;&nbsp;
                          {items?.sub_category_name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li>
                <a href="javascript:void(0);">Home Service</a>
                <ul className="nav-dropdown nav-submenu">
                  {category1?.map((items) => {
                    return (
                      <li
                        onClick={() => {
                          secureLocalStorage.setItem(
                            "suncategoryidd",
                            items.id
                          );
                          secureLocalStorage.setItem("catergoryid", "22");
                        }}
                        key={items.id}
                        value={items.id}
                      >
                        <a href="/listingsearch">
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                          />
                          &nbsp;&nbsp; {items?.sub_category_name.slice(0, 18)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li> */}
                {/* <li>
                <a href="javascript:void(0);">Auto Service</a>
                <ul className="nav-dropdown nav-submenu">
                  {category2?.map((items) => {
                    return (
                      <li
                        onClick={() => {
                          secureLocalStorage.setItem(
                            "suncategoryidd",
                            items.id
                          );
                          secureLocalStorage.setItem("catergoryid", "15");
                        }}
                        key={items.id}
                        value={items.id}
                      >
                        <a href="/listingsearch">
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.sub_category_image
                            }
                          />
                          &nbsp;&nbsp; {items?.sub_category_name.slice(0, 18)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li> */}
                {/* <li>
                <a href="javascript:void(0);">More</a>
                <ul className="nav-dropdown nav-submenu" style={{overflow:'scroll',height:'450px' , scrollbarWidth:'none'}}>
                  {category3?.map((items) => {
                    return (
                      <li
                        onClick={() => {
                          secureLocalStorage.setItem("catergoryid", items.id);
                        }}
                        key={items.id}
                        value={items.id}
                      >
                        <a href="/subcategory">
                          {" "}
                          <img
                            style={{ height: "20px", width: "20px" }}
                            src={
                              `${apiurl[0].apiUrl}assets/bori_images/category/` +
                              items?.category_image
                            }
                          />
                          &nbsp;&nbsp; {items?.category_name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li> */}

                {/* <li>
                <a href="javascript:void(0);">List Your Business</a>
                <ul className="nav-dropdown nav-submenu" style={{overflow:'scroll',height:'450px' , scrollbarWidth:'none'}}>
                 
                      <li
                        
                      >
                        <a href="/contact">
                      <i class="lni lni-add-files me-2"></i> Add a Business
                    </a>
                      </li>
                      <li>
                    <a href="/contact">
                      <i class="lni lni-dashboard me-2"></i>Claim your business
                    </a>
                  </li>

                      <li>
                    
                    {vendor_type_status === "vendor" ? (
                      vendorstatus == 1 ? (
                        <a href="/businesshome">
                          <i class="lni lni-user me-2"></i>Business Account
                        </a>
                      ) : (
                        <a href="/">
                          <i class="fas fa-user me-1 "></i>Business not approve
                        </a>
                      )
                    ) : (
                      <a href="/businesssignup">
                        <i class="lni lni-user me-2"></i>List Your Business
                      </a>
                    )}
                  </li>
                   
                </ul>
              </li> */}
              </ul>
              <ul className="nav-menu">
                <li>
                  <a href="javascript:void(0);">Incluya su negocio</a>
                  <ul className="nav-dropdown nav-submenu">
                    <li>
                      <a href="/contact">
                        <i class="lni lni-add-files me-2"></i> Agregar una empresa
                      </a>
                    </li>
                    {/* <li>
                      <a href="/contact">
                        <i class="lni lni-dashboard me-2"></i>Claim your
                        business
                      </a>
                    </li> */}



{idd == null ? (<li>
                      <a href="/login">
                        <i class="lni lni-dashboard me-2"></i>Inicie sesión en la cuenta comercial
                      </a>
                    </li>) : (null)}
                    
                    <li>
                      {vendor_type_status === "vendor" ? (
                        vendorstatus == 1 ? (
                          <a href="/bdashboard">
                            <i class="lni lni-user me-2"></i>Cuenta de negocios
                          </a>
                         ) : (
                          <a href="/">
                            <i class="fas fa-user me-1 "></i>Negocio no aprobado
                          </a>
                        )
                      ) : (
                        <a href="/businesssignup">
                          <i class="lni lni-user me-2"></i>Incluya su negocio
                        </a>
                      )}
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="nav-menu">
              <li>
  <a href="javascript:void(0);">Idioma</a>
  <ul className="nav-dropdown nav-submenu">
    <li>
      <a onClick={(e) => {
        window.location.reload();
        secureLocalStorage.setItem("language", "English");
      }} href="#">
        <i class="lni lni-add-files me-2"></i> English
      </a>
    </li>
    <li>
      <a onClick={(e) => {
        window.location.reload();
        secureLocalStorage.setItem("language", "Spanish");
      }} href="#">
        <i class="lni lni-dashboard me-2"></i> Spanish
      </a>
    </li>
  </ul>
</li>

                {/* <li>
                  <a href="javascript:void(0);">Language</a>
                  <ul className="nav-dropdown nav-submenu">
                    <li>
                      <a onClick={(()=>{secureLocalStorage.setItem("English","English")})} href="#">
                        <i class="lni lni-add-files me-2"></i> English
                      </a>
                    </li>
                    <li>
                      <a onClick={(()=>{secureLocalStorage.setItem("English","Spanish")})} href="#">
                        <i class="lni lni-dashboard me-2"></i>Spanish
                      </a>
                    </li>
                  </ul>
                </li> */}
              </ul>
              {idd == null ? (null
                // <ul className="nav-menu nav-menu-social align-to-right">
                //   <li>
                //     <a
                //       href="#"
                //       data-bs-toggle="modal"
                //       data-bs-target="#login"
                //       className="ft-bold"
                //     >
                //       <i className="fas fa-sign-in-alt me-1 theme-cl" />
                //       Log In
                //     </a>
                //   </li>
                //   <li>
                //     <a
                //       href="#"
                //       data-bs-toggle="modal"
                //       data-bs-target="#signup"
                //       className="ft-bold"
                //     >
                //       <i className="fas fa-user-plus me-1 theme-cl" />
                //       Sign Up
                //     </a>
                //   </li>
                // </ul>
              ) : (
                <ul className="nav-menu nav-menu-social align-to-right">
                  <li>
                    <a href="/dashboard" className="ft-bold">
                      <i class="fas fa-user-plus me-1 theme-cl"></i>
                    </a>
                  </li>

                  <li onClick={logout}>
                    <a href="#" className="ft-bold">
                      {/* <i class="fas fa-user-plus me-1 theme-cl"></i> */}
                      <i className="fas fa-sign-in-alt me-1 theme-cl" />
                      Logout
                    </a>
                  </li>
                </ul>
              )}
            </div>}
          </nav>
        </div>
      </div>
      <div
        className="modal fade"
        id="login"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="loginmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="loginmodal">
            <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Login Your Account</h4>
              </div>
              {output && <font style={{ color: "red" }}>{output}</font>}
              <form onSubmit={handleSubmitt} className="submit-form">
                <div className="form-group">
                  <label className="mb-1">Email & Phone </label>
                  <input
                    required
                    type="text"
                    name="email"
                    placeholder="Email or Phone Number"
                    value={email}
                    onChange={(e) => setEemail(e.target.value)}
                    className="form-control rounded bg-light"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Password</label>
                  <input
                    required
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPaassword(e.target.value)}
                    className="form-control rounded bg-light"
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="flex-1">
                        <input
                          required
                          id="a1"
                          class="checkbox-custom"
                          name="open"
                          type="checkbox"
                        />
                        <label for="a1" class="checkbox-custom-label">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#term"
                            data-bs-dismiss="modal"
                            class="theme-cl"
                          >
                            Terms and Conditions
                          </a>
                        </label>
                      </div>
                    </div>{" "}
                    <div className="flex-1">
                      <input
                        id="dd"
                        className="checkbox-custom"
                        name="dd"
                        type="checkbox"
                        defaultChecked
                      />
                      <label
                        data-bs-toggle="modal"
                        data-bs-target="#forgot"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        htmlFor="dd"
                        className="checkbox-custom-label"
                      >
                        Forgot Password
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                  >
                    Log In
                  </button>
                </div>
                <div className="form-group text-center mb-0">
                  <p className="extra">Or login with</p>
                  <div className="option-log"></div>
                  <Google />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="forgot"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="forgotmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="forgotmodal">
            <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Forgot Your Password</h4>
              </div>
              {output1 && <font style={{ color: "red" }}>{output1}</font>}
              {/* <form onClick={handleSubmitforgot} className="submit-form"> */}
              <div className="form-group">
                <label className="mb-1">Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Addres"
                  value={emailforgot}
                  onChange={(e) => setEemailforgot(e.target.value)}
                  className="form-control rounded bg-light"
                />
              </div>

              <div className="form-group mt-3">
                <button
                  onClick={handleSubmitforgot}
                  type="submit"
                  className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                >
                  Submit
                </button>
              </div>

              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="signup"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="signupmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="signupmodal">
            <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Sign Up</h4>
              </div>
              {output && <font style={{ color: "red" }}>{output}</font>}
              <form onSubmit={handleSubmit} className="submit-form">
                <div className="form-group">
                  <label className="mb-1">First Name</label>
                  <input
                    required
                    value={first_name}
                    onChange={(e) => setFirstname(e.target.value)}
                    type="text"
                    className="form-control rounded bg-light"
                    placeholder="Firstname*"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Last Name</label>
                  <input
                    required
                    value={last_name}
                    onChange={(e) => setLastname(e.target.value)}
                    type="text"
                    className="form-control rounded bg-light"
                    placeholder="Lastname*"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Zip Code</label>
                  <input
                    required
                    value={zip}
                    onChange={(e) => setZipcode(e.target.value)}
                    type="number"
                    className="form-control rounded bg-light"
                    placeholder="Zip Code*"
                    maxLength={6}
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Email</label>
                  <input
                    required
                    value={user_email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control rounded bg-light"
                    placeholder="Email*"
                  />
                </div>

                <div className="form-group">
                  <label className="mb-1">Phone</label>
                  <input
                    required
                    value={user_mobile}
                    onChange={(e) => setuser_mobile(e.target.value)}
                    type="mobile"
                    maxLength={10}
                    minLength={10}
                    className="form-control rounded bg-light"
                    placeholder="Mobile*"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-1">Password</label>
                  <input
                    required
                    maxLength={"8"}
                    value={user_password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control rounded bg-light"
                    placeholder="Password*"
                  />
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                  >
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="term"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="termmodal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog login-pop-form"
          role="document"
          style={{ maxWidth: "80%" }}
        >
          <div className="modal-content" id="termmodal">
            <div className="modal-headers">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
             {lng === "English" ?  <section
                className="middle"
                style={{
                  overflow: "scroll",
                  height: "600px",
                  scrollbarWidth: "none",
                }}
              >
                <div className="container">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-xl-11 col-lg-12 col-md-6 col-sm-12">
                     
                      <div className="abt_caption">
                        <h2
                          className="ft-medium mb-4"
                          dangerouslySetInnerHTML={{
                            __html: policyData?.title,
                          }}
                        />

                        <p
                          className="mb-4"
                          dangerouslySetInnerHTML={{
                            __html: policyData?.description,
                          }}
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
              </section> :  <section
                className="middle"
                style={{
                  overflow: "scroll",
                  height: "600px",
                  scrollbarWidth: "none",
                }}
              >
                <div className="container">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-xl-11 col-lg-12 col-md-6 col-sm-12">
                     
                      <div className="abt_caption">
                        <h2
                          className="ft-medium mb-4"
                          dangerouslySetInnerHTML={{
                            __html: policyData?.sp_title,
                          }}
                        />

                        <p
                          className="mb-4"
                          dangerouslySetInnerHTML={{
                            __html: policyData?.sp_description,
                          }}
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
              </section>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
