import React from 'react'
import { Link } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage';
import apiurl from '../Component/config';
import { useState } from 'react';
import axios from 'axios';
import { Navigate, useLocation } from "react-router-dom";
import {   useNavigate } from "react-router-dom";
const Headerbusiness = () => {
  const Navigate = useNavigate();
  const idd = secureLocalStorage.getItem("coquiid");
  let token = secureLocalStorage.getItem("token");

  let logout = async () => {
    const id = secureLocalStorage.getItem("coquiid");

    const data = {
      user_id: id,
    };

    axios
      .post(`${apiurl[0].apiUrl}React/logout_react`, data)
      .then((response) => {
        window.location.reload(Navigate('/'))
        if (response.status === 200) {
          secureLocalStorage.removeItem("coquiid");
          secureLocalStorage.removeItem("token");
          secureLocalStorage.removeItem("volunteringid");
          secureLocalStorage.removeItem("educaltioncalidd");
          secureLocalStorage.removeItem("eventid");
          secureLocalStorage.removeItem("lessid");
          secureLocalStorage.removeItem("catergoryid");
          secureLocalStorage.removeItem("listingserachid");
          secureLocalStorage.removeItem("listingid");
          secureLocalStorage.removeItem("latitude");
          secureLocalStorage.removeItem("suncategoryidd");
          secureLocalStorage.removeItem("catergoryid");
          secureLocalStorage.removeItem("_id");

          
        } else {
        }
      })
      .catch((error) => {});
  };
  let lng = secureLocalStorage.getItem("language")
  return (
    <div className="header header-light dark-text">
            {lng === "English" ? <div className="container">
              <nav id="navigation" className="navigation navigation-landscape">
                <div className="nav-header">
                  <a  className="nav-brand" href="/bdashboard">
                    <img src="assets/img/logoss.png" className="logo" alt="" />
                  </a>
                  <div className="nav-toggle" />
                  <div className="mobile_nav">
                    <ul>
                      <li>
                       <a href="/bdashboard"  className="theme-cl fs-lg">
                          <i className="lni lni-user" />
                        </a> 
                         {/* <a href="#" data-bs-toggle="modal" data-bs-target="#login" className="theme-cl fs-lg">
                          <i className="lni lni-user" />
                        </a>  */}
                      </li>
                      <li onClick={logout}>
                        <a href="#" className="crs_yuo12 w-auto text-white theme-bg">
                          <span className="embos_45"><i className="fas fa-plus me-2" />LogOut</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="nav-menus-wrapper " style={{transitionProperty: 'none'}}>
                  <ul className="nav-menu">
                    {/* <li><a href="javascript:void(0);">Products</a>
                      <ul className="nav-dropdown nav-submenu">
                        <li><a href="/business">Business Page</a></li>
                        <li><a href="/upgrade">Upgarde Package</a></li>
                        <li><a href="/boricuaads">CoquiPages Ads</a></li>
                      </ul>
                    </li>
                    <li><a href="javascript:void(0);">Industries</a>
                      <ul className="nav-dropdown nav-submenu">
                        <li><a href="/homelocalservices">Home and Local Services</a>
                        </li>
                        <li><a href="/homelocalservices">Restaurant and Night Life</a>
                        </li>
                        <li><a href="/homelocalservices">National and Regional Brands</a>
                        </li>
                        <li><a href="/homelocalservices">Marketing &amp; Advertising Agencies</a>
                        </li>
                      </ul>
                    </li>
                    <li><a href="javascript:void(0);">Support</a>
                    </li> */}

                     <li ><a href="/" style={{lineHeight:'2px' ,color:'#fff',borderRadius: '4px',
    top: '10px',
    position: 'relative',
    height: '45px',
    
    marginLeft: '10px',
    background: '#F41b3b'}}>User-Home</a>
                    </li> 
                    {/* <li><a href="javascript:void(0);">Resources</a>
                      <ul className="nav-dropdown nav-submenu">
                        <li><a href="/smallbusniess">Small Business Guide</a></li>
                        <li><a href="/resource">Resource Center</a></li>
                        <li><a href="/event">Events</a></li>
                        <li><a href="/event">Podcasts</a></li>
                        <li><a href="/bblog">Blog</a></li>
                      </ul>
                    </li> */}
                  </ul>
                  <ul className="nav-menu nav-menu-social align-to-right">
                     <li className="add-listing">
                      <a href="/bdashboard" className="ft-bold">
                      <i style={{background:'white'}} class="fas fa-user-plus me-1 theme-cl"></i>
                      </a>
                    </li> 

{/* <li>
                      <a href="/" className="ft-bold">
                        <i className="fas fa-sign-in-alt me-1 theme-cl" />LogOut
                      </a>
                    </li> */}
                    {/* <li className="add-listing">
                      <a href="/">
                        Back to User Home
                      </a>
                    </li> */}
                  </ul>
                </div>
              </nav>
            </div> : <div className="container">
              <nav id="navigation" className="navigation navigation-landscape">
                <div className="nav-header">
                  <a  className="nav-brand" href="/bdashboard">
                    <img src="assets/img/logoss.png" className="logo" alt="" />
                  </a>
                  <div className="nav-toggle" />
                  <div className="mobile_nav">
                    <ul>
                      <li>
                       <a href="/bdashboard"  className="theme-cl fs-lg">
                          <i className="lni lni-user" />
                        </a> 
                         {/* <a href="#" data-bs-toggle="modal" data-bs-target="#login" className="theme-cl fs-lg">
                          <i className="lni lni-user" />
                        </a>  */}
                      </li>
                      <li onClick={logout}>
                        <a href="#" className="crs_yuo12 w-auto text-white theme-bg">
                          <span className="embos_45"><i className="fas fa-plus me-2" />Cerrar sesión</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="nav-menus-wrapper " style={{transitionProperty: 'none'}}>
                  <ul className="nav-menu">
                    {/* <li><a href="javascript:void(0);">Products</a>
                      <ul className="nav-dropdown nav-submenu">
                        <li><a href="/business">Business Page</a></li>
                        <li><a href="/upgrade">Upgarde Package</a></li>
                        <li><a href="/boricuaads">CoquiPages Ads</a></li>
                      </ul>
                    </li>
                    <li><a href="javascript:void(0);">Industries</a>
                      <ul className="nav-dropdown nav-submenu">
                        <li><a href="/homelocalservices">Home and Local Services</a>
                        </li>
                        <li><a href="/homelocalservices">Restaurant and Night Life</a>
                        </li>
                        <li><a href="/homelocalservices">National and Regional Brands</a>
                        </li>
                        <li><a href="/homelocalservices">Marketing &amp; Advertising Agencies</a>
                        </li>
                      </ul>
                    </li>
                    <li><a href="javascript:void(0);">Support</a>
                    </li> */}

                     <li ><a href="/" style={{lineHeight:'2px' ,color:'#fff',borderRadius: '4px',
    top: '10px',
    position: 'relative',
    height: '45px',
    
    marginLeft: '10px',
    background: '#F41b3b'}}>Usuario-Inicio</a>
                    </li> 
                    {/* <li><a href="javascript:void(0);">Resources</a>
                      <ul className="nav-dropdown nav-submenu">
                        <li><a href="/smallbusniess">Small Business Guide</a></li>
                        <li><a href="/resource">Resource Center</a></li>
                        <li><a href="/event">Events</a></li>
                        <li><a href="/event">Podcasts</a></li>
                        <li><a href="/bblog">Blog</a></li>
                      </ul>
                    </li> */}
                  </ul>
                  <ul className="nav-menu nav-menu-social align-to-right">
                     <li className="add-listing">
                      <a href="/bdashboard" className="ft-bold">
                      <i style={{background:'white'}} class="fas fa-user-plus me-1 theme-cl"></i>
                      </a>
                    </li> 

{/* <li>
                      <a href="/" className="ft-bold">
                        <i className="fas fa-sign-in-alt me-1 theme-cl" />LogOut
                      </a>
                    </li> */}
                    {/* <li className="add-listing">
                      <a href="/">
                        Back to User Home
                      </a>
                    </li> */}
                  </ul>
                </div>
              </nav>
            </div>}
          </div>
  )
}

export default Headerbusiness
