import axios from 'axios';
import React, { useEffect, useState } from 'react'
import apiurl from '../Component/config';

const Bfooter = () => {
  const [helpdata, sethelpdata] = useState([]);

  useEffect(() => {
    gethelp();
  }, []);
  const gethelp = () => {
    axios
      .get(`${apiurl[0].apiUrl}React/get_help_center`)
      .then((res) => sethelpdata(res.data.data));
  };
  return (
    <div>
        {/* <footer className="light-footer skin-light-footer style-2">
            <div className="footer-middle">
              <div className="container">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="footer_widget">
                      <img src="assets/img/logoss.png" className="img-footer small mb-2" alt="" />
                    
                      <div className="address mt-3">
                      {helpdata?.contact}
                    <br />
                    {helpdata?.email}
                      </div>
                      <div className="address mt-2">
                      <ul className="list-inline">
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://www.facebook.com/groups/859160575966776/?ref=share_group_link&exp=7ffb"
                          className="theme-cl"
                        >
                          <i className="lni lni-facebook-filled" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://www.instagram.com/p/C4gp_MnxjsR/?igsh=MTFvMWwwcGxpbXBkdw=="
                          className="theme-cl"
                        >
                          <i className="lni lni-instagram-filled" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          href="https://youtube.com/@Coquipages?si=QHq7tNsk1vbKtuaH"
                          className="theme-cl"
                        >
                          <i className="lni lni-youtube" />
                        </a>
                      </li>

                      <li className="list-inline-item">
                        <a
                          target="_black"
                          href="https://www.tiktok.com/@coquipages?_t=8kgQwFlFZ6J&_r=1"
                          className="theme-cl"
                        >
                          <i class="fab fa-tiktok"></i>
                        </a>
                      </li>
                    </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                    <div className="footer_widget">
                      <h4 className="widget_title">About</h4>
                      <ul className="footer-menu">
                        <li><a href="/babout">About CoquiPages </a></li>
                        
                        <li><a href="/Privacy">Terms of Service</a></li>
                        <li><a href="/Privacy">Privacy Policy</a></li>
                        
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                    <div className="footer_widget">
                      <h4 className="widget_title">Discover</h4>
                      <ul className="footer-menu">
                        <li><a href="#">CoquiPages Project Cost Guides</a></li>
                        <li><a href="#">Collections</a></li>
                        <li><a href="#">Talk</a></li>
                        <li><a href="#">Events</a></li>
                        <li><a href="/bbloghome">CoquiPages Blog</a></li>
                        <li><a href="#">Support</a></li>
                        <li><a href="#">CoquiPages Mobile</a></li>
                        <li><a href="#">Developers</a></li>
                        <li><a href="#">RSS</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                    <div className="footer_widget">
                      <h4 className="widget_title">CoquiPages for Business</h4>
                      <ul className="footer-menu">
                        <li><a href="#">CoquiPages for Business</a></li>
                        <li><a href="#">Business Owner Login</a></li>
                        <li><a href="#">Claim your Business Page</a></li>
                        <li><a href="#">Advertise on CoquiPages</a></li>
                        <li><a href="#">CoquiPages for Restaurant Owners</a></li>
                        <li><a href="#">Table Management</a></li>
                        <li><a href="#">Business Success Stories</a></li>
                        <li><a href="#">Business Support</a></li>
                        <li><a href="/bbloghome">CoquiPages Blog for Business</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                    <div className="footer_widget">
                      <h4 className="widget_title">Languages</h4>
                      <select className="footer-menu">
                        <option>English</option>
                        <option>Spanish</option>
                      </select>
                      
                    </div>
                  </div>

                  
                </div>
              </div>
            </div>
            <div className="footer-bottom br-top">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12 text-center">
                    <p className="mb-0">© 2024 CoquiPages</p>
                  </div>
                </div>
              </div>
            </div>
          </footer> */}
    </div>
  )
}

export default Bfooter
