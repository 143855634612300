import React, { useEffect, useState } from "react";

import Headerbusiness from "./Headerbusiness";
import Mainnavigation from "./Mainnavigation";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import apiurl from "../Component/config";
import Dashboardimage from "../Component/Dashboardimage";

const Bmessage = () => {
  const [notification , setnotification] = useState([]);

  // console.log("notification",notification)
  const [messagee, setMessagee] = useState("");
  const [getchantadmin, setGetchantadmin] = useState([]);

  const chatadmin = (e) => {
e.preventDefault();
    let idd = secureLocalStorage.getItem("coquiid");
    let tokenn = secureLocalStorage.getItem("token");
    const data = {
      user_id: idd,
      boricua_id: "1",
      message: messagee,
    };
    axios
      .post(`${apiurl[0].apiUrl}React/chat_admin_user_react`, data, {
        headers: {
          Authorization: `${tokenn}`,
        },
      })
      .then((res) => {
        getadmingchat();
        setMessagee(" ");
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getadmingchat();
  }, [0]);

  const getadmingchat = () => {
    let idd = secureLocalStorage.getItem("coquiid");
    let tokenn = secureLocalStorage.getItem("token");
    const data = {
      user_id: idd,
    };
    axios
      .post(`${apiurl[0].apiUrl}React/get_chat_admin_react`, data, {
        headers: {
          Authorization: `${tokenn}`,
        },
      })
      .then((res) => {
        setGetchantadmin(res.data.data);
        

      })
      .catch((error) => {});
  };



  useEffect(()=>{
    getnotification();
  },[0])

  const getnotification=()=>{
    let iddd = secureLocalStorage.getItem("coquiid")
    let tokenn = secureLocalStorage.getItem("token")
    const data={
      vendor_id:iddd
    };
    axios.post(`${apiurl[0].apiUrl}React/notification_vendorside`,data,{
      headers : {
        Authorization : `${tokenn}`
      }
    }).then((res)=>{

      setnotification(res.data.data)
     
    }).catch((error)=>{

    })
  }
let lng = secureLocalStorage.getItem("language")

  return (
    <div>
      {/* ============================================================== */}
      {/* Preloader - style you can find in spinners.css */}
      {/* ============================================================== */}
      <div className="preloader" />
      {/* ============================================================== */}
      {/* Main wrapper - style you can find in pages.scss */}
      {/* ============================================================== */}
      <div id="main-wrapper">
        {/* ============================================================== */}
        {/* Top header  */}
        {/* ============================================================== */}
        {/* Start Navigation */}
        <Headerbusiness />
        {/* End Navigation */}
        <div className="clearfix" />
        {/* ============================================================== */}
        {/* Top header  */}
        {/* ============================================================== */}
        {/* =============================== Dashboard Header ========================== */}
        <Dashboardimage />

        {/* =============================== Dashboard Header ========================== */}
        {/* ======================= dashboard Detail ======================== */}
        <div className="goodup-dashboard-wrap gray px-4 py-5">
          <a
            className="mobNavigation"
            data-bs-toggle="collapse"
            href="#MobNav"
            role="button"
            aria-expanded="false"
            aria-controls="MobNav"
          >
            <i className="fas fa-bars me-2" />
            Dashboard Navigation
          </a>
          <Mainnavigation />
          {lng === "English" ? <div className="goodup-dashboard-content">
            <div className="dashboard-tlbar d-block mb-5">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <h1 className="ft-medium">Messages</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li>
                        <a href="/bdashboard">Home /</a>
                      </li>
                      <li>
                        <a href="/bdashboard">Dashboard /</a>
                      </li>
                      <li>
                        <a href="#" className="theme-cl">
                          Messages
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="dashboard-widg-bar d-block">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="_dashboard_content bg-white rounded mb-4">
                    <div className="_dashboard_content_body">
                      {/* Convershion */}
                      <div className="messages-container margin-top-0">
                         <div className="messages-headline">
                            <h4>Notification</h4>
                            {/* <a href="#" className="message-action"><i className="ti-trash" /> Delete Conversation</a> */}
                          </div> 
                        <div className="messages-container-inner">
                          {/* Messages */}
                           <div className="dash-msg-inbox">
                              <ul>
                                {notification?.map((list)=>{
                                  return(
                                    <li>
                                    <a href="#">
                                      <div className="dash-msg-avatar"><img src="assets/img/faviconn.png" alt="" /><span className="_user_status online" /></div>
                                      <div className="message-by">
                                        <div className="message-by-headline">
                                          <h5>{list?.title}</h5>
                                          <span>{list?.time} {list?.date}</span>
                                        </div>
                                        <p>{list?.body}</p>
                                      </div>
                                    </a>
                                  </li>
                                  )
                                })}
                               
                               
                              </ul>
                            </div> 
                          {/* Messages / End */}
                          {/* Message Content */}

                          <div className="dash-msg-content" style={{height:'450px', overflow:'scroll',scrollbarWidth:'none'}}>
                            {getchantadmin?.map((msg) => {
                              return (
<div >
{msg?.sender == "User" ? ( <div className="message-plunch">

     {msg?.user_image ? (<div className="dash-msg-avatar">
                                    <img src={`${apiurl[0].apiUrl}/assets/bori_images/users/`+msg?.user_image} alt="" />
                                  </div>) : (
                                    <div className="dash-msg-avatar">
                                    <img src='assets/img/faviconn.png' alt="" />
                                  </div>
                                  )}
                                  
                                  <div className="dash-msg-text">
                                    <p>{msg?.message}</p>
                                  </div>
                                </div>) : ( <div className="message-plunch me">

                                {msg?.admin_image ? (<div className="dash-msg-avatar">
                                <img src={`${apiurl[0].apiUrl}assets/bori_images/figure/`+msg?.admin_image} alt="" />
                              </div>) : (<div className="dash-msg-avatar">
                                <img src='assets/img/faviconn.png' alt="" />
                              </div>)}  
                              
                              <div className="dash-msg-text">
                                <p>
                                {msg?.message}
                                </p>
                              </div>
                            </div>)}

</div>
                               
                              );
                            })}

                            {/* <div className="message-plunch me">
                              <div className="dash-msg-avatar">
                                <img src="assets/img/user-8.jpg" alt="" />
                              </div>
                              <div className="dash-msg-text">
                                <p>
                                  looked up one of the more obscure Latin words,
                                  consectetur, from a Lorem
                                </p>
                              </div>
                            </div> */}
                            {/* <div className="message-plunch">
                              <div className="dash-msg-avatar">
                                <img src="assets/img/user-1.jpg" alt="" />
                              </div>
                              <div className="dash-msg-text">
                                <p>
                                  If you are going to use a passage of Lorem
                                  Ipsum, you need to be sure there isn't
                                  anything embarrassing
                                </p>
                              </div>
                            </div>
                            <div className="message-plunch me">
                              <div className="dash-msg-avatar">
                                <img src="assets/img/user-8.jpg" alt="" />
                              </div>
                              <div className="dash-msg-text">
                                <p>
                                  please consider donating a small sum to help
                                  pay for the hosting and bandwidth bill.
                                </p>
                              </div>
                            </div>
                            <div className="message-plunch">
                              <div className="dash-msg-avatar">
                                <img src="assets/img/user-1.jpg" alt="" />
                              </div>
                              <div className="dash-msg-text">
                                <p>
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum dolore
                                </p>
                              </div>
                            </div>
                            <div className="message-plunch me">
                              <div className="dash-msg-avatar">
                                <img src="assets/img/user-8.jpg" alt="" />
                              </div>
                              <div className="dash-msg-text">
                                <p>
                                  numquam eius modi tempora incidunt ut labore
                                  et dolore magnam aliquam quaerat voluptatem.
                                </p>
                              </div>
                            </div>
                            <div className="message-plunch">
                              <div className="dash-msg-avatar">
                                <img src="assets/img/user-1.jpg" alt="" />
                              </div>
                              <div className="dash-msg-text">
                                <p>
                                  But I must explain to you how all this
                                  mistaken idea of denouncing pleasure
                                </p>
                              </div>
                            </div> */}
                            {/* Reply Area */}
                            <div className="clearfix" />
                            <div className="message-reply">
                              <form onSubmit={chatadmin}>
                              <textarea value={messagee}
                                onChange={(e) => {
                                  setMessagee(e.target.value);
                                }}
                                cols={40}
                                rows={3}
                                className="form-control with-light"
                                placeholder="Your Message"
                                
                                required
                              />
                              <button
                                
                                type="submit"
                                className="btn theme-bg text-white"
                              >
                                Send Message
                              </button>
                              </form>
                            </div>
                          </div>
                          {/* Message Content */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* footer */}
            {/* 	<div class="row">
						<div class="col-md-12">
							<div class="py-3">© 2022 Goodup. Designd By ThemezHub.</div>
						</div>
					</div> */}
          </div> : <div className="goodup-dashboard-content">
            <div className="dashboard-tlbar d-block mb-5">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <h1 className="ft-medium">Mensajes</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li>
                        <a href="/bdashboard">Hogar /</a>
                      </li>
                      <li>
                        <a href="/bdashboard">Panel /</a>
                      </li>
                      <li>
                        <a href="#" className="theme-cl">
                        Mensajes
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="dashboard-widg-bar d-block">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="_dashboard_content bg-white rounded mb-4">
                    <div className="_dashboard_content_body">
                      {/* Convershion */}
                      <div className="messages-container margin-top-0">
                         <div className="messages-headline">
                            <h4>Notificación</h4>
                            {/* <a href="#" className="message-action"><i className="ti-trash" /> Delete Conversation</a> */}
                          </div> 
                        <div className="messages-container-inner">
                          {/* Messages */}
                           <div className="dash-msg-inbox">
                              <ul>
                                {notification?.map((list)=>{
                                  return(
                                    <li>
                                    <a href="#">
                                      <div className="dash-msg-avatar"><img src="assets/img/faviconn.png" alt="" /><span className="_user_status online" /></div>
                                      <div className="message-by">
                                        <div className="message-by-headline">
                                          <h5>{list?.title}</h5>
                                          <span>{list?.time} {list?.date}</span>
                                        </div>
                                        <p>{list?.body}</p>
                                      </div>
                                    </a>
                                  </li>
                                  )
                                })}
                               
                               
                              </ul>
                            </div> 
                          {/* Messages / End */}
                          {/* Message Content */}

                          <div className="dash-msg-content" style={{height:'450px', overflow:'scroll',scrollbarWidth:'none'}}>
                            {getchantadmin?.map((msg) => {
                              return (
<div >
{msg?.sender == "User" ? ( <div className="message-plunch">

     {msg?.user_image ? (<div className="dash-msg-avatar">
                                    <img src={`${apiurl[0].apiUrl}/assets/bori_images/users/`+msg?.user_image} alt="" />
                                  </div>) : (
                                    <div className="dash-msg-avatar">
                                    <img src='assets/img/faviconn.png' alt="" />
                                  </div>
                                  )}
                                  
                                  <div className="dash-msg-text">
                                    <p>{msg?.message}</p>
                                  </div>
                                </div>) : ( <div className="message-plunch me">

                                {msg?.admin_image ? (<div className="dash-msg-avatar">
                                <img src={`${apiurl[0].apiUrl}assets/bori_images/figure/`+msg?.admin_image} alt="" />
                              </div>) : (<div className="dash-msg-avatar">
                                <img src='assets/img/faviconn.png' alt="" />
                              </div>)}  
                              
                              <div className="dash-msg-text">
                                <p>
                                {msg?.message}
                                </p>
                              </div>
                            </div>)}

</div>
                               
                              );
                            })}

                           
                            <div className="clearfix" />
                            <div className="message-reply">
                              <form onSubmit={chatadmin}>
                              <textarea value={messagee}
                                onChange={(e) => {
                                  setMessagee(e.target.value);
                                }}
                                cols={40}
                                rows={3}
                                className="form-control with-light"
                                placeholder="Your Message"
                                
                                required
                              />
                              <button
                                
                                type="submit"
                                className="btn theme-bg text-white"
                              >
                                Enviar mensaje

                              </button>
                              </form>
                            </div>
                          </div>
                          {/* Message Content */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* footer */}
            {/* 	<div class="row">
						<div class="col-md-12">
							<div class="py-3">© 2022 Goodup. Designd By ThemezHub.</div>
						</div>
					</div> */}
          </div>}
        </div>
        {/* ======================= dashboard Detail End ======================== */}
        <a id="tops-button" className="top-scroll" title="Back to top" href="#">
          <i className="ti-arrow-up" />
        </a>
      </div>
      {/* ============================================================== */}
      {/* End Wrapper */}
      {/* ============================================================== */}
      {/* ============================================================== */}
      {/* All Jquery */}
      {/* ============================================================== */}
      {/* ============================================================== */}
      {/* This page plugins */}
      {/* ============================================================== */}
    </div>
  );
};

export default Bmessage;
