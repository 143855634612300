import React, { useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from './Footer'
import Header from './Header'
import { useState } from 'react'
import apiurl from './config'
import axios from 'axios'
import Google from './Google';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { getToken } from 'firebase/messaging';
import { messaging } from './Firebase';




const Login = () => {

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [email, setEemail] = useState("");
  const [password, setPaassword] = useState("");
  const  navigate =useNavigate('') 
  const [output, setOutput] = useState("");
  const handleSubmitt = (evt) => {
    evt.preventDefault();

    
  //   if (!('Notification' in window)) {
      
  //     return;
  // }
  // if (Notification.permission === 'granted') {
      
  //     let fcmtoken = await getToken(messaging, { vapidKey: 'BK0anho2f3mYHQxFitww4g7zgkwMo_8P53ptrbci04eh_TjnbLR99iw11SE5Bg3ao6iHoPdzeuK9lmllx0EBoSE' })
     
  //     secureLocalStorage.setItem("astro_fcm", fcmtoken)
      
  // } else if (Notification.permission === 'denied') {
        
  //     const confirmed = window.confirm(
  //         'You have denied notification permission. Do you want to enable it manually in your browser settings?'
  //     );
  //     if (confirmed) {
          
  //     }
  // } else {
  //     try {
  //         const permissionResult = await Notification.requestPermission();
  //         if (permissionResult === 'granted') {
  //         } else if (permissionResult === 'denied') {
             
  //         } else {
             
  //         }
  //     } catch (error) {
  //         console.error('Error requesting notification permission:', error);
  //     }
  // }



    if (!email) {
      toast.error("*Email is required");
      return;
    } else if (!password) {
      toast.error("*Password is required");
      return;
    }
    let fcm_id = secureLocalStorage.getItem("astro_fcm")
    const userDetails = {
      user_email: email,
      user_password: password,
      fcm_id: fcm_id ? fcm_id : "123456",
      lat: latitude ? latitude : "22.75555",
      long: longitude ? longitude : "75.44444",
    };

    axios
      .post(`${apiurl[0].apiUrl}React/login_react_new`, userDetails)
      .then((response) => {
        if (response.data.result) {
          const responseData = response.data.userDetails;
          secureLocalStorage.setItem("coquiid", response.data.data.id);

          secureLocalStorage.setItem("token", response.data.data.token);

          toast.success(response.data.msg);

          setTimeout(() => {
            
             window.location.reload(navigate("/dashboard"));
          }, 2000);
        } else {
          if (response.data.message === "User not found") {
            toast.error("Invalid user. Please register first.");
          } else {
            toast.error("Invalid user or verify your account.");
          }
          // setEemail("");
          // setPaassword("");
        }
      })
      .catch((err) => {
        //console.log(err);
        toast.error("Incorrect data provide by your side");
      });
  };

  const [policyData, setPolicyData] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axios
      .get(`${apiurl[0].apiUrl}React/get_terms_condtion`)
      .then((res) => setPolicyData(res.data.data));
  };
let lng = secureLocalStorage.getItem("language")
  return (
    <div>
      {/* <div className="preloader" /> */}
       <ToastContainer />
    
    <div id="main-wrapper">
     
      <Header/>
      {/* End Navigation */}
      <div className="clearfix" />
      
     {lng === "English" ?  <section className="gray">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-12">
              <div className="signup-screen-wrap">

                
                <div className="signup-screen-single">
                  <div className="text-center mb-4">
                    <h4 className="m-0 ft-medium">Login Your Account</h4>
                  </div>


                  {output && <font style={{ color: "red" }}>{output}</font>}
                <form onSubmit={handleSubmitt} className="submit-form">
                  <div className="form-group">
                    <label className="mb-1">Email & Phone</label>
                    <input required
                      type="text"
                      name="email"
                      placeholder="Email or Phone Number "
                      value={email}
                      onChange={(e) => setEemail(e.target.value)}
                      className="form-control rounded bg-light"
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1">Password</label>
                    <input required
                      type="password"
                      
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPaassword(e.target.value)}
                      className="form-control rounded bg-light"
                    />
                  </div>
                  <div class="form-group">
											<div class="d-flex align-items-center justify-content-between">
												<div class="flex-1">
                        
													<input required id="a2" class="checkbox-custom" name="open" type="checkbox" />
													<label for="a2" class="checkbox-custom-label"><a href="#" data-bs-toggle="modal"
                          data-bs-target="#term"
                          data-bs-dismiss="modal" class="theme-cl">Terms and Conditions</a></label>
												</div>	
                        <div className="single-log-opt"><p className="mb-0">Don't Have An account? <a href="/signuptobussiness" className="ft-medium text-success">Sign Up</a></p></div>

											</div>
										</div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                    >
                      Sign In
                    </button>
                  </div>
                  {/* <div className="form-group text-center mb-0">
                    <p className="extra">Or login with</p>
                    <div className="option-log">
                      
                    </div>
                    <Google />
                  </div> */}
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> :  <section className="gray">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-12">
              <div className="signup-screen-wrap">

                
                <div className="signup-screen-single">
                  <div className="text-center mb-4">
                    <h4 className="m-0 ft-medium">Inicie sesión en su cuenta</h4>
                  </div>


                  {output && <font style={{ color: "red" }}>{output}</font>}
                <form onSubmit={handleSubmitt} className="submit-form">
                  <div className="form-group">
                    <label className="mb-1">Correo electrónico y teléfono</label>
                    <input required
                      type="text"
                      name="email"
                      placeholder="Correo electrónico o número de teléfono"
                      value={email}
                      onChange={(e) => setEemail(e.target.value)}
                      className="form-control rounded bg-light"
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1">Contraseña</label>
                    <input required
                      type="password"
                      
                      name="password"
                      placeholder="Contraseña"
                      value={password}
                      onChange={(e) => setPaassword(e.target.value)}
                      className="form-control rounded bg-light"
                    />
                  </div>
                  <div class="form-group">
											<div class="d-flex align-items-center justify-content-between">
												<div class="flex-1">
                        
													<input required id="a2" class="checkbox-custom" name="open" type="checkbox" />
													<label for="a2" class="checkbox-custom-label"><a href="#" data-bs-toggle="modal"
                          data-bs-target="#term"
                          data-bs-dismiss="modal" class="theme-cl">Términos y condiciones</a></label>
												</div>	
                        <div className="single-log-opt"><p className="mb-0">¿No tienes una cuenta? <a href="/signuptobussiness" className="ft-medium text-success">Inscribirse</a></p></div>

											</div>
										</div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-md full-width theme-bg text-light rounded ft-medium"
                    >
                      Iniciar sesión
                    </button>
                  </div>
                  {/* <div className="form-group text-center mb-0">
                    <p className="extra">Or login with</p>
                    <div className="option-log">
                      
                    </div>
                    <Google />
                  </div> */}
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}
     
      <Footer/>
      {/* ============================ Footer End ================================== */}
      {/* Log In Modal */}
      <div className="modal fade" id="login" tabIndex={-1} role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="loginmodal">
            <div className="modal-headers">
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Login Your Account</h4>
              </div>
              <form className="submit-form">				
                <div className="form-group">
                  <label className="mb-1">Phone</label>
                  <input type="text" className="form-control rounded bg-light" placeholder="Phone*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Password</label>
                  <input type="password" className="form-control rounded bg-light" placeholder="Password*" />
                </div>
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-1">
                      <input id="dd" className="checkbox-custom" name="dd" type="checkbox" defaultChecked />
                      <label htmlFor="dd" className="checkbox-custom-label">Remember Me</label>
                    </div>	
                    <div className="eltio_k2">
                      <a href="#" className="theme-cl">Lost Your Password?</a>
                    </div>	
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-md full-width theme-bg text-light rounded ft-medium">Log In</button>
                </div>
                <div className="form-group text-center mb-0">
                  <p className="extra">Or login with</p>
                  
                   <div className="option-log">
                    <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/c-1.png" className="img-fluid" alt="" />Login with Google</a></div>
                    <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/facebook.png" className="img-fluid" alt="" />Login with Facebook</a></div>
                  </div> 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
      {/* Sign up Modal */}
      <div className="modal fade" id="signup" tabIndex={-1} role="dialog" aria-labelledby="signupmodal" aria-hidden="true">
        <div className="modal-dialog login-pop-form" role="document">
          <div className="modal-content" id="signupmodal">
            <div className="modal-headers">
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span className="ti-close" />
              </button>
            </div>
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h4 className="m-0 ft-medium">Sign Up</h4>
              </div>
              <form className="submit-form">				
                <div className="form-group">
                  <label className="mb-1">First Name</label>
                  <input type="text" className="form-control rounded bg-light" placeholder="Firstname*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Last Name</label>
                  <input type="text" className="form-control rounded bg-light" placeholder="Lastname*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Phone</label>
                  <input type="text" className="form-control rounded bg-light" placeholder="Phone*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Email</label>
                  <input type="email" className="form-control rounded bg-light" placeholder="Email*" />
                </div>
                <div className="form-group">
                  <label className="mb-1">Password</label>
                  <input type="password" className="form-control rounded bg-light" placeholder="Password*" />
                </div>
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-1">
                      <input id="dd" className="checkbox-custom" name="dd" type="checkbox" defaultChecked />
                      <label htmlFor="dd" className="checkbox-custom-label">Remember Me</label>
                    </div>	
                    <div className="eltio_k2">
                      <a href="#" className="theme-cl">Lost Your Password?</a>
                    </div>	
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-md full-width theme-bg text-light rounded ft-medium">Sign Up</button>
                </div>
                <div className="form-group text-center mb-0">
                  <p className="extra">Or login with</p>
                  <div className="option-log">
                    <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/c-1.png" className="img-fluid" alt="" />Login with Google</a></div>
                    <div className="single-log-opt"><a href="javascript:void(0);" className="log-btn"><img src="assets/img/facebook.png" className="img-fluid" alt="" />Login with Facebook</a></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>














      <div
          className="modal fade"
          id="term"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="termmodal"
          aria-hidden="true"
        >
          <div className="modal-dialog login-pop-form" role="document" style={{maxWidth:'80%'}}>
            <div className="modal-content" id="termmodal">
              <div className="modal-headers">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="ti-close" />
                </button>
              </div>
              <div className="modal-body p-5">
               
               
              {lng === "English" ?  <section
                className="middle"
                style={{
                  overflow: "scroll",
                  height: "600px",
                  scrollbarWidth: "none",
                }}
              >
                <div className="container">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-xl-11 col-lg-12 col-md-6 col-sm-12">
                     
                      <div className="abt_caption">
                        <h2
                          className="ft-medium mb-4"
                          dangerouslySetInnerHTML={{
                            __html: policyData?.title,
                          }}
                        />

                        <p
                          className="mb-4"
                          dangerouslySetInnerHTML={{
                            __html: policyData?.description,
                          }}
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
              </section> :  <section
                className="middle"
                style={{
                  overflow: "scroll",
                  height: "600px",
                  scrollbarWidth: "none",
                }}
              >
                <div className="container">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-xl-11 col-lg-12 col-md-6 col-sm-12">
                     
                      <div className="abt_caption">
                        <h2
                          className="ft-medium mb-4"
                          dangerouslySetInnerHTML={{
                            __html: policyData?.sp_title,
                          }}
                        />

                        <p
                          className="mb-4"
                          dangerouslySetInnerHTML={{
                            __html: policyData?.sp_description,
                          }}
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
              </section>}

                

               </div>
              
            </div>
          </div>
        </div>
      {/* End Modal */}
      <a id="tops-button" className="top-scroll" title="Back to top" href="#"><i className="ti-arrow-up" /></a>
    </div>
    {/* ============================================================== */}
    {/* End Wrapper */}
    {/* ============================================================== */}
    {/* ============================================================== */}
    {/* All Jquery */}
    {/* ============================================================== */}
    {/* ============================================================== */}
    {/* This page plugins */}
    {/* ============================================================== */}		
  </div>
  )
}

export default Login
