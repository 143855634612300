import React from 'react'

import Headerbusiness from './Headerbusiness'
import Dashboardimage from '../Component/Dashboardimage';
import apiurl from '../Component/config';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import Mainnavigation from './Mainnavigation';

const Bchangepassword = () => {


  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [output, setOutput] = useState("");

  const _id = secureLocalStorage.getItem("coquiid");
  const tokenn = secureLocalStorage.getItem("token");
  const handleSubmit = (evt) => {
    evt.preventDefault();

    const data = {
      user_id: _id,
      old_password: password,
      new_password: newPassword,
    };

    axios
      .post(`${apiurl[0].apiUrl}React/change_password_react`, data, {
        headers: {
          Authorization: `${tokenn}`,
        },
      })
      .then((response) => {

        if (response.data.result === true) {
          setOutput(response.data.msg);
        } else if
        (response.data.result === false)
        {
          
          setOutput(response.data.msg);
        }
else{
  setOutput(response.data.msg)
}

      })
      .catch((err) => {

      });
  };

  let lng = secureLocalStorage.getItem("language")

  return (
    <div>
        {/* ============================================================== */}
        {/* Preloader - style you can find in spinners.css */}
        {/* ============================================================== */}
        <div className="preloader" />
        {/* ============================================================== */}
        {/* Main wrapper - style you can find in pages.scss */}
        {/* ============================================================== */}
        <div id="main-wrapper">
          {/* ============================================================== */}
          {/* Top header  */}
          {/* ============================================================== */}
          {/* Start Navigation */}
          <Headerbusiness/>
          {/* End Navigation */}
          <div className="clearfix" />
          {/* ============================================================== */}
          {/* Top header  */}
          {/* ============================================================== */}
          {/* =============================== Dashboard Header ========================== */}
         <Dashboardimage/>
          {/* =============================== Dashboard Header ========================== */}
          {/* ======================= dashboard Detail ======================== */}
          <div className="goodup-dashboard-wrap gray px-4 py-5">
            <a className="mobNavigation" data-bs-toggle="collapse" href="#MobNav" role="button" aria-expanded="false" aria-controls="MobNav">
              <i className="fas fa-bars me-2" />Dashboard Navigation
            </a>
            <Mainnavigation/>
           {lng === "English" ?  <div className="goodup-dashboard-content">
              <div className="dashboard-tlbar d-block mb-5">
                <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="_dashboard_content bg-white rounded mb-4">
                    <div className="_dashboard_content_header br-bottom py-3 px-3">
                      <div className="_dashboard__header_flex">
                        <h4 className="mb-0 ft-medium fs-md">
                          <i className="fa fa-lock me-2 theme-cl fs-sm" />
                          Change Password
                        </h4>
                      </div>
                    </div>
                    <div className="_dashboard_content_body py-3 px-3">
                      <font style={{color:'red'}}>{output}</font> 
                      <form onClick={handleSubmit} className="row submit-form">
                        <div className="col-xl-8 col-lg-9 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label>Old Password</label>
                            <input required
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              type="text"
                              className="form-control rounded"
                              placeholder='Old Password'
                            />
                          </div>
                        </div>
                        <div className="col-xl-8 col-lg-9 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label>New Password</label>
                            <input required
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              type="text"
                              className="form-control rounded"
                              placeholder='New Password'
                            />
                          </div>
                        </div>
                        {/* <div className="col-xl-8 col-lg-9 col-md-12 col-sm-12">
                            <div className="form-group">
                              <label>Confirm Password</label>
                              <input type="text" className="form-control rounded" placeholder />
                            </div>
                          </div> */}
                        <div className="col-xl-12 col-lg-12">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-md ft-medium text-light rounded theme-bg"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              
              {/* footer */}
              {/* <div class="row">
						<div class="col-md-12">
							<div class="py-3">© 2022 Goodup. Designd By ThemezHub.</div>
						</div>
					</div> */}
            </div> :  <div className="goodup-dashboard-content">
              <div className="dashboard-tlbar d-block mb-5">
                <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="_dashboard_content bg-white rounded mb-4">
                    <div className="_dashboard_content_header br-bottom py-3 px-3">
                      <div className="_dashboard__header_flex">
                        <h4 className="mb-0 ft-medium fs-md">
                          <i className="fa fa-lock me-2 theme-cl fs-sm" />
                          Cambiar la contraseña
                        </h4>
                      </div>
                    </div>
                    <div className="_dashboard_content_body py-3 px-3">
                      <font style={{color:'red'}}>{output}</font> 
                      <form onClick={handleSubmit} className="row submit-form">
                        <div className="col-xl-8 col-lg-9 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label>Contraseña anterior</label>
                            <input required
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              type="text"
                              className="form-control rounded"
                              placeholder='Contraseña anterior'
                            />
                          </div>
                        </div>
                        <div className="col-xl-8 col-lg-9 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label>Nueva contraseña</label>
                            <input required
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              type="text"
                              className="form-control rounded"
                              placeholder='Nueva contraseña'
                            />
                          </div>
                        </div>
                        
                        <div className="col-xl-12 col-lg-12">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-md ft-medium text-light rounded theme-bg"
                            >
                              Guardar cambios
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                </div>
              </div>
             
            </div>}
          </div>
          {/* ======================= dashboard Detail End ======================== */}
          <a id="tops-button" className="top-scroll" title="Back to top" href="#"><i className="ti-arrow-up" /></a>
        </div>
        		
      </div>
  )
}

export default Bchangepassword
