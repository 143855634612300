import React from 'react'
import Header from './Header'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import apiurl from './config'
import { useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Subscripstion = () => {
    let navigate=useNavigate()
    const subscribtion=()=>{
        let userid = secureLocalStorage.getItem("coquiid")
        let tokenn = secureLocalStorage.getItem("token")
       
        const data={
            user_id:userid,
            sub_ammount:"90"
 };

 axios.post(`${apiurl[0].apiUrl}React/subscription_purchased`,data,{
 headers : {
    Authorization:`${tokenn}`
 },

    }).then((res)=>{
       
        setTimeout(() => {
          window.location.reload(navigate('/'))
        }, 3000);
        toast.success(res.data.msg)
    }).catch((error)=>{
        // console.log("error",error)
    })
    }
  return (
    <div>
<ToastContainer />
      {/* ============================================================== */}
        {/* Preloader - style you can find in spinners.css */}
        {/* ============================================================== */}
        <div className="preloader" />
        {/* ============================================================== */}
        {/* Main wrapper - style you can find in pages.scss */}
        {/* ============================================================== */}
        <div id="main-wrapper">
          {/* ============================================================== */}
          {/* Top header  */}
          {/* ============================================================== */}
          {/* Start Navigation */}
         <Header/>
          {/* End Navigation */}
          <div className="clearfix" />
          {/* ============================================================== */}
          {/* Top header  */}
          {/* ============================================================== */}
          {/* ======================= Top Breadcrubms ======================== */}
          
          {/* ======================= Top Breadcrubms ======================== */}
          {/* ======================= Booking page Design ======================== */}
          <section className="gray space min">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  
                  <div className="d-block"><h4 className="ft-medium">Payment Method</h4></div>
                  <div className="d-block"><h4 className="ft-medium">Payable Amount - $90</h4></div>
                  <div className="single-form-item">
                    <div className="accordion accordion-flush" id="acdflush">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="paypalhead">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#colpaypal" aria-expanded="true" aria-controls="colpaypal">
                            Pay via PayPal<img src="assets/img/paypal.png" className="img-fluid" alt="" />
                          </button>
                        </h2>
                        <div id="colpaypal" className="accordion-collapse collapse show" aria-labelledby="paypalhead" data-bs-parent="#acdflush">
                          <div className="accordion-body">
                            <p>You will be redirected to PayPal to complete payment.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="creditheading">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#colcredit" aria-expanded="false" aria-controls="colcredit">
                            Pay Via Credit Card<img src="assets/img/debit.png" className="img-fluid" alt="" />
                          </button>
                        </h2>
                        <div id="colcredit" className="accordion-collapse collapse" aria-labelledby="creditheading" data-bs-parent="#acdflush">
                          <div className="accordion-body">
                            <div className="row submit-form">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label className="mb-1">Card Holder Name</label>
                                  <input type="text" className="form-control rounded" placeholder="Dhananjay Preet" />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label className="mb-1">Card Number</label>
                                  <input type="text" className="form-control rounded" placeholder="5468 2153 1007 7077" />
                                </div>
                              </div>									
                              <div className="col-lg-5 col-md-5 col-sm-6">
                                <div className="form-group">
                                  <label className="mb-1">Expire Month</label>
                                  <input type="text" className="form-control rounded" placeholder="MM" />
                                </div>
                              </div>
                              <div className="col-lg-5 col-md-5 col-sm-6">
                                <div className="form-group">
                                  <label className="mb-1">Expire Year</label>
                                  <input type="text" className="form-control rounded" placeholder="YY" />
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-12">
                                <div className="form-group">
                                  <label className="mb-1">CVC</label>
                                  <input type="text" className="form-control rounded" placeholder="***" />
                                </div>
                              </div>										
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single-form-item mb-4">
                    <div className="d-block"><button onClick={subscribtion} type="button" className="btn theme-bg text-light ft-medium rounded">Confirm &amp; Pay</button></div>
                  </div>
                </div>
              
              </div>
            </div>
          </section>
     
                
       
          <a id="tops-button" className="top-scroll" title="Back to top" href="#"><i className="ti-arrow-up" /></a>
        </div>
    
    </div>
  )
}

export default Subscripstion
