import React from "react";
import Headerbusiness from "./Headerbusiness";
import Dashboardimage from "../Component/Dashboardimage";
import secureLocalStorage from "react-secure-storage";
import apiurl from "../Component/config";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Mainnavigation from "./Mainnavigation";
import toast, { Toaster } from "react-hot-toast";

const Bdashboard = () => {
  const [output, setoutput] = useState("");
  const [profile, setprofile] = useState([]);

  const [selectedProfileImage, setSelectedProfileImage] = useState();

  const [first_name, setfirst_name] = useState("");
  const [type, setType] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [vendorelisting, setVendorelisting] = useState([]);

  const useridd = secureLocalStorage.getItem("coquiid");
  const tokenn = secureLocalStorage.getItem("token");

  const handleProfileImageSelect = (event) => {
    setSelectedProfileImage(event.target.files[0]);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!type) {
      setoutput("Please Select Type");
      return;
    }
    const formData = new FormData();
    formData.append("business_logo", selectedProfileImage);

    formData.append("business_name", first_name);
    formData.append("type", type);
    formData.append("vendor_id", useridd);

    try {
      const response = await axios.post(
        `${apiurl[0].apiUrl}React/update_business_name_logo`,
        formData,

        {
          headers: {
            Authorization: `${tokenn}`,
          },
        }
      );

      setSelectedProfileImage("");

      setfirst_name("");
      setType("");
toast.success(response.data.msg)
      // setSuccessMessage("Profile updated successfully!");
      getvendorlisting();
    } catch (error) {}
  };

  useEffect(() => {
    getvendorlisting();
  },[0]);

  const getvendorlisting = () => {
    const tokenn = secureLocalStorage.getItem("token");
    const idddd = secureLocalStorage.getItem("coquiid");

    const data = {
      vendor_id: idddd,
    };

    axios
      .post(`${apiurl[0].apiUrl}React/get_vendor_listing_react`, data, {
        headers: {
          Authorization: `${tokenn}`,
        },
      })
      .then((response) => {
        setVendorelisting(response.data.data);
        secureLocalStorage.setItem("mylistingid", response.data.data.id);
      })
      .catch((error) => {});
  };

  let lng = secureLocalStorage.getItem("language")
  return (
    <div>
      <Toaster/>
      {/* ============================================================== */}
      {/* Preloader - style you can find in spinners.css */}
      {/* ============================================================== */}
      <div className="preloader" />
      {/* ============================================================== */}
      {/* Main wrapper - style you can find in pages.scss */}
      {/* ============================================================== */}
      <div id="main-wrapper">
        {/* ============================================================== */}
        {/* Top header  */}
        {/* ============================================================== */}
        {/* Start Navigation */}
        <Headerbusiness />
        {/* End Navigation */}
        <div className="clearfix" />
        {/* ============================================================== */}
        {/* Top header  */}
        {/* ============================================================== */}
        {/* =============================== Dashboard Header ========================== */}

        <Dashboardimage />

        {/* =============================== Dashboard Header ========================== */}
        {/* ======================= dashboard Detail ======================== */}
        <div className="goodup-dashboard-wrap gray px-4 py-5">
          <a
            className="mobNavigation"
            data-bs-toggle="collapse"
            href="#MobNav"
            role="button"
            aria-expanded="false"
            aria-controls="MobNav"
          >
            <i className="fas fa-bars me-2" />
            Dashboard Navigation
          </a>
         <Mainnavigation/>
          {lng === "English" ? <div className="goodup-dashboard-content">
            <div className="dashboard-tlbar d-block mb-5">
              <div className="row">
                <div className="colxl-12 col-lg-12 col-md-12">
                  <h1 className="ft-medium">
                    Hello,
                    {vendorelisting?.vendor_details?.vendor_email
                      .charAt(0)
                      .toUpperCase() +
                      vendorelisting?.vendor_details?.vendor_email.slice(1, 4)}
                  </h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item text-muted">
                        <a href="#">Home</a>
                      </li>{" "}
                      /{/* <li className="breadcrumb-item"> */}
                      <li className="">
                        <a href="#" className="theme-cl">
                          Dashboard
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="dashboard-widg-bar d-block">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                  <div
                    className="alert bg-inverse text-light d-flex align-items-center"
                    role="alert"
                  >
                    <p className="p-0 m-0 ft-medium full-width">
                      Your listing{" "}
                      <a href="#" className="text-success">
                        {vendorelisting?.vendor_details?.business_name}
                      </a>{" "}
                      has been approved!
                    </p>
                    <button
                      type="button"
                      className="btn-close text-light"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-4 col-sm-12 order-xl-last order-lg-last order-md-last">
                  <div className="d-flex bg-white rounded px-3 py-3 mb-3">
                    <div className="dash-figure">
                      <div className="dash-figure-thumb">
                        {vendorelisting?.business_logo ? (
                          <img  width={300}
                            src={`${apiurl[0].apiUrl}assets/bori_images/listing/${vendorelisting?.business_logo}`}
                            className="img-fluid rounded"
                            alt=""
                          />
                        ) : (
                          <img
                            src="assets/img/t-4.png"
                            className="img-fluid rounded"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* row */}
              <div className="dashboard-widg-bar d-block">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <font style={{ color: "red" }}> {output}</font>
                    <font style={{ color: "blue" }}> {successMessage}</font>

                    <form className="submit-form" onSubmit={handleFormSubmit}>
                      <div className="dashboard-list-wraps bg-white rounded mb-4">
                        <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                          <div className="dashboard-list-wraps-flx">
                            <h4 className="mb-0 ft-medium fs-md">
                              <i className="fa fa-user-check me-2 theme-cl fs-sm" />
                              My Profile
                            </h4>
                          </div>
                        </div>
                        <div className="dashboard-list-wraps-body py-3 px-3">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label className="mb-1">Business Name</label>
                                <input
                                  type="text"
                                  value={first_name}
                                  onChange={(e) =>
                                    setfirst_name(e.target.value)
                                  }
                                  className="form-control rounded"
                                  placeholder={
                                    vendorelisting?.vendor_details
                                      ?.business_name
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label className="mb-1">Changes Type </label>
                                <select
                                  value={type}
                                  onChange={(e) => setType(e.target.value)}
                                  type="text"
                                  className="form-control rounded"
                                >
                                  <option>Select Type</option>
                                  <option value="business_name">
                                    Business Name
                                  </option>
                                  <option value="business_logo">
                                    Business Logo
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="upload-photo-btn">
                              <label className="mb-1">Business Logo</label>
                              <div className="Uploadphoto">
                                <span>
                                  <i className="fas fa-upload" /> Upload Photo
                                </span>
                                <input accept="image/*"
                                  onChange={handleProfileImageSelect}
                                  type="file"
                                  className="upload"
                                />
                              </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-list-wraps bg-white rounded mb-4">
                        <div className="dashboard-list-wraps-body py-3 px-3">
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn  theme-bg rounded text-light"
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="row">
              <div className="col-md-12">
                <div className="py-3">
                  © 2024 CoquiPage . Designd By Logical SoftTech Private
                  Limited.
                </div>
              </div>
            </div>
          </div> : <div className="goodup-dashboard-content">
            <div className="dashboard-tlbar d-block mb-5">
              <div className="row">
                <div className="colxl-12 col-lg-12 col-md-12">
                  <h1 className="ft-medium">
                  Hola,
                    {vendorelisting?.vendor_details?.vendor_email
                      .charAt(0)
                      .toUpperCase() +
                      vendorelisting?.vendor_details?.vendor_email.slice(1, 4)}
                  </h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item text-muted">
                        <a href="#">Hogar</a>
                      </li>{" "}
                      /{/* <li className="breadcrumb-item"> */}
                      <li className="">
                        <a href="#" className="theme-cl">
                        Panel
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="dashboard-widg-bar d-block">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                  <div
                    className="alert bg-inverse text-light d-flex align-items-center"
                    role="alert"
                  >
                    <p className="p-0 m-0 ft-medium full-width">
                    Tu listado{" "}
                      <a href="#" className="text-success">
                        {vendorelisting?.vendor_details?.sp_business_name}
                      </a>{" "}
                      ¡ha sido aprobada!
                    </p>
                    <button
                      type="button"
                      className="btn-close text-light"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-4 col-sm-12 order-xl-last order-lg-last order-md-last">
                  <div className="d-flex bg-white rounded px-3 py-3 mb-3">
                    <div className="dash-figure">
                      <div className="dash-figure-thumb">
                        {vendorelisting?.business_logo ? (
                          <img  width={300}
                            src={`${apiurl[0].apiUrl}assets/bori_images/listing/${vendorelisting?.business_logo}`}
                            className="img-fluid rounded"
                            alt=""
                          />
                        ) : (
                          <img
                            src="assets/img/t-4.png"
                            className="img-fluid rounded"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* row */}
              <div className="dashboard-widg-bar d-block">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <font style={{ color: "red" }}> {output}</font>
                    <font style={{ color: "blue" }}> {successMessage}</font>

                    <form className="submit-form" onSubmit={handleFormSubmit}>
                      <div className="dashboard-list-wraps bg-white rounded mb-4">
                        <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                          <div className="dashboard-list-wraps-flx">
                            <h4 className="mb-0 ft-medium fs-md">
                              <i className="fa fa-user-check me-2 theme-cl fs-sm" />
                              Mi perfil
                            </h4>
                          </div>
                        </div>
                        <div className="dashboard-list-wraps-body py-3 px-3">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label className="mb-1">Nombre del Negocio</label>
                                <input
                                  type="text"
                                  value={first_name}
                                  onChange={(e) =>
                                    setfirst_name(e.target.value)
                                  }
                                  className="form-control rounded"
                                  placeholder={
                                    vendorelisting?.vendor_details
                                      ?.business_name
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group">
                                <label className="mb-1">Tipo de cambios </label>
                                <select
                                  value={type}
                                  onChange={(e) => setType(e.target.value)}
                                  type="text"
                                  className="form-control rounded"
                                >
                                  <option>Seleccione tipo</option>
                                  <option value="business_name">
                                  Nombre del Negocio
                                  </option>
                                  <option value="business_logo">
                                  Logotipo de empresa
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="upload-photo-btn">
                              <label className="mb-1">Logotipo de empresa</label>
                              <div className="Uploadphoto">
                                <span>
                                  <i className="fas fa-upload" /> Subir foto
                                </span>
                                <input accept="image/*"
                                  onChange={handleProfileImageSelect}
                                  type="file"
                                  className="upload"
                                />
                              </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-list-wraps bg-white rounded mb-4">
                        <div className="dashboard-list-wraps-body py-3 px-3">
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn  theme-bg rounded text-light"
                                >
                                  Guardar cambios
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="row">
              <div className="col-md-12">
                <div className="py-3">
                © 2024 CoquiPágina. Diseñado por Logical SoftTech Private
                  Limitado.
                </div>
              </div>
            </div>
          </div>}
        </div>
        {/* ======================= dashboard Detail End ======================== */}
        <a id="tops-button" className="top-scroll" title="Back to top" href="#">
          <i className="ti-arrow-up" />
        </a>
      </div>
      {/* ============================================================== */}
      {/* End Wrapper */}
      {/* ============================================================== */}
      {/* ============================================================== */}
      {/* All Jquery */}
      {/* ============================================================== */}
      {/* Morris.js charts */}
      {/* Custom Chart JavaScript */}
      {/* ============================================================== */}
      {/* This page plugins */}
      {/* ============================================================== */}
    </div>
  );
};

export default Bdashboard;
